import { useState } from 'react';
import Button from '../../Button/Button';
import Input from '../../Input/Input';
import ModalWrapper from '../../ModalWrapper/ModalWrapper';
import Select from '../../Select/Select';
import Text from '../../Text/Text';
import Textarea from '../../Textarea/Textarea';
import Title from '../../Title/Title';
import UploadFile from '../../UploadFile/UploadFile';
import style from './Recovery.module.scss';
import icon from './assets/icon.svg';

const RecoveryModal = ({ close }) => {
  const [isSwitched, setIsSwitched] = useState(false);

  return (
    <ModalWrapper closeModal={close} withBack={!isSwitched}>
      <div className={style.modal}>
        {isSwitched ? (
          <>
            <Title.H1 center className={style.modal__title}>
              Create new password
            </Title.H1>
            <Text grey center>
              Use this form below to reset <br /> your password
            </Text>
            <Input
              icon={
                <svg
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M11.3333 5.99996C11.3333 5.65874 11.2031 5.31753 10.9428 5.05719C10.6825 4.79684 10.3412 4.66667 10 4.66667M10 10C12.2091 10 14 8.20914 14 6C14 3.79086 12.2091 2 10 2C7.79086 2 6 3.79086 6 6C6 6.18245 6.01222 6.36205 6.03587 6.53802C6.07478 6.82745 6.09424 6.97217 6.08114 7.06373C6.0675 7.1591 6.05013 7.2105 6.00313 7.2946C5.958 7.37533 5.87847 7.45486 5.71942 7.61391L2.31242 11.0209C2.19712 11.1362 2.13947 11.1939 2.09824 11.2611C2.06169 11.3208 2.03475 11.3858 2.01842 11.4538C2 11.5306 2 11.6121 2 11.7752V12.9333C2 13.3067 2 13.4934 2.07266 13.636C2.13658 13.7614 2.23856 13.8634 2.36401 13.9273C2.50661 14 2.6933 14 3.06667 14H4.66667V12.6667H6V11.3333H7.33333L8.38609 10.2806C8.54514 10.1215 8.62467 10.042 8.7054 9.99687C8.7895 9.94987 8.8409 9.9325 8.93627 9.91886C9.02783 9.90576 9.17255 9.92522 9.46198 9.96413C9.63795 9.98778 9.81755 10 10 10Z'
                    stroke='white'
                    stroke-opacity='0.5'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              }
              placeholder={'Password'}
              className={style.input}
              big
              border
              password
            />
            <Input
              icon={
                <svg
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M11.3333 5.99996C11.3333 5.65874 11.2031 5.31753 10.9428 5.05719C10.6825 4.79684 10.3412 4.66667 10 4.66667M10 10C12.2091 10 14 8.20914 14 6C14 3.79086 12.2091 2 10 2C7.79086 2 6 3.79086 6 6C6 6.18245 6.01222 6.36205 6.03587 6.53802C6.07478 6.82745 6.09424 6.97217 6.08114 7.06373C6.0675 7.1591 6.05013 7.2105 6.00313 7.2946C5.958 7.37533 5.87847 7.45486 5.71942 7.61391L2.31242 11.0209C2.19712 11.1362 2.13947 11.1939 2.09824 11.2611C2.06169 11.3208 2.03475 11.3858 2.01842 11.4538C2 11.5306 2 11.6121 2 11.7752V12.9333C2 13.3067 2 13.4934 2.07266 13.636C2.13658 13.7614 2.23856 13.8634 2.36401 13.9273C2.50661 14 2.6933 14 3.06667 14H4.66667V12.6667H6V11.3333H7.33333L8.38609 10.2806C8.54514 10.1215 8.62467 10.042 8.7054 9.99687C8.7895 9.94987 8.8409 9.9325 8.93627 9.91886C9.02783 9.90576 9.17255 9.92522 9.46198 9.96413C9.63795 9.98778 9.81755 10 10 10Z'
                    stroke='white'
                    stroke-opacity='0.5'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              }
              placeholder={'Confirm Password'}
              className={style.input}
              big
              border
              password
            />
            <Button className={style.button__spaceBig} filled>
              Reset Password
            </Button>
          </>
        ) : (
          <>
            <div className={style.modal__icon}>
              <img src={icon} alt='' />
            </div>
            <Title.H1 center className={style.modal__title}>
              Password recovery
            </Title.H1>
            <Text grey center>
              Enter your email address below <br /> to reset your password
            </Text>
            <Input
              icon={
                <svg
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M1.33325 4.66797L4.79581 6.97634C4.99558 7.10952 5.09546 7.1761 5.20352 7.22328C5.29943 7.26515 5.39994 7.29558 5.50297 7.31394C5.61905 7.33464 5.73909 7.33464 5.97917 7.33464H10.0207C10.2607 7.33464 10.3808 7.33464 10.4969 7.31394C10.5999 7.29558 10.7004 7.26515 10.7963 7.22328C10.9044 7.17611 11.0043 7.10951 11.204 6.97634L14.6666 4.66797M4.53325 13.3346H11.4666C12.5867 13.3346 13.1467 13.3346 13.5746 13.1166C13.9509 12.9249 14.2569 12.6189 14.4486 12.2426C14.6666 11.8148 14.6666 11.2547 14.6666 10.1346V5.86797C14.6666 4.74786 14.6666 4.18781 14.4486 3.75999C14.2569 3.38366 13.9509 3.0777 13.5746 2.88596C13.1467 2.66797 12.5867 2.66797 11.4666 2.66797H4.53325C3.41315 2.66797 2.85309 2.66797 2.42527 2.88596C2.04895 3.0777 1.74299 3.38366 1.55124 3.75999C1.33325 4.18781 1.33325 4.74786 1.33325 5.86797V10.1346C1.33325 11.2547 1.33325 11.8148 1.55124 12.2426C1.74299 12.6189 2.04895 12.9249 2.42527 13.1166C2.85309 13.3346 3.41315 13.3346 4.53325 13.3346Z'
                    stroke='white'
                    stroke-opacity='0.5'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              }
              placeholder={'Email'}
              className={style.input}
              big
              border
            />
            <Button
              onClick={() => setIsSwitched(true)}
              className={style.button__space}
              filled
            >
              Reset password
            </Button>
          </>
        )}
      </div>
    </ModalWrapper>
  );
};

export default RecoveryModal;
