import Text from '../../../../components/Text/Text';
import style from './Tag.module.scss';

const Tag = ({ text, value }) => {
  return (
    <div className={style.tag}>
      <Text grey>{text}</Text>
      <Text>{value}</Text>
    </div>
  );
};

export default Tag;
