import { useRef, useState } from 'react';
import Text from '../Text/Text';
import style from './UploadImg.module.scss';
import Button from '../Button/Button';

const UploadImg = ({ className, label, big, transparent }) => {
  const inputRef = useRef(null);
  const uploadedImgRef = useRef(null);
  const [isUpload, setIsUpload] = useState(false);

  function handleChange({ target }) {
    if (!FileReader) {
      alert('FileReader не поддерживается');
      return;
    }

    if (!target.files.length) {
      alert('Ничего не загружено');
      setIsUpload(false);
      return;
    }

    const fileReader = new FileReader();
    setIsUpload(true);
    fileReader.onload = function () {
      uploadedImgRef.current.src = fileReader.result;
    };

    fileReader.readAsDataURL(target.files[0]);
  }

  const removeFile = () => {
    inputRef.current.value = null;
    setIsUpload(false);
  };

  return (
    <label
      className={`${style.upload} ${big && style.big} ${
        transparent && style.transparent
      } ${className}`}
    >
      <input
        onChange={handleChange}
        ref={inputRef}
        type='file'
        accept='image/png, image/jpeg'
      />
      {!isUpload && (
        <div className={style.uploadBody}>
          <div className={style.uploadBody__icon}>
            <svg
              width='25'
              height='24'
              viewBox='0 0 25 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M12.5 5V19M5.5 12H19.5'
                stroke='white'
                stroke-opacity='0.5'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
            </svg>
          </div>
          <Text grey center className={style.uploadBody__text}>
            {label}
          </Text>
        </div>
      )}
      {isUpload && (
        <div className={style.uploadImg}>
          <Button sm filledDark onlyIcon round className={style.uploadImg__btn}>
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M14 10V10.8C14 11.9201 14 12.4802 13.782 12.908C13.5903 13.2843 13.2843 13.5903 12.908 13.782C12.4802 14 11.9201 14 10.8 14H5.2C4.07989 14 3.51984 14 3.09202 13.782C2.71569 13.5903 2.40973 13.2843 2.21799 12.908C2 12.4802 2 11.9201 2 10.8V10M11.3333 5.33333L8 2M8 2L4.66667 5.33333M8 2V10'
                stroke='white'
                stroke-opacity='0.5'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
            </svg>
          </Button>
          <Button
            onClick={removeFile}
            sm
            filledDark
            onlyIcon
            round
            className={style.uploadImg__btn}
          >
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M10.6667 3.99967V3.46634C10.6667 2.7196 10.6667 2.34624 10.5213 2.06102C10.3935 1.81014 10.1895 1.60616 9.93865 1.47833C9.65344 1.33301 9.28007 1.33301 8.53333 1.33301H7.46667C6.71993 1.33301 6.34656 1.33301 6.06135 1.47833C5.81046 1.60616 5.60649 1.81014 5.47866 2.06102C5.33333 2.34624 5.33333 2.7196 5.33333 3.46634V3.99967M2 3.99967H14M12.6667 3.99967V11.4663C12.6667 12.5864 12.6667 13.1465 12.4487 13.5743C12.2569 13.9506 11.951 14.2566 11.5746 14.4484C11.1468 14.6663 10.5868 14.6663 9.46667 14.6663H6.53333C5.41323 14.6663 4.85318 14.6663 4.42535 14.4484C4.04903 14.2566 3.74307 13.9506 3.55132 13.5743C3.33333 13.1465 3.33333 12.5864 3.33333 11.4663V3.99967'
                stroke='white'
                stroke-opacity='0.5'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
            </svg>
          </Button>

          <div className={style.uploadImg__item}>
            <img ref={uploadedImgRef} src='' alt='' />
          </div>
        </div>
      )}
    </label>
  );
};

export default UploadImg;
