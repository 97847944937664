import style from './Button.module.scss';

const Button = ({
  children,
  className,
  filled,
  dark,
  sm,
  lg,
  filledGrey,
  filledDark,
  noBorder,
  whiteText,
  round,
  icon,
  label,
  onlyIcon,
  onClick,
  yellow,
  lightYellow,
  lightPurple,
  blue,
  isDiv,
  disabled = false,
}) => {
  const clsx = `${style.button} ${className} 
      ${filled && style.filled}
      ${filledGrey && style.filledGrey}
      ${whiteText && style.whiteText}
      ${filledDark && style.filledDark}
      ${dark && style.dark}
      ${noBorder && style.noBorder}
      ${icon && style.withIcon}
      ${sm && style.sm}
      ${lg && style.lg}
      ${round && style.round}
      ${onlyIcon && style.onlyIcon}
      ${disabled && style.disabled}
      ${yellow && style.yellow}
      ${lightYellow && style.lightYellow}
      ${lightPurple && style.lightPurple}
      ${blue && style.blue}
      `;

  return isDiv ? (
    <div onClick={onClick} disabled={disabled} className={clsx}>
      {icon && (
        <span className={style.button__icon}>
          <img src={icon} alt='' />
        </span>
      )}
      {children}
      {label && <span className={style.button__label}>{label}</span>}
    </div>
  ) : (
    <button onClick={onClick} disabled={disabled} className={clsx}>
      {icon && (
        <span className={style.button__icon}>
          <img src={icon} alt='' />
        </span>
      )}
      {children}
      {label && <span className={style.button__label}>{label}</span>}
    </button>
  );
};

export default Button;
