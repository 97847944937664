import Title from '../../../../../../components/Title/Title';
import style from './Rate.module.scss';
import img from '../../../Community/assets/quest.svg';
import Text from '../../../../../../components/Text/Text';
import Button from '../../../../../../components/Button/Button';

const Rate = () => {
  return (
    <div className={style.rate}>
      <Title.H3 fz16 className={style.rate__title}>
        How do you rate
        <div className={style.rate__titleCoin}>
          <img src={img} alt='' />
          LopeCoin
        </div>
        today?
      </Title.H3>
      <Text grey className={style.rate__text}>
        You can also see how other users feel about the project
      </Text>
      <div className={style.rateBody}>
        <Button sm className={style.rateBody__item}>
          <span>🐢</span> 35
        </Button>
        <Button sm className={style.rateBody__item}>
          <span>🚀</span> 441
        </Button>
        <Button sm className={style.rateBody__item}>
          <span>🦄</span> 79
        </Button>
        <Button sm className={style.rateBody__item}>
          <span>🔥</span> 60
        </Button>
        <Button sm className={style.rateBody__item}>
          <span>🪽</span> 54
        </Button>
        <Button sm className={style.rateBody__item}>
          <span>👑</span> 24
        </Button>
        <Button sm className={style.rateBody__item}>
          <span>🤿</span> 17
        </Button>
      </div>
    </div>
  );
};

export default Rate;
