import Text from '../../../../components/Text/Text';
import Title from '../../../../components/Title/Title';
import style from './Progress.module.scss';

const Progress = ({
  className,
  generalProgress,
  categoryProgress,
  aboutProgress,
  contactsProgress,
  progress = 0,
}) => {
  return (
    <div className={`${style.progress} ${className}`}>
      <Title.H2 className={style.progress__title}>Progress</Title.H2>
      <div className={style.progressBody}>
        <div style={{ '--p': progress }} className={style.progressBody__chart}>
          <Title.H2 grey={true}>{progress}%</Title.H2>
        </div>
        <div className={style.progressBody__categories}>
          <ProgressCategory progress={generalProgress} name={'General info'} />
          <ProgressCategory progress={categoryProgress} name={'Category'} />
          <ProgressCategory progress={aboutProgress} name={'About Project'} />
          <ProgressCategory
            progress={contactsProgress}
            name={'Contact Information'}
          />
        </div>
      </div>
    </div>
  );
};

const ProgressCategory = ({ name, progress }) => {
  return (
    <div className={style.category}>
      <Text grey className={style.category__progress}>
        {progress}%
      </Text>
      <Text grey={progress !== 25} className={style.category__name}>
        {name}
      </Text>
    </div>
  );
};

export default Progress;
