import Text from '../../../../components/Text/Text';
import Title from '../../../../components/Title/Title';
import style from './Community.module.scss';
import questIcon from './assets/quest.svg';
import xIcon from './assets/x.svg';

const CommunitySection = () => {
  return (
    <section className={style.community}>
      <div className='__container'>
        <Title.H1 className={style.community__title}>Community</Title.H1>
        <div className={style.communityBody}>
          <div className={style.communityBody__col}>
            <div className={style.communityBody__colIcon}>
              <img src={questIcon} alt='' />
            </div>
            <Title.H3 fz16 className={style.communityBody__colTitle}>
              LopeCoin Quests
            </Title.H3>
            <Text grey className={style.communityBody__colText}>
              Comming soon
            </Text>
          </div>
          <div className={style.communityBody__col}>
            <div className={style.communityBody__colIcon}>
              <img src={xIcon} alt='' />
            </div>
            <Title.H3 fz16 className={style.communityBody__colTitle}>
              Twitter Widget
            </Title.H3>
            <Text grey className={style.communityBody__colText}>
              Comming soon
            </Text>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CommunitySection;
