import style from './ProfileCard.module.scss';
import Title from '../../../../components/Title/Title';
import Text from '../../../../components/Text/Text';
import Input from '../../../../components/Input/Input';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import './slider.scss';

import poop from '../../modules/Info/assets/poop.svg';
import coingecko from '../../modules/Info/assets/coingecko.svg';
import pancake from '../../modules/Info/assets/pancake.svg';
import tgIcon from './assets/social/tg.svg';
import { useMediaQuery } from 'usehooks-ts';

const ProfileCard = ({ avatar }) => {
  const isMobile = useMediaQuery('(max-width:767px)');

  return (
    <div className={style.profile}>
      <div className={style.profileTop}>
        <div className={style.profileTop__img}>
          <img src={avatar} alt='' />
        </div>
        <div className={style.profileTop__content}>
          <div className={style.profileTop__col}>
            <div className={style.profileTop__name}>
              <Title.H2 fz20>LopeCoin</Title.H2>
              <div className={style.profileTop__nameLabels}>
                <span className={style.blue}>Audit</span>
                <span className={style.yellow}>KYC</span>
              </div>
            </div>
            <Text grey className={style.profileTop__label}>
              Lope
            </Text>
            {!isMobile && (
              <div className={style.profileTop__address}>
                <div className={style.profileTop__addressChain}>
                  <span>
                    <svg
                      width='9'
                      height='9'
                      viewBox='0 0 9 9'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M4.2 8.39999C6.51959 8.39999 8.39999 6.51959 8.39999 4.2C8.39999 1.8804 6.51959 0 4.2 0C1.8804 0 0 1.8804 0 4.2C0 6.51959 1.8804 8.39999 4.2 8.39999Z'
                        fill='#F3BA2F'
                      />
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M5.28907 4.64447L5.92262 5.2762L4.20039 6.99661L2.47997 5.2762L3.11352 4.64447L4.20039 5.73133L5.28907 4.64447ZM4.20039 3.55578L4.84304 4.19844L4.20039 4.84109L3.55956 4.20026V4.19844L3.67243 4.08556L3.72705 4.03095L4.20039 3.55578ZM2.03394 3.56489L2.66749 4.19844L2.03394 4.83016L1.40039 4.19662L2.03394 3.56489ZM6.36684 3.56489L7.00039 4.19844L6.36684 4.83016L5.73329 4.19662L6.36684 3.56489ZM4.20039 1.39844L5.9208 3.11885L5.28725 3.7524L4.20039 2.66372L3.11352 3.75058L2.47997 3.11885L4.20039 1.39844Z'
                        fill='#131415'
                      />
                    </svg>
                  </span>
                  <Text grey>BSC</Text>
                </div>
                <Input
                  className={style.profileTop__addressInput}
                  value={'0x9f9cc070487257654d0e19C11f4bFF1c8A7A460D'}
                  withCopy
                />
              </div>
            )}
          </div>
          <div className={style.profileTop__col}>
            <div className={style.profileTop__exchanges}>
              <div className={style.profileTop__exchangesItem}>
                <img src={poop} alt='' />
              </div>
              <div className={style.profileTop__exchangesItem}>
                <img src={coingecko} alt='' />
              </div>
              <div className={style.profileTop__exchangesItem}>
                <img src={pancake} alt='' />
              </div>
            </div>
          </div>
        </div>
      </div>
      {isMobile && (
        <div className={style.profileTop__address}>
          <div className={style.profileTop__addressChain}>
            <span>
              <svg
                width='9'
                height='9'
                viewBox='0 0 9 9'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M4.2 8.39999C6.51959 8.39999 8.39999 6.51959 8.39999 4.2C8.39999 1.8804 6.51959 0 4.2 0C1.8804 0 0 1.8804 0 4.2C0 6.51959 1.8804 8.39999 4.2 8.39999Z'
                  fill='#F3BA2F'
                />
                <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M5.28907 4.64447L5.92262 5.2762L4.20039 6.99661L2.47997 5.2762L3.11352 4.64447L4.20039 5.73133L5.28907 4.64447ZM4.20039 3.55578L4.84304 4.19844L4.20039 4.84109L3.55956 4.20026V4.19844L3.67243 4.08556L3.72705 4.03095L4.20039 3.55578ZM2.03394 3.56489L2.66749 4.19844L2.03394 4.83016L1.40039 4.19662L2.03394 3.56489ZM6.36684 3.56489L7.00039 4.19844L6.36684 4.83016L5.73329 4.19662L6.36684 3.56489ZM4.20039 1.39844L5.9208 3.11885L5.28725 3.7524L4.20039 2.66372L3.11352 3.75058L2.47997 3.11885L4.20039 1.39844Z'
                  fill='#131415'
                />
              </svg>
            </span>
            <Text grey>BSC</Text>
          </div>
          <Input
            className={style.profileTop__addressInput}
            value={'0x9f9cc070487257654d0e19C11f4bFF1c8A7A460D'}
            withCopy
          />
        </div>
      )}
      <div className={style.profileSocial}>
        <button
          className={`${style.profileSocial__prev} projectProfileSocialSlider_prev`}
        >
          <svg
            width='12'
            height='12'
            viewBox='0 0 12 12'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M7.5 9L4.5 6L7.5 3'
              stroke='white'
              stroke-linecap='round'
              stroke-linejoin='round'
            />
          </svg>
        </button>
        <button
          className={`${style.profileSocial__next} projectProfileSocialSlider_next`}
        >
          <svg
            width='12'
            height='12'
            viewBox='0 0 12 12'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M7.5 9L4.5 6L7.5 3'
              stroke='white'
              stroke-linecap='round'
              stroke-linejoin='round'
            />
          </svg>
        </button>
        <Swiper
          modules={[Navigation]}
          slidesPerView={'auto'}
          spaceBetween={20}
          className={style.profileSocial__slider}
          navigation={{
            prevEl: '.projectProfileSocialSlider_prev',
            nextEl: '.projectProfileSocialSlider_next',
          }}
        >
          <SwiperSlide className={style.profileSocial__sliderSlide}>
            <SocialCard img={tgIcon} name={'Telegram'} followers={'16K'} />
          </SwiperSlide>
          <SwiperSlide className={style.profileSocial__sliderSlide}>
            <SocialCard img={tgIcon} name={'Telegram'} followers={'16K'} />
          </SwiperSlide>
          <SwiperSlide className={style.profileSocial__sliderSlide}>
            <SocialCard img={tgIcon} name={'Telegram'} followers={'16K'} />
          </SwiperSlide>
          <SwiperSlide className={style.profileSocial__sliderSlide}>
            <SocialCard img={tgIcon} name={'Telegram'} followers={'16K'} />
          </SwiperSlide>
          <SwiperSlide className={style.profileSocial__sliderSlide}>
            <SocialCard img={tgIcon} name={'Telegram'} followers={'16K'} />
          </SwiperSlide>
          <SwiperSlide className={style.profileSocial__sliderSlide}>
            <SocialCard img={tgIcon} name={'Telegram'} followers={'16K'} />
          </SwiperSlide>
          <SwiperSlide className={style.profileSocial__sliderSlide}>
            <SocialCard img={tgIcon} name={'Telegram'} followers={'16K'} />
          </SwiperSlide>
          <SwiperSlide className={style.profileSocial__sliderSlide}>
            <SocialCard img={tgIcon} name={'Telegram'} followers={'16K'} />
          </SwiperSlide>
          <SwiperSlide className={style.profileSocial__sliderSlide}>
            <SocialCard img={tgIcon} name={'Telegram'} followers={'16K'} />
          </SwiperSlide>
          <SwiperSlide className={style.profileSocial__sliderSlide}>
            <SocialCard img={tgIcon} name={'Telegram'} followers={'16K'} />
          </SwiperSlide>
          <SwiperSlide className={style.profileSocial__sliderSlide}>
            <SocialCard img={tgIcon} name={'Telegram'} followers={'16K'} />
          </SwiperSlide>
          <SwiperSlide className={style.profileSocial__sliderSlide}>
            <SocialCard img={tgIcon} name={'Telegram'} followers={'16K'} />
          </SwiperSlide>
        </Swiper>
      </div>
      <div className={style.profileSummary}>
        <Title.H3 fz16 className={style.profileSummary__title}>
          Summary
        </Title.H3>
        <div className={style.profileSummary__content}>
          <SummaryItem
            icon={
              <svg
                width='16'
                height='16'
                viewBox='0 0 16 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M8.66674 1.33203L2.72904 8.45727C2.4965 8.73631 2.38023 8.87584 2.37846 8.99367C2.37691 9.09611 2.42256 9.19357 2.50224 9.25796C2.5939 9.33203 2.77552 9.33203 3.13876 9.33203H8.00007L7.3334 14.6654L13.2711 7.54013C13.5036 7.26108 13.6199 7.12156 13.6217 7.00372C13.6232 6.90129 13.5776 6.80383 13.4979 6.73944C13.4062 6.66536 13.2246 6.66536 12.8614 6.66536H8.00007L8.66674 1.33203Z'
                  stroke='white'
                  stroke-opacity='0.5'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </svg>
            }
            title={'August 6 2024'}
            text={'Launch date'}
          />
          <SummaryItem
            icon={
              <svg
                width='16'
                height='16'
                viewBox='0 0 16 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M13.3332 4L5.99984 11.3333L2.6665 8'
                  stroke='white'
                  stroke-opacity='0.5'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </svg>
            }
            title={'April 17th 2024'}
            text={'Submitted'}
          />
          <SummaryItem category={'Meme'} text={'Categories'} />
          <SummaryItem
            icon={
              <svg
                width='17'
                height='16'
                viewBox='0 0 17 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M7.23393 2.66536C8.07562 1.84057 9.22838 1.33203 10.4999 1.33203C13.0772 1.33203 15.1666 3.42137 15.1666 5.9987C15.1666 7.27025 14.658 8.42303 13.8332 9.26473M11.1666 9.9987C11.1666 12.576 9.07725 14.6654 6.49992 14.6654C3.92259 14.6654 1.83325 12.576 1.83325 9.9987C1.83325 7.42137 3.92259 5.33203 6.49992 5.33203C9.07725 5.33203 11.1666 7.42137 11.1666 9.9987Z'
                  stroke='white'
                  stroke-opacity='0.5'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </svg>
            }
            title={'2.00B'}
            text={'Total Supply'}
          />
        </div>
      </div>
      <div className={style.profileAbout}>
        <Title.H3 fz16 className={style.profileAbout__title}>
          About
        </Title.H3>
        <Text grey className={style.profileAbout__text}>
          LOPE is a meme coin that was created to suppress the dominance of
          existing cheat coins, LOPE has a special and different feature that
          can distinguish it from other meme coins, as you know about other meme
          coins. <a href=''>Show more</a>
        </Text>
      </div>
    </div>
  );
};

const SocialCard = ({ img, name, followers }) => {
  return (
    <div className={style.socialCard}>
      <div className={style.socialCard__img}>
        <img src={img} alt='' />
      </div>
      <div className={style.socialCard__content}>
        <Text middle>{name}</Text>
        <Text grey>{followers}</Text>
      </div>
    </div>
  );
};

const SummaryItem = ({ icon, title, text, category }) => {
  return (
    <div className={style.summaryItem}>
      {icon && (
        <div className={style.summaryItem__icon}>
          {icon}
          {/* <img src={icon} alt='' /> */}
        </div>
      )}
      {category && (
        <div className={style.summaryItem__category}>{category}</div>
      )}
      {title && <Text>{title}</Text>}
      <Text grey className={style.summaryItem__text}>
        {text}
      </Text>
    </div>
  );
};

export default ProfileCard;
