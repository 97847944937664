import Text from '../../../../components/Text/Text';
import style from './Img.module.scss';

const Img = {
  Big: ({ img, text }) => {
    return (
      <div className={style.img}>
        <div className={style.img__item}>
          <img src={img} alt='' />
        </div>
        {text && (
          <Text grey center className={style.img__text}>
            {text}
          </Text>
        )}
      </div>
    );
  },
  Medium: ({ img, text }) => {
    return (
      <div className={`${style.img} ${style.md}`}>
        <div className={style.img__item}>
          <img src={img} alt='' />
        </div>
        {text && (
          <Text grey center className={style.img__text}>
            {text}
          </Text>
        )}
      </div>
    );
  },
  Small: ({ img, text }) => {
    return (
      <div className={`${style.img} ${style.sm}`}>
        <div className={style.img__item}>
          <img src={img} alt='' />
        </div>
        {text && (
          <Text grey center className={style.img__text}>
            {text}
          </Text>
        )}
      </div>
    );
  },
  Row: ({ children }) => {
    return <div className={style.row}>{children}</div>;
  },
};

export default Img;
