import Text from '../../../../../../components/Text/Text';
import Title from '../../../../../../components/Title/Title';
import style from './BarGraph.module.scss';
import boostIcon from '../../../../../../assets/btn_icons/boost.svg';
import votesIcon from '../../../../../../assets/btn_icons/votes.svg';

const BarGraph = ({ className }) => {
  return (
    <div className={`${style.graph} ${className}`}>
      <div className={style.graphTop}>
        <Title.H2 className={style.graphTop__title}>
          Votes & Boosts Ratio
        </Title.H2>
        <div className={style.graphTop__legend}>
          <div className={`${style.graphTop__legendItem} ${style.yellow}`}>
            <span></span>
            <Text grey>Boosts</Text>
          </div>
          <div className={`${style.graphTop__legendItem} ${style.purple}`}>
            <span></span>
            <Text grey>Votes</Text>
          </div>
        </div>
      </div>
      <div className={style.graphBody}>
        <div className={style.graphBody__content}>
          <div className={style.graphBody__lines}>
            <div className={`${style.graphBody__item} ${style.purple}`}>
              <div className={style.progress}>
                <div className={style.tip}>
                  <Text grey>Votes</Text>
                  <div className={style.tip__value}>
                    <Text>
                      350 <img src={votesIcon} alt='' />
                    </Text>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${style.graphBody__item} ${style.yellow}`}>
              <div className={style.progress}>
                <div className={style.tip}>
                  <Text grey>Boosts</Text>
                  <div className={style.tip__value}>
                    <Text>
                      350 <img src={boostIcon} alt='' />
                    </Text>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Text grey className={style.graphBody__x}>
          <span>100</span>
          <span>250</span>
          <span>500</span>
          <span>750</span>
          <span>1K</span>
          <span>2K</span>
          <span>5K</span>
          <span>10K</span>
        </Text>
      </div>
    </div>
  );
};

export default BarGraph;
