import Title from '../../components/Title/Title';
import style from './index.module.scss';
import Content from './modules/Content/Content';
import Top from './modules/Top/Top';

const DictionaryPage = () => {
  return (
    <div className={style.dictionary}>
      <div className='__container'>
        <Title.H1>Dictionary</Title.H1>
        <div className={style.dictionaryBody}>
          <Top />
          <Content
            id={'A'}
            title={'A'}
            list={[
              {
                title: 'Address (Cryptocurrency Address)',
                text: 'A unique alphanumeric identifier that represents a specific destination where cryptocurrency can be sent to or from.',
              },
              {
                title: 'Address (Cryptocurrency Address)',
                text: 'A unique alphanumeric identifier that represents a specific destination where cryptocurrency can be sent to or from.',
              },
              {
                title: 'Address (Cryptocurrency Address)',
                text: 'A unique alphanumeric identifier that represents a specific destination where cryptocurrency can be sent to or from.',
              },
              {
                title: 'Address (Cryptocurrency Address)',
                text: 'A unique alphanumeric identifier that represents a specific destination where cryptocurrency can be sent to or from.',
              },
              {
                title: 'Address (Cryptocurrency Address)',
                text: 'A unique alphanumeric identifier that represents a specific destination where cryptocurrency can be sent to or from.',
              },
              {
                title: 'Address (Cryptocurrency Address)',
                text: 'A unique alphanumeric identifier that represents a specific destination where cryptocurrency can be sent to or from.',
              },
              {
                title: 'Address (Cryptocurrency Address)',
                text: 'A unique alphanumeric identifier that represents a specific destination where cryptocurrency can be sent to or from.',
              },
              {
                title: 'Address (Cryptocurrency Address)',
                text: 'A unique alphanumeric identifier that represents a specific destination where cryptocurrency can be sent to or from.',
              },
            ]}
          />
          <Content
            id={'B'}
            title={'B'}
            list={[
              {
                title: 'Address (Cryptocurrency Address)',
                text: 'A unique alphanumeric identifier that represents a specific destination where cryptocurrency can be sent to or from.',
              },
              {
                title: 'Address (Cryptocurrency Address)',
                text: 'A unique alphanumeric identifier that represents a specific destination where cryptocurrency can be sent to or from.',
              },
              {
                title: 'Address (Cryptocurrency Address)',
                text: 'A unique alphanumeric identifier that represents a specific destination where cryptocurrency can be sent to or from.',
              },
              {
                title: 'Address (Cryptocurrency Address)',
                text: 'A unique alphanumeric identifier that represents a specific destination where cryptocurrency can be sent to or from.',
              },
              {
                title: 'Address (Cryptocurrency Address)',
                text: 'A unique alphanumeric identifier that represents a specific destination where cryptocurrency can be sent to or from.',
              },
              {
                title: 'Address (Cryptocurrency Address)',
                text: 'A unique alphanumeric identifier that represents a specific destination where cryptocurrency can be sent to or from.',
              },
              {
                title: 'Address (Cryptocurrency Address)',
                text: 'A unique alphanumeric identifier that represents a specific destination where cryptocurrency can be sent to or from.',
              },
              {
                title: 'Address (Cryptocurrency Address)',
                text: 'A unique alphanumeric identifier that represents a specific destination where cryptocurrency can be sent to or from.',
              },
            ]}
          />
          <Content
            id={'D'}
            title={'D'}
            list={[
              {
                title: 'Address (Cryptocurrency Address)',
                text: 'A unique alphanumeric identifier that represents a specific destination where cryptocurrency can be sent to or from.',
              },
              {
                title: 'Address (Cryptocurrency Address)',
                text: 'A unique alphanumeric identifier that represents a specific destination where cryptocurrency can be sent to or from.',
              },
              {
                title: 'Address (Cryptocurrency Address)',
                text: 'A unique alphanumeric identifier that represents a specific destination where cryptocurrency can be sent to or from.',
              },
              {
                title: 'Address (Cryptocurrency Address)',
                text: 'A unique alphanumeric identifier that represents a specific destination where cryptocurrency can be sent to or from.',
              },
              {
                title: 'Address (Cryptocurrency Address)',
                text: 'A unique alphanumeric identifier that represents a specific destination where cryptocurrency can be sent to or from.',
              },
              {
                title: 'Address (Cryptocurrency Address)',
                text: 'A unique alphanumeric identifier that represents a specific destination where cryptocurrency can be sent to or from.',
              },
              {
                title: 'Address (Cryptocurrency Address)',
                text: 'A unique alphanumeric identifier that represents a specific destination where cryptocurrency can be sent to or from.',
              },
              {
                title: 'Address (Cryptocurrency Address)',
                text: 'A unique alphanumeric identifier that represents a specific destination where cryptocurrency can be sent to or from.',
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default DictionaryPage;
