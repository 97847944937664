import Text from '../../../../components/Text/Text';
import style from './AddCard.module.scss';

const AddCard = ({ icon, title, onClick, className }) => {
  return (
    <div onClick={onClick} className={`${style.card} ${className}`}>
      <div className={style.card__icon}>{icon}</div>
      <div className={style.card__title}>
        <Text>{title}</Text>
        <Text grey>(Optional)</Text>
      </div>
      <div className={style.card__plus}>
        <svg
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M7.99992 3.33301V12.6663M3.33325 7.99967H12.6666'
            stroke='white'
            stroke-opacity='0.5'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
        </svg>
      </div>
    </div>
  );
};

export default AddCard;
