import Button from '../../../../components/Button/Button';
import Text from '../../../../components/Text/Text';
import style from './Bottom.module.scss';

const BottomSection = () => {
  return (
    <div className={style.bottom}>
      <Text grey className={style.bottom__text}>
        By clicking “Submit” you agree <br /> with our{' '}
        <a href=''>Terms and Conditions</a>
      </Text>
      <Button filled className={style.bottom__btn}>
        Submit Coin
      </Button>
    </div>
  );
};

export default BottomSection;
