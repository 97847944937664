import style from './Text.module.scss';

const Text = ({
  children,
  className,
  grey,
  black,
  center,
  fz14,
  middle,
  semiBold,
}) => {
  return (
    <div
      className={`${style.text} ${className} 
      ${grey && style.grey} 
      ${black && style.black} 
      ${fz14 && style._14} 
      ${center && style.center}
      ${middle && style.middle}
      ${semiBold && style.semiBold}
      `}
    >
      {children}
    </div>
  );
};

export default Text;
