import style from './Calendar.module.scss';
import { useEffect, useMemo, useState } from 'react';
import { cn, dayNames, monthNames } from '../../lib/utils';
import {
  add,
  addDays,
  addHours,
  eachDayOfInterval,
  eachMinuteOfInterval,
  endOfDay,
  endOfMonth,
  endOfWeek,
  format,
  getDay,
  isAfter,
  isBefore,
  isEqual,
  isSameMonth,
  isThisMonth,
  isToday,
  parse,
  parseISO,
  set,
  startOfDay,
  startOfToday,
  startOfWeek,
  startOfYesterday,
} from 'date-fns';
// import { CheckCircle2, ChevronLeft, ChevronRight } from "lucide-react"
import TimesBar from './components/TimesBar';
import Title from '../Title/Title';
import Button from '../Button/Button';
import Text from '../Text/Text';

const reservations = [
  addHours(startOfToday(), 1).toString(),
  addDays(new Date(addHours(startOfToday(), 1)), 2).toString(),
  addDays(new Date(addHours(startOfToday(), 1)), 3).toString(),
  addDays(new Date(addHours(startOfToday(), 1)), 4).toString(),
  addDays(new Date(addHours(startOfToday(), 1)), 6).toString(),
  addDays(new Date(addHours(startOfToday(), 1)), 8).toString(),
  addDays(new Date(addHours(startOfToday(), 1)), 35).toString(),
  addDays(new Date(addHours(startOfToday(), 1)), 36).toString(),
  addDays(new Date(addHours(startOfToday(), 1)), 37).toString(),
  addDays(new Date(addHours(startOfToday(), 1)), 39).toString(),
];

const parseAreaNums = (arr) => {
  let nums = [];

  for (let index = 0; index < arr.length; index++) {
    if (
      arr[index] === arr[index - 1] + 1 ||
      arr[index] === arr[index + 1] - 1
    ) {
      nums.push(arr[index]);
    } else {
      nums.push(`*${arr[index]}*`);
    }
  }

  const parseNums = nums
    .join('-')
    .split('*')
    .map((item) => item.split('-').filter((item) => item.length > 0))
    .filter((item) => item.length > 0)
    .map((item) => {
      if (item.length > 1) {
        return `${item[0]}-${item.reverse()[0]}`;
      } else {
        return item[0];
      }
    });

  return parseNums.join(', ');
};

const Calendar = ({ className }) => {
  // display div of availables times
  const [calendarTouched, setCalendarTouched] = useState(false);

  // handle dates
  let today = startOfToday();
  let [currentMonth, setCurrentMonth] = useState(format(today, 'MMM-yyyy'));
  let [selectedDay, setSelectedDay] = useState([]);
  let [selectedDayList, setSelectedDayList] = useState('');
  let firstDayCurrentMonth = parse(currentMonth, 'MMM-yyyy', new Date());
  let days = useMemo(
    () =>
      eachDayOfInterval({
        start: startOfWeek(firstDayCurrentMonth, { weekStartsOn: 1 }),
        end: endOfWeek(endOfMonth(firstDayCurrentMonth), { weekStartsOn: 1 }),
      }),
    [firstDayCurrentMonth]
  );

  const isDaySelected = (day) => {
    return selectedDay.filter((item) => +item === +day).length > 0;
  };

  const handleSelectedDays = (day) => {
    if (isDaySelected(day)) {
      setSelectedDay(selectedDay.filter((item) => +item !== +day));
    } else {
      setSelectedDay([...selectedDay, day]);
    }
  };

  // all days avaiilable times in this month until you change it
  const [availableTimesInThisMonth, setAvailableTimesInThisMonth] = useState(
    []
  );
  const [
    availableTimesInThisMonthForEachDay,
    setAvailableTimesInThisMonthForEachDay,
  ] = useState([]);

  // next and prev month functions
  function prevMonth() {
    let firstDayNextMonth = add(firstDayCurrentMonth, { months: -1 });
    setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'));
  }
  function nextMonth() {
    let firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 });
    setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'));
  }

  // selected days parse
  useEffect(() => {
    let monthList = [[], [], [], [], [], [], [], [], [], [], [], []];

    selectedDay.forEach((item) => {
      monthList[item.getMonth()].push(item.getDate());
    });

    const result = monthList
      .map((item, index) => {
        if (item.length > 0) {
          return `${parseAreaNums(item.sort())} ${monthNames[index].slice(
            0,
            3
          )}`;
        } else return '';
      })
      .filter((arr) => arr !== '');

    setSelectedDayList(result.join(', '));
  }, [selectedDay]);

  // calculate the number of available times for each day in this month
  useMemo(() => {
    let thisMonthTimesLength = [];
    let thisMonthTimesEachDay = [];
    days.map((day, dayIdx) => {
      // get times

      const StartOfToday = startOfDay(day);
      const endOfToday = endOfDay(day);
      // change your working hours here
      const startHour = set(StartOfToday, { hours: 1 });
      const endHour = set(endOfToday, { hours: 1 });
      let hoursInDay = eachMinuteOfInterval(
        {
          start: startHour,
          end: endHour,
        },
        { step: 60 }
      );
      // filter the available hours
      let freeTimes = hoursInDay.filter(
        (hour) =>
          !reservations.includes(parseISO(hour.toISOString()).toString())
      );
      thisMonthTimesLength.push(freeTimes.length);
      thisMonthTimesEachDay.push(freeTimes);
    });

    setAvailableTimesInThisMonth(thisMonthTimesLength);
    setAvailableTimesInThisMonthForEachDay(thisMonthTimesEachDay);
  }, [currentMonth]);

  return (
    <div className={`${style.calendar} ${className}`}>
      <div className={style.calendarTop}>
        <Title.H2>{format(firstDayCurrentMonth, ' MMMM yyyy')}</Title.H2>
        <div className={style.calendarTop__btns}>
          <Button
            onClick={prevMonth}
            onlyIcon
            noBorder
            // disabled={isThisMonth(new Date(currentMonth))}
          >
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M10 12L6 8L10 4'
                stroke='white'
                stroke-opacity='0.5'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
            </svg>
          </Button>
          <Button onClick={nextMonth} onlyIcon noBorder>
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M6 12L10 8L6 4'
                stroke='white'
                stroke-opacity='0.5'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
            </svg>
          </Button>
        </div>
      </div>

      <div className={style.calendarWeek}>
        {dayNames.map((day, i) => {
          return (
            <Text grey key={i} className={style.calendar__day}>
              {day.slice(0, 2)}
            </Text>
          );
        })}
      </div>

      <div className={style.calendarBody}>
        {days.map((day, dayIdx) => {
          return (
            <div
              key={day.toString()}
              // className={cn(
              //   dayIdx === 0 && colStartClasses[getDay(day) - 1],
              //   'h-14 justify-center flex items-center',
              //   (getDay(day) === 0 || getDay(day) === 6) &&
              //     'bg-orange-50 rounded-lg'
              // )}
              className={style.calendar__day}
            >
              <Button
                onClick={() => {
                  setCalendarTouched(true);
                  // setSelectedDay(day);
                  handleSelectedDays(day);
                }}
                onlyIcon
                noBorder={availableTimesInThisMonth[dayIdx] === 0}
                filledGrey={isDaySelected(day)}
                // className={cn(
                //   'w-12 h-12 flex flex-col p-2 justify-center items-center rounded-xl gap-0 group bg-gray-50 relative group',
                //   isEqual(day, selectedDay) &&
                //     'bg-orange-100 text-slate-900 text-lg',
                //   isEqual(today, day) && 'text-blue-900 bg-blue-50',
                //   isBefore(day, today) &&
                //     'text-red-800 bg-red-50 cursor-not-allowed',
                //   isEqual(today, day) && 'text-blue-900 bg-blue-50',
                //   isBefore(day, today) && 'cursor-not-allowed',
                //   isEqual(day, selectedDay) && isToday(day) && 'bg-blue-200',
                //   !isEqual(day, selectedDay) &&
                //     !isToday(day) &&
                //     !isSameMonth(day, firstDayCurrentMonth) &&
                //     'text-gray-400',
                //   !isEqual(day, selectedDay) &&
                //     !isToday(day) &&
                //     isSameMonth(day, firstDayCurrentMonth) &&
                //     'text-gray-900'
                // )}
                // disabled={isBefore(day, today)}
                disabled={
                  availableTimesInThisMonth[dayIdx] === 0 ||
                  isBefore(day, today)
                }
              >
                {isAfter(day, startOfYesterday()) && (
                  <span className='hidden group-hover:flex absolute top-0 -translate-x-.5 -translate-y-4 z-10 text-[11px] bg-slate-900 text-slate-100 px-1 rounded-md gap-1'>
                    <span>
                      {availableTimesInThisMonth[dayIdx] === 0 && '-'}
                    </span>
                    {/* <span>Available</span> */}
                  </span>
                )}

                {availableTimesInThisMonth[dayIdx] !== 0 && (
                  <time
                    dateTime={format(day, 'yyyy-MM-dd')}
                    className={cn(
                      'group-hover:text-lg',
                      (isEqual(day, selectedDay) || isToday(day)) &&
                        'font-semibold'
                    )}
                  >
                    {format(day, 'd')}
                  </time>
                )}

                {isAfter(day, startOfYesterday()) && (
                  <TimesBar
                    times={availableTimesInThisMonthForEachDay[dayIdx]}
                  />
                )}
              </Button>
            </div>
          );
        })}
      </div>
      {selectedDay.length > 0 && (
        <div className={style.calendarBottom}>
          <div className={style.calendarBottom__row}>
            <Text grey>Selected dates</Text>
            <div className={style.calendarBottom__rowItem}>
              <Text>{selectedDayList}</Text>
              <Text grey>({selectedDay.length} days)</Text>
            </div>
          </div>
          <div className={style.calendarBottom__row}>
            <Text grey>Total Price</Text>
            <div className={style.calendarBottom__rowItem}>
              <Text>${selectedDay.length * 50}</Text>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Calendar;
