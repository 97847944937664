import Text from '../../../../../../components/Text/Text';
import Title from '../../../../../../components/Title/Title';
import style from './Roadmap.module.scss';
import lockIcon from './assets/lock.svg';
import presaleIcon from '../../../../../../assets/btn_icons/presale.svg';

const Roadmap = () => {
  return (
    <div className={style.roadmap}>
      <Title.H3 fz16>Roadmap</Title.H3>
      <div className={style.roadmapBody}>
        <RoadmapItem icon={presaleIcon} title={'Presale'} />
        <RoadmapItem title={'Development'} isLock />
        <RoadmapItem title={'Launch'} isLock />
        <RoadmapItem title={'Search & Destroy'} isLock />
        <RoadmapItem title={'Meme Donation'} isLock />
      </div>
    </div>
  );
};

const RoadmapItem = ({ icon, title, isLock, isCompleted }) => {
  return (
    <div className={style.roadmapBody__item}>
      <div className={style.roadmapBody__itemIcon}>
        <img src={isLock ? lockIcon : icon} alt='' />
      </div>
      <div className={style.roadmapBody__itemContent}>
        <Text className={style.roadmapBody__itemTitle}>{title}</Text>
        {isLock && (
          <Text grey className={style.roadmapBody__itemLabel}>
            Not Yet
          </Text>
        )}
        {isCompleted && (
          <Text
            className={`${style.roadmapBody__itemLabel} ${style.completed}`}
          >
            Completed
          </Text>
        )}
      </div>
      {!isLock && (
        <div className={style.roadmapBody__itemShow}>
          <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M13.3334 6.9987V4.53203C13.3334 3.41193 13.3334 2.85187 13.1154 2.42405C12.9237 2.04773 12.6177 1.74176 12.2414 1.55002C11.8136 1.33203 11.2535 1.33203 10.1334 1.33203H5.86675C4.74664 1.33203 4.18659 1.33203 3.75877 1.55002C3.38244 1.74176 3.07648 2.04773 2.88474 2.42405C2.66675 2.85187 2.66675 3.41193 2.66675 4.53203V11.4654C2.66675 12.5855 2.66675 13.1455 2.88474 13.5733C3.07648 13.9497 3.38244 14.2556 3.75877 14.4474C4.18659 14.6654 4.74664 14.6654 5.86675 14.6654H7.66675M14.6667 14.6654L13.6667 13.6654M14.3334 11.9987C14.3334 13.2874 13.2887 14.332 12.0001 14.332C10.7114 14.332 9.66675 13.2874 9.66675 11.9987C9.66675 10.71 10.7114 9.66536 12.0001 9.66536C13.2887 9.66536 14.3334 10.71 14.3334 11.9987Z'
              stroke='white'
              stroke-opacity='0.5'
              stroke-linecap='round'
              stroke-linejoin='round'
            />
          </svg>
        </div>
      )}
    </div>
  );
};

export default Roadmap;
