import style from './index.module.scss';
import BlogContent from './modules/Content/Content';
import PopularSection from './modules/Popular/Popular';
import BlogTop from './modules/Top/Top';

const BlogPage = () => {
  return (
    <div className={style.blog}>
      <div className='__container'>
        <BlogTop />
        <BlogContent />
        <PopularSection />
      </div>
    </div>
  );
};

export default BlogPage;
