import Table from '../../../../components/Table/Table';
import TokenInfo from '../../../../components/TokenInfo/TokenInfo';
import style from './Boosted.module.scss';
import img from '../../../../components/Search/modules/Slider/assets/logo.jpg';
import fireIcon from '../../../../assets/btn_icons/boost.svg';
import Button from '../../../../components/Button/Button';
import { useMediaQuery } from 'usehooks-ts';
import { useState } from 'react';
import Empty from '../../../../components/Empty/Empty';
import firedIcon from '../../../../assets/icons/fired.svg';

const BoostedSection = () => {
  const isTablet = useMediaQuery('(max-width:991px)');
  const [isEmpty, setIsEmpty] = useState(false);

  return (
    <div className={style.boosted}>
      {isEmpty ? (
        <div className={style.empty}>
          <Empty
            icon={firedIcon}
            title={
              <>
                So far, you have <br /> not purchased Boost
              </>
            }
            text={
              <>
                The more boosts, the higher <br /> you are among other projects
              </>
            }
            button={<Button>Buy Boost</Button>}
          />
        </div>
      ) : (
        <Table.Container>
          <Table.Head>
            <Table.Row>
              <Table.Col>Project name</Table.Col>
              {!isTablet && <Table.Col>Date</Table.Col>}
              <Table.Col right>{isTablet ? 'Added' : 'Boosts added'}</Table.Col>
              {!isTablet && <Table.Col right>Current Boost</Table.Col>}
              <Table.Col center>Action</Table.Col>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            <Table.Row>
              <Table.Col>
                <TokenInfo icon={img} name={'Memereum'} label={'Meme'} />
              </Table.Col>
              {!isTablet && <Table.Col>02 Jun 2024 - 7 PM</Table.Col>}
              <Table.Col right>
                <div className={`${style.score}`}>
                  12X
                  <img src={fireIcon} alt='' />
                </div>
              </Table.Col>
              {!isTablet && (
                <Table.Col right>
                  <div className={`${style.score}`}>
                    1000
                    <img src={fireIcon} alt='' />
                  </div>
                </Table.Col>
              )}
              <Table.Col center>
                <Button sm filledGrey>
                  Detail
                </Button>
              </Table.Col>
            </Table.Row>
            <Table.Row>
              <Table.Col>
                <TokenInfo icon={img} name={'Memereum'} label={'Meme'} />
              </Table.Col>
              {!isTablet && <Table.Col>02 Jun 2024 - 7 PM</Table.Col>}
              <Table.Col right>
                <div className={`${style.score}`}>
                  12X
                  <img src={fireIcon} alt='' />
                </div>
              </Table.Col>
              {!isTablet && (
                <Table.Col right>
                  <div className={`${style.score}`}>
                    1000
                    <img src={fireIcon} alt='' />
                  </div>
                </Table.Col>
              )}
              <Table.Col center>
                <Button sm filledGrey>
                  Detail
                </Button>
              </Table.Col>
            </Table.Row>
            <Table.Row>
              <Table.Col>
                <TokenInfo icon={img} name={'Memereum'} label={'Meme'} />
              </Table.Col>
              {!isTablet && <Table.Col>02 Jun 2024 - 7 PM</Table.Col>}
              <Table.Col right>
                <div className={`${style.score}`}>
                  12X
                  <img src={fireIcon} alt='' />
                </div>
              </Table.Col>
              {!isTablet && (
                <Table.Col right>
                  <div className={`${style.score}`}>
                    1000
                    <img src={fireIcon} alt='' />
                  </div>
                </Table.Col>
              )}
              <Table.Col center>
                <Button sm filledGrey>
                  Detail
                </Button>
              </Table.Col>
            </Table.Row>
            <Table.Row>
              <Table.Col>
                <TokenInfo icon={img} name={'Memereum'} label={'Meme'} />
              </Table.Col>
              {!isTablet && <Table.Col>02 Jun 2024 - 7 PM</Table.Col>}
              <Table.Col right>
                <div className={`${style.score}`}>
                  12X
                  <img src={fireIcon} alt='' />
                </div>
              </Table.Col>
              {!isTablet && (
                <Table.Col right>
                  <div className={`${style.score}`}>
                    1000
                    <img src={fireIcon} alt='' />
                  </div>
                </Table.Col>
              )}
              <Table.Col center>
                <Button sm filledGrey>
                  Detail
                </Button>
              </Table.Col>
            </Table.Row>
            <Table.Row>
              <Table.Col>
                <TokenInfo icon={img} name={'Memereum'} label={'Meme'} />
              </Table.Col>
              {!isTablet && <Table.Col>02 Jun 2024 - 7 PM</Table.Col>}
              <Table.Col right>
                <div className={`${style.score}`}>
                  12X
                  <img src={fireIcon} alt='' />
                </div>
              </Table.Col>
              {!isTablet && (
                <Table.Col right>
                  <div className={`${style.score}`}>
                    1000
                    <img src={fireIcon} alt='' />
                  </div>
                </Table.Col>
              )}
              <Table.Col center>
                <Button sm filledGrey>
                  Detail
                </Button>
              </Table.Col>
            </Table.Row>
            <Table.Row>
              <Table.Col>
                <TokenInfo icon={img} name={'Memereum'} label={'Meme'} />
              </Table.Col>
              {!isTablet && <Table.Col>02 Jun 2024 - 7 PM</Table.Col>}
              <Table.Col right>
                <div className={`${style.score}`}>
                  12X
                  <img src={fireIcon} alt='' />
                </div>
              </Table.Col>
              {!isTablet && (
                <Table.Col right>
                  <div className={`${style.score}`}>
                    1000
                    <img src={fireIcon} alt='' />
                  </div>
                </Table.Col>
              )}
              <Table.Col center>
                <Button sm filledGrey>
                  Detail
                </Button>
              </Table.Col>
            </Table.Row>
          </Table.Body>
        </Table.Container>
      )}
    </div>
  );
};

export default BoostedSection;
