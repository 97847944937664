import Button from '../../Button/Button';
import Input from '../../Input/Input';
import ModalWrapper from '../../ModalWrapper/ModalWrapper';
import Select from '../../Select/Select';
import Textarea from '../../Textarea/Textarea';
import Title from '../../Title/Title';
import UploadFile from '../../UploadFile/UploadFile';
import style from './Ask.module.scss';
import icon from './assets/icon.svg';

const AskModal = ({ close }) => {
  return (
    <ModalWrapper closeModal={close}>
      <div className={style.modal}>
        <Title.H2 center className={style.modal__title}>
          Ask a Question
        </Title.H2>
        <div className={style.modal__icon}>
          <img src={icon} alt='' />
        </div>
        <Input
          icon={
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M1.33325 4.66797L4.79581 6.97634C4.99558 7.10952 5.09546 7.1761 5.20352 7.22328C5.29943 7.26515 5.39994 7.29558 5.50297 7.31394C5.61905 7.33464 5.73909 7.33464 5.97917 7.33464H10.0207C10.2607 7.33464 10.3808 7.33464 10.4969 7.31394C10.5999 7.29558 10.7004 7.26515 10.7963 7.22328C10.9044 7.17611 11.0043 7.10951 11.204 6.97634L14.6666 4.66797M4.53325 13.3346H11.4666C12.5867 13.3346 13.1467 13.3346 13.5746 13.1166C13.9509 12.9249 14.2569 12.6189 14.4486 12.2426C14.6666 11.8148 14.6666 11.2547 14.6666 10.1346V5.86797C14.6666 4.74786 14.6666 4.18781 14.4486 3.75999C14.2569 3.38366 13.9509 3.0777 13.5746 2.88596C13.1467 2.66797 12.5867 2.66797 11.4666 2.66797H4.53325C3.41315 2.66797 2.85309 2.66797 2.42527 2.88596C2.04895 3.0777 1.74299 3.38366 1.55124 3.75999C1.33325 4.18781 1.33325 4.74786 1.33325 5.86797V10.1346C1.33325 11.2547 1.33325 11.8148 1.55124 12.2426C1.74299 12.6189 2.04895 12.9249 2.42527 13.1166C2.85309 13.3346 3.41315 13.3346 4.53325 13.3346Z'
                stroke='white'
                stroke-opacity='0.5'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
            </svg>
          }
          placeholder={'Email'}
          className={style.input}
          big
          border
        />
        <Select
          className={style.input}
          big
          border
          icon={
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M13.3332 6.33203V4.53203C13.3332 3.41193 13.3332 2.85187 13.1152 2.42405C12.9234 2.04773 12.6175 1.74176 12.2412 1.55002C11.8133 1.33203 11.2533 1.33203 10.1332 1.33203H5.8665C4.7464 1.33203 4.18635 1.33203 3.75852 1.55002C3.3822 1.74176 3.07624 2.04773 2.88449 2.42405C2.6665 2.85187 2.6665 3.41193 2.6665 4.53203V11.4654C2.6665 12.5855 2.6665 13.1455 2.88449 13.5733C3.07624 13.9497 3.3822 14.2556 3.75852 14.4474C4.18635 14.6654 4.7464 14.6654 5.8665 14.6654H9.33317M10.9998 10.0002C11.1173 9.66627 11.3492 9.3847 11.6543 9.20535C11.9595 9.026 12.3183 8.96043 12.6672 9.02028C13.0161 9.08012 13.3325 9.2615 13.5605 9.5323C13.7884 9.8031 13.9132 10.1458 13.9127 10.4998C13.9127 11.4991 12.4138 11.9987 12.4138 11.9987M12.4332 13.9987H12.4399'
                stroke='white'
                stroke-opacity='0.5'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
            </svg>
          }
          options={[
            {
              name: 'Failed Transaction',
              value: 'Failed Transaction',
            },
            {
              name: 'Coin Not Approved',
              value: 'Coin Not Approved',
            },
            {
              name: 'My Coin Is Not Listed',
              value: 'My Coin Is Not Listed',
            },
            {
              name: 'Boost Didn’t Go Through',
              value: 'Boost Didn’t Go Through',
            },
          ]}
        />
        <Textarea
          big
          border
          placeholder={'Describe your problem'}
          className={style.input}
        />
        <UploadFile className={style.modal__upload} />
        <Button className={style.modal__submit} filled>
          Submit request
        </Button>
      </div>
    </ModalWrapper>
  );
};

export default AskModal;
