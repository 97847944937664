import Button from '../../../../components/Button/Button';
import Text from '../../../../components/Text/Text';
import Title from '../../../../components/Title/Title';
import style from './Card.module.scss';

const SubmitCoinCard = ({ children, title, label, onRemove, className }) => {
  return (
    <>
      <div className={`${style.card} ${className}`}>
        {onRemove && (
          <Button onClick={onRemove} noBorder className={style.card__remove}>
            Remove
          </Button>
        )}
        <Title.H2 center className={style.card_title}>
          {title}
        </Title.H2>
        <div className={style.card__content}>{children}</div>
      </div>
      {label && (
        <Text grey className={style.card__label}>
          {label}
        </Text>
      )}
    </>
  );
};

export default SubmitCoinCard;
