import Text from '../../../../components/Text/Text';
import Title from '../../../../components/Title/Title';
import style from './PurchaseCard.module.scss';

const PurchaseCard = ({ id, boost, bonus, price, choose }) => {
  return (
    <div className={style.card}>
      <div className={style.cardBoost}>
        <Title.H2>{boost}</Title.H2>
        <Text grey>Boost:</Text>
      </div>
      <div className={style.card__plus}>
        <svg
          width='16'
          height='17'
          viewBox='0 0 16 17'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M7.99992 3.83301V13.1663M3.33325 8.49967H12.6666'
            stroke='white'
            stroke-opacity='0.5'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
        </svg>
      </div>
      <div className={style.cardBonus}>
        <div className={style.cardBonus__x}>{bonus}</div>
        <Text grey>Bonus:</Text>
      </div>
      <Title.H2 className={style.card__price}>{price}</Title.H2>
      <button
        onClick={() => choose({ id, boost, bonus, price }, 'remove')}
        className={style.card__remove}
      >
        <svg
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M12 4L4 12M4 4L12 12'
            stroke='white'
            stroke-opacity='0.5'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
        </svg>
      </button>
    </div>
  );
};

export default PurchaseCard;
