import { useEffect, useRef, useState } from 'react';
import Text from '../Text/Text';
import style from './UploadFile.module.scss';

const UploadFile = ({ className }) => {
  const inputRef = useRef(null);
  const [fileName, setFileName] = useState('');

  function handleChange({ target }) {
    const fileNames = Array.from(target.files).map((file) => file.name);
    setFileName(fileNames);
  }

  const removeFile = () => {
    inputRef.current.value = null;
    setFileName('');
  };

  return (
    <label
      className={`${style.upload} ${fileName && style.uploaded} ${className}`}
    >
      <input onChange={handleChange} ref={inputRef} type='file' />
      {!fileName && (
        <button className={style.upload__btn}>
          <Text>Upload File</Text>
        </button>
      )}
      {fileName && (
        <div className={style.uploadContent}>
          <Text className={style.uploadContent__name}>{fileName}</Text>
          <button onClick={removeFile} className={style.uploadContent__remove}>
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M12 4L4 12M4 4L12 12'
                stroke='white'
                stroke-opacity='0.5'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
            </svg>
          </button>
        </div>
      )}
    </label>
  );
};

export default UploadFile;
