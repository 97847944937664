import { useMediaQuery } from 'usehooks-ts';
import style from './index.module.scss';
import Progress from './modules/Progress/Progress';
import ProgressMobile from './modules/ProgressMobile/ProgressMobile';
import GeneralSection from './sections/General/General';
import SubmitCoinTop from './sections/Top/Top';
import CategorySection from './sections/Category/Category';
import AboutSection from './sections/About/About';
import ContactSection from './sections/Contact/Contact';
import AddCard from './components/AddCard/AddCard';
import BottomSection from './sections/Bottom/Bottom';
import FaqSection from './sections/Faq/Faq';
import KycSection from './sections/Kyc/Kyc';
import RoadmapSection from './sections/Roadmap/Roadmap';
import AuditSection from './sections/Audit/Audit';
import AirdropSection from './sections/Airdrop/Airdrop';
import { useEffect, useState } from 'react';

const SubmitCoinPage = () => {
  const isMobile = useMediaQuery('(max-width:767px)');
  const [progress, setProgress] = useState(0);
  const [generalProgress, setGeneralProgress] = useState(0);
  const [categoryProgress, setCategoryProgress] = useState(0);
  const [aboutProgress, setAboutProgress] = useState(0);
  const [contactsProgress, setContactsProgress] = useState(0);

  useEffect(() => {
    setProgress(
      generalProgress + categoryProgress + aboutProgress + contactsProgress
    );
  }, [generalProgress, categoryProgress, aboutProgress, contactsProgress]);

  const handleSuccessGeneral = (status) => {
    if (status) {
      setGeneralProgress(25);
    } else {
      setGeneralProgress(0);
    }
  };
  const handleSuccessCategory = (status) => {
    if (status) {
      setCategoryProgress(25);
    } else {
      setCategoryProgress(0);
    }
  };
  const handleSuccessAbout = (status) => {
    if (status) {
      setAboutProgress(25);
    } else {
      setAboutProgress(0);
    }
  };
  const handleSuccessContacts = (status) => {
    if (status) {
      setContactsProgress(25);
    } else {
      setContactsProgress(0);
    }
  };

  return (
    <div className={style.coin}>
      <SubmitCoinTop />
      {isMobile && <ProgressMobile />}
      <div className='__container'>
        <div className={style.coinBody}>
          <div className={style.coinBody__content}>
            <GeneralSection handleSuccess={handleSuccessGeneral} />
            <CategorySection handleSuccess={handleSuccessCategory} />
            <AboutSection handleSuccess={handleSuccessAbout} />
            <ContactSection handleSuccess={handleSuccessContacts} />
            <FaqSection />
            <KycSection />
            <RoadmapSection />
            <AuditSection />
            <AirdropSection />
            <BottomSection />
          </div>

          {!isMobile && (
            <div className={style.coinBody__side}>
              <Progress
                generalProgress={generalProgress}
                categoryProgress={categoryProgress}
                aboutProgress={aboutProgress}
                contactsProgress={contactsProgress}
                progress={progress}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubmitCoinPage;
