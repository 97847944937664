import style from './VideoBlock.module.scss';
import img from './assets/img.jpg';

const VideoBlock = ({ src }) => {
  return (
    <div className={style.video}>
      <iframe
        src='https://www.youtube.com/embed/AcGbXS-AGCA?si=CLCe_b2iAtv4IcHq'
        title='YouTube video player'
        frameborder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
        referrerpolicy='strict-origin-when-cross-origin'
        allowfullscreen
      ></iframe>
    </div>
  );
};

export default VideoBlock;
