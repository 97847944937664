import { useState } from 'react';
import Text from '../Text/Text';
import style from './Switcher.module.scss';

const Switcher = ({ className, text, handler }) => {
  const [isSwitched, setIsSwitched] = useState(false);

  return (
    <label className={`${style.switcher} ${className}`}>
      <input
        onChange={(event) => {
          handler(event);
          setIsSwitched(!isSwitched);
        }}
        type='checkbox'
      />
      <div className={style.switcherBody}>
        {text && (
          <Text grey={isSwitched} className={style.switcherBody__text}>
            {text[0]}
          </Text>
        )}
        <div className={style.switcherBody__item}>
          <span></span>
        </div>
        {text && (
          <Text grey={!isSwitched} className={style.switcherBody__text}>
            {text[1]}
          </Text>
        )}
      </div>
    </label>
  );
};

export default Switcher;
