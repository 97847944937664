import style from './index.module.scss';
import AirdropsTable from './modules/Table/Table';
import AirdropsTop from './modules/Top/Top';

const AirdropsPage = () => {
  return (
    <div className={style.airdrops}>
      <div className='__container'>
        <AirdropsTop />
        <AirdropsTable />
      </div>
    </div>
  );
};

export default AirdropsPage;
