import Text from '../Text/Text';
import Title from '../Title/Title';
import style from './Empty.module.scss';

const Empty = ({ icon, title, text, className, button }) => {
  return (
    <div className={`${style.empty} ${className}`}>
      <div className={style.empty__icon}>
        <img src={icon} alt='' />
      </div>
      <Title.H2 className={style.empty__title}>{title}</Title.H2>
      <Text className={style.empty__text} grey>
        {text}
      </Text>
      {button && <div className={style.button}>{button}</div>}
    </div>
  );
};

export default Empty;
