import SearchResults from './modules/Results/Results';
import SearchSlider from './modules/Slider/Slider';
import style from './Search.module.scss';

import img from './modules/Slider/assets/logo.jpg';

const resultList = [
  {
    img: img,
    name: 'Memereum',
    label: 'Meme',
    address: '0xDbC3...92e382',
    rate: '55,555',
  },
  {
    img: img,
    name: 'Memereum',
    label: 'Meme',
    address: '0xDbC3...92e382',
    rate: '55,555',
  },
  {
    img: img,
    name: 'Memereum',
    label: 'Meme',
    address: '0xDbC3...92e382',
    rate: '55,555',
  },
  {
    img: img,
    name: 'Memereum',
    label: 'Meme',
    address: '0xDbC3...92e382',
    rate: '55,555',
  },
  {
    img: img,
    name: 'Memereum',
    label: 'Meme',
    address: '0xDbC3...92e382',
    rate: '55,555',
  },
  {
    img: img,
    name: 'Memereum',
    label: 'Meme',
    address: '0xDbC3...92e382',
    rate: '55,555',
  },
  {
    img: img,
    name: 'Memereum',
    label: 'Meme',
    address: '0xDbC3...92e382',
    rate: '55,555',
  },
  {
    img: img,
    name: 'Memereum',
    label: 'Meme',
    address: '0xDbC3...92e382',
    rate: '55,555',
  },
  {
    img: img,
    name: 'Memereum',
    label: 'Meme',
    address: '0xDbC3...92e382',
    rate: '55,555',
  },
  {
    img: img,
    name: 'Memereum',
    label: 'Meme',
    address: '0xDbC3...92e382',
    rate: '55,555',
  },
  {
    img: img,
    name: 'Memereum',
    label: 'Meme',
    address: '0xDbC3...92e382',
    rate: '55,555',
  },
  {
    img: img,
    name: 'Memereum',
    label: 'Meme',
    address: '0xDbC3...92e382',
    rate: '55,555',
  },
  {
    img: img,
    name: 'Memereum',
    label: 'Meme',
    address: '0xDbC3...92e382',
    rate: '55,555',
  },
  {
    img: img,
    name: 'Memereum',
    label: 'Meme',
    address: '0xDbC3...92e382',
    rate: '55,555',
  },
  {
    img: img,
    name: 'Memereum',
    label: 'Meme',
    address: '0xDbC3...92e382',
    rate: '55,555',
  },
  {
    img: img,
    name: 'Memereum',
    label: 'Meme',
    address: '0xDbC3...92e382',
    rate: '55,555',
  },
  {
    img: img,
    name: 'Memereum',
    label: 'Meme',
    address: '0xDbC3...92e382',
    rate: '55,555',
  },
  {
    img: img,
    name: 'Memereum',
    label: 'Meme',
    address: '0xDbC3...92e382',
    rate: '55,555',
  },
];

const Search = ({ isOpen, closeSearch }) => {
  return (
    <div className={`${style.search} ${isOpen && style.open}`}>
      <div className={style.search__bg} onClick={closeSearch}></div>
      <div className={`${style.con} __container`}>
        <div className={style.searchBody}>
          <SearchSlider />
          <SearchResults list={resultList} />
        </div>
      </div>
    </div>
  );
};

export default Search;
