import Button from '../../../../components/Button/Button';
import Text from '../../../../components/Text/Text';
import Timer from '../../../../components/Timer/Timer';
import Title from '../../../../components/Title/Title';
import style from './PresaleTimer.module.scss';

const PresaleTimer = () => {
  return (
    <div className={style.presale}>
      <Title.H3 fz16>Presale Live</Title.H3>
      <Text className={style.presale__text} grey>
        From Jan 10 to June 15, 2024
      </Text>
      <Timer className={style.presale__timer} />
      <Button noBorder className={style.presale__btn}>
        View Presale
        <svg
          width='17'
          height='16'
          viewBox='0 0 17 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M5.16675 11.3346L11.8334 4.66797M11.8334 4.66797H5.16675M11.8334 4.66797V11.3346'
            stroke='white'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
        </svg>
      </Button>
    </div>
  );
};

export default PresaleTimer;
