import Input from '../../../../components/Input/Input';
import Select from '../../../../components/Select/Select';
import UploadImg from '../../../../components/UploadImg/UploadImg';
import SubmitCoinCard from '../../modules/Card/Card';
import style from './General.module.scss';

const GeneralSection = ({ handleSuccess }) => {
  return (
    <SubmitCoinCard className={style.general} title={'General Info'}>
      <div className={style.generalBody}>
        <UploadImg
          label={'Recommended format JPG, PNG. 200х200px'}
          className={style.generalBody__img}
        />
        <div className={style.generalBody__inputs}>
          <Input
            big
            border
            placeholder={'Name'}
            icon={
              <svg
                width='16'
                height='16'
                viewBox='0 0 16 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <g clip-path='url(#clip0_14_14763)'>
                  <path
                    d='M5.33325 5.33301H5.33992M3.04175 1.95785L1.95809 3.0415C1.72749 3.2721 1.61219 3.38741 1.52973 3.52196C1.45663 3.64126 1.40275 3.77132 1.37009 3.90737C1.33325 4.06082 1.33325 4.22388 1.33325 4.55L1.33325 6.44935C1.33325 6.77547 1.33325 6.93853 1.37009 7.09198C1.40275 7.22803 1.45663 7.35809 1.52973 7.47739C1.61219 7.61194 1.72749 7.72724 1.95809 7.95785L7.07051 13.0703C7.86254 13.8623 8.25856 14.2583 8.71521 14.4067C9.1169 14.5372 9.5496 14.5372 9.95128 14.4067C10.4079 14.2583 10.804 13.8623 11.596 13.0703L13.0705 11.5957C13.8625 10.8037 14.2586 10.4077 14.4069 9.95104C14.5374 9.54935 14.5374 9.11666 14.4069 8.71497C14.2586 8.25831 13.8625 7.8623 13.0705 7.07026L7.95809 1.95785C7.72749 1.72724 7.61219 1.61194 7.47763 1.52949C7.35833 1.45638 7.22827 1.40251 7.09223 1.36985C6.93878 1.33301 6.77572 1.33301 6.4496 1.33301L4.55024 1.33301C4.22412 1.33301 4.06106 1.33301 3.90761 1.36985C3.77156 1.40251 3.6415 1.45638 3.52221 1.52949C3.38765 1.61194 3.27235 1.72724 3.04175 1.95785ZM5.66659 5.33301C5.66659 5.5171 5.51735 5.66634 5.33325 5.66634C5.14916 5.66634 4.99992 5.5171 4.99992 5.33301C4.99992 5.14891 5.14916 4.99967 5.33325 4.99967C5.51735 4.99967 5.66659 5.14891 5.66659 5.33301Z'
                    stroke='white'
                    stroke-opacity='0.5'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </g>
                <defs>
                  <clipPath id='clip0_14_14763'>
                    <rect width='16' height='16' fill='white' />
                  </clipPath>
                </defs>
              </svg>
            }
          />
          <Input
            big
            border
            placeholder={'Symbol'}
            icon={
              <svg
                width='16'
                height='16'
                viewBox='0 0 16 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <g clip-path='url(#clip0_14_5140)'>
                  <path
                    d='M5.33325 5.33301H5.33992M3.04175 1.95785L1.95809 3.0415C1.72749 3.2721 1.61219 3.38741 1.52973 3.52196C1.45663 3.64126 1.40275 3.77132 1.37009 3.90737C1.33325 4.06082 1.33325 4.22388 1.33325 4.55L1.33325 6.44935C1.33325 6.77547 1.33325 6.93853 1.37009 7.09198C1.40275 7.22803 1.45663 7.35809 1.52973 7.47739C1.61219 7.61194 1.72749 7.72724 1.95809 7.95785L7.07051 13.0703C7.86254 13.8623 8.25856 14.2583 8.71521 14.4067C9.1169 14.5372 9.5496 14.5372 9.95128 14.4067C10.4079 14.2583 10.804 13.8623 11.596 13.0703L13.0705 11.5957C13.8625 10.8037 14.2586 10.4077 14.4069 9.95104C14.5374 9.54935 14.5374 9.11666 14.4069 8.71497C14.2586 8.25831 13.8625 7.8623 13.0705 7.07026L7.95809 1.95785C7.72749 1.72724 7.61219 1.61194 7.47763 1.52949C7.35833 1.45638 7.22827 1.40251 7.09223 1.36985C6.93878 1.33301 6.77572 1.33301 6.4496 1.33301L4.55024 1.33301C4.22412 1.33301 4.06106 1.33301 3.90761 1.36985C3.77156 1.40251 3.6415 1.45638 3.52221 1.52949C3.38765 1.61194 3.27235 1.72724 3.04175 1.95785ZM5.66659 5.33301C5.66659 5.5171 5.51735 5.66634 5.33325 5.66634C5.14916 5.66634 4.99992 5.5171 4.99992 5.33301C4.99992 5.14891 5.14916 4.99967 5.33325 4.99967C5.51735 4.99967 5.66659 5.14891 5.66659 5.33301Z'
                    stroke='white'
                    stroke-opacity='0.5'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </g>
                <defs>
                  <clipPath id='clip0_14_5140'>
                    <rect width='16' height='16' fill='white' />
                  </clipPath>
                </defs>
              </svg>
            }
          />
          <Select
            big
            border
            icon={
              <svg
                width='16'
                height='16'
                viewBox='0 0 16 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <g clip-path='url(#clip0_14_5167)'>
                  <path
                    d='M6.66656 8.66697C6.95286 9.04972 7.31813 9.36642 7.73759 9.5956C8.15705 9.82477 8.62089 9.96105 9.09766 9.99519C9.57442 10.0293 10.0529 9.96055 10.5008 9.79349C10.9486 9.62643 11.3553 9.36502 11.6932 9.02697L13.6932 7.02697C14.3004 6.3983 14.6364 5.55629 14.6288 4.6823C14.6212 3.80831 14.2706 2.97227 13.6526 2.35424C13.0346 1.73621 12.1985 1.38565 11.3246 1.37806C10.4506 1.37046 9.60856 1.70644 8.97989 2.31364L7.83322 3.45364M9.33322 7.33364C9.04692 6.95088 8.68165 6.63418 8.26219 6.40501C7.84273 6.17584 7.37888 6.03956 6.90212 6.00541C6.42536 5.97127 5.94683 6.04006 5.49899 6.20711C5.05115 6.37417 4.64448 6.63559 4.30656 6.97364L2.30656 8.97364C1.69936 9.60231 1.36338 10.4443 1.37098 11.3183C1.37857 12.1923 1.72913 13.0283 2.34716 13.6464C2.96519 14.2644 3.80123 14.615 4.67522 14.6226C5.54921 14.6301 6.39121 14.2942 7.01989 13.687L8.15989 12.547'
                    stroke='white'
                    stroke-opacity='0.5'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </g>
                <defs>
                  <clipPath id='clip0_14_5167'>
                    <rect width='16' height='16' fill='white' />
                  </clipPath>
                </defs>
              </svg>
            }
            options={[
              { name: 'Network', value: '' },
              { name: 'Network 1', value: '123' },
              { name: 'Network 2', value: '312' },
            ]}
          />
        </div>
      </div>
    </SubmitCoinCard>
  );
};

export default GeneralSection;
