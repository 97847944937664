import ResultRow from './compontents/Row/Row';
import style from './Results.module.scss';

const SearchResults = ({ list }) => {
  return (
    <div className={style.results}>
      <div className={style.resultsContent}>
        {list.map((item, index) => (
          <ResultRow
            key={index}
            img={item.img}
            name={item.name}
            label={item.label}
            address={item.address}
            rate={item.rate}
          />
        ))}
      </div>
    </div>
  );
};

export default SearchResults;
