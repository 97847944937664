import { Swiper, SwiperSlide } from 'swiper/react';
import style from './Top.module.scss';
import Button from '../../../../components/Button/Button';
import Title from '../../../../components/Title/Title';

const AirdropsTop = () => {
  return (
    <div className={style.top}>
      <Title.H1>Airdrops</Title.H1>
      <Swiper
        className={style.top__tabs}
        spaceBetween={8}
        slidesPerView={'auto'}
      >
        <SwiperSlide>
          <Button filled>Show All</Button>
        </SwiperSlide>
        <SwiperSlide>
          <Button>Live Airdrops</Button>
        </SwiperSlide>
        <SwiperSlide>
          <Button>Upcoming</Button>
        </SwiperSlide>
        <SwiperSlide>
          <Button>Ended</Button>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default AirdropsTop;
