import { useState } from 'react';
import Input from '../../../../components/Input/Input';
import Textarea from '../../../../components/Textarea/Textarea';
import AddCard from '../../components/AddCard/AddCard';
import SubmitCoinCard from '../../modules/Card/Card';
import style from './Audit.module.scss';

const AuditSection = () => {
  const [isShown, setIsShown] = useState(false);

  return (
    <>
      {isShown && (
        <SubmitCoinCard
          onRemove={() => setIsShown(false)}
          title={'Submit Audit'}
          className={style.audit}
        >
          <div className={style.auditBody}>
            <Input
              icon={
                <svg
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M5.99992 11.3327H4.66659C2.82564 11.3327 1.33325 9.8403 1.33325 7.99935C1.33325 6.1584 2.82564 4.66602 4.66659 4.66602H5.99992M9.99992 11.3327H11.3333C13.1742 11.3327 14.6666 9.8403 14.6666 7.99935C14.6666 6.1584 13.1742 4.66602 11.3333 4.66602H9.99992M4.66659 7.99935L11.3333 7.99935'
                    stroke='white'
                    stroke-opacity='0.5'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              }
              big
              border
              placeholder={'Link to the audit: https://'}
            />
            <Textarea big border placeholder={'Comment (For moderation)'} />
          </div>
        </SubmitCoinCard>
      )}
      {!isShown && (
        <AddCard
          icon={
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M9.33341 1.51362V4.26732C9.33341 4.64069 9.33341 4.82737 9.40608 4.96998C9.46999 5.09542 9.57198 5.19741 9.69742 5.26132C9.84003 5.33398 10.0267 5.33398 10.4001 5.33398H13.1538M13.3334 6.65947V11.4673C13.3334 12.5874 13.3334 13.1475 13.1154 13.5753C12.9237 13.9516 12.6177 14.2576 12.2414 14.4493C11.8136 14.6673 11.2535 14.6673 10.1334 14.6673H5.86675C4.74664 14.6673 4.18659 14.6673 3.75877 14.4493C3.38244 14.2576 3.07648 13.9516 2.88473 13.5753C2.66675 13.1475 2.66675 12.5874 2.66675 11.4673V4.53398C2.66675 3.41388 2.66675 2.85383 2.88473 2.426C3.07648 2.04968 3.38244 1.74372 3.75877 1.55197C4.18659 1.33398 4.74664 1.33398 5.86675 1.33398H8.00793C8.49711 1.33398 8.7417 1.33398 8.97188 1.38924C9.17595 1.43824 9.37104 1.51905 9.54998 1.6287C9.75182 1.75239 9.92477 1.92534 10.2707 2.27124L12.3962 4.39673C12.7421 4.74263 12.915 4.91558 13.0387 5.11741C13.1484 5.29636 13.2292 5.49145 13.2782 5.69552C13.3334 5.9257 13.3334 6.17029 13.3334 6.65947Z'
                stroke='white'
                stroke-opacity='0.5'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
            </svg>
          }
          title={'Audit'}
          onClick={() => setIsShown(true)}
        />
      )}
    </>
  );
};

export default AuditSection;
