import Table from '../../../../components/Table/Table';
import Title from '../../../../components/Title/Title';
import TokenInfo from '../../../../components/TokenInfo/TokenInfo';
import style from './PromotedTableSection.module.scss';
import img from '../../../../components/Search/modules/Slider/assets/logo.jpg';
import { useEffect, useState } from 'react';
import Button from '../../../../components/Button/Button';
import { useMediaQuery } from 'usehooks-ts';
import Pagination from '../../../../components/Pagination/Pagination';

const PromotedTableSection = () => {
  const isTablet = useMediaQuery('(max-width:991px)');

  return (
    <div className={style.promoted}>
      <div className={style.promotedTop}>
        <Title.H1>Promoted</Title.H1>
        <Button>
          Place your coin
          <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M4.6665 11.3346L11.3332 4.66797M11.3332 4.66797H4.6665M11.3332 4.66797V11.3346'
              stroke='white'
              stroke-opacity='0.5'
              stroke-linecap='round'
              stroke-linejoin='round'
            />
          </svg>
        </Button>
      </div>
      <Table.Container className={style.promoted__table}>
        <Table.Head>
          <Table.Row>
            {!isTablet && <Table.Col>#</Table.Col>}
            <Table.Col></Table.Col>
            <Table.Col>Coin</Table.Col>
            {!isTablet && (
              <>
                <Table.Col>Badges</Table.Col>
                <Table.Col center>Chains</Table.Col>
                <Table.Col center>Transactions</Table.Col>
                <Table.Col center>Site Visitors</Table.Col>
                <Table.Col center>
                  <div className={style.head__row}>
                    Current Price
                    <svg
                      width='12'
                      height='12'
                      viewBox='0 0 12 12'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <g clip-path='url(#clip0_133_62278)'>
                        <path
                          d='M6 8V6M6 4H6.005M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z'
                          stroke='white'
                          stroke-opacity='0.5'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                      </g>
                      <defs>
                        <clipPath id='clip0_133_62278'>
                          <rect width='12' height='12' fill='white' />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </Table.Col>
                <Table.Col>Market Cap</Table.Col>
                <Table.Col center>Change 24h</Table.Col>
                <Table.Col center>Raise amount</Table.Col>
                <Table.Col center>Launch</Table.Col>
              </>
            )}
            <Table.Col></Table.Col>
            <Table.Col center>Total Boosts</Table.Col>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          <Table.Row>
            {!isTablet && <Table.Col>1</Table.Col>}
            <Table.Col>
              <button className={style.favorite}>
                <svg
                  width='14'
                  height='13'
                  viewBox='0 0 14 13'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M6.26134 0.775945C6.53462 0.11891 7.46538 0.118909 7.73866 0.775943L8.92838 3.63637C9.04358 3.91336 9.30407 4.10261 9.6031 4.12659L12.6912 4.37416C13.4005 4.43102 13.6881 5.31623 13.1477 5.77916L10.7949 7.79457C10.5671 7.98974 10.4676 8.29596 10.5372 8.58776L11.256 11.6012C11.4211 12.2934 10.6681 12.8405 10.0608 12.4695L7.417 10.8547C7.16099 10.6983 6.83901 10.6983 6.583 10.8547L3.93918 12.4695C3.3319 12.8405 2.5789 12.2934 2.74401 11.6012L3.46282 8.58776C3.53243 8.29596 3.43293 7.98974 3.2051 7.79458L0.852314 5.77917C0.311884 5.31623 0.599504 4.43102 1.30883 4.37416L4.3969 4.12659C4.69593 4.10261 4.95642 3.91336 5.07162 3.63637L6.26134 0.775945Z'
                    fill='white'
                    fill-opacity='0.1'
                  />
                </svg>
              </button>
            </Table.Col>
            <Table.Col>
              <TokenInfo icon={img} name={'Memereum'} label={'Meme'} />
            </Table.Col>
            {!isTablet && (
              <>
                <Table.Col>
                  <div className={style.chains}>
                    <TypeDiv title={'Audit'} />
                    <TypeDiv title={'KYC  99/100'} />
                    <TypeDiv title={'Meme'} />
                  </div>
                </Table.Col>
                <Table.Col>
                  <Chain
                    name={'ETH'}
                    icon={
                      <svg
                        width='16'
                        height='16'
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z'
                          fill='white'
                          fill-opacity='0.1'
                        />
                        <path
                          d='M7.99902 3V6.69568L10.9978 8.09145L7.99902 3Z'
                          fill='white'
                          fill-opacity='0.5'
                        />
                        <path
                          d='M7.99919 3L5 8.09145L7.99919 6.69568V3Z'
                          fill='white'
                        />
                        <path
                          d='M7.99902 10.4851V12.9963L10.9998 8.67188L7.99902 10.4851Z'
                          fill='white'
                          fill-opacity='0.5'
                        />
                        <path
                          d='M7.99919 12.9963V10.4847L5 8.67188L7.99919 12.9963Z'
                          fill='white'
                        />
                        <path
                          d='M7.99902 9.90393L10.9978 8.09025L7.99902 6.69531V9.90393Z'
                          fill='white'
                          fill-opacity='0.1'
                        />
                        <path
                          d='M5 8.09025L7.99919 9.90393V6.69531L5 8.09025Z'
                          fill='white'
                          fill-opacity='0.5'
                        />
                      </svg>
                    }
                  />
                </Table.Col>
                <Table.Col center>4234</Table.Col>
                <Table.Col center>
                  <Chain
                    name={'120K'}
                    reverse
                    smallIcon
                    transparentIcon
                    icon={
                      <svg
                        width='12'
                        height='12'
                        viewBox='0 0 12 12'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M8.4138 0.291703C8.30641 0.252347 8.19552 0.211485 8.07999 0.175844C6.24482 -0.400641 5.22397 0.5433 4.95266 1.51726C4.58097 2.84793 5.21459 3.75965 6.85658 5.10106C8.8694 6.74457 9.70256 8.27092 9.11883 10.3648C8.94667 10.9845 8.6586 11.5402 8.26389 12C9.89063 11.2974 11.2144 9.84416 11.7516 7.91792C12.6195 4.81091 11.1423 1.56073 8.4138 0.291703Z'
                          fill='#FF9700'
                        />
                        <path
                          d='M5.56033 6.32975C3.5978 4.81937 2.57808 3.187 3.13756 1.18055C3.22146 0.882968 3.33273 0.606473 3.46938 0.351562C1.96443 1.0936 0.757025 2.49214 0.248304 4.31246C-0.282671 6.21289 0.0643994 8.16573 1.03686 9.68876C1.70789 10.559 2.35753 11.2126 3.67621 11.5983C5.46184 12.1212 6.90155 11.3948 7.30222 9.95693C7.67467 8.62585 7.16983 7.64085 5.56033 6.32975Z'
                          fill='#2B3D52'
                        />
                      </svg>
                    }
                  />
                </Table.Col>
                <Table.Col center>$0.00031</Table.Col>
                <Table.Col>$ 7.37M</Table.Col>
                <Table.Col center>
                  <div className={`${style.diff} ${style.plus}`}>+15%</div>
                </Table.Col>
                <Table.Col center>500K</Table.Col>
                <Table.Col center>In 2 month</Table.Col>
              </>
            )}
            <Table.Col center>
              <Chain
                transparentIcon
                name='2,387'
                icon={
                  <svg
                    width='20'
                    height='20'
                    viewBox='0 0 20 20'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M14.9995 10.989C14.9676 8.52734 14.0196 6.82558 13.5049 6.08044C13.3725 5.88879 13.0898 5.91265 12.9873 6.1225C12.8296 6.44481 12.5766 6.85228 12.2321 7.06436C12.2321 7.06436 10.772 5.85628 10.7039 4.04639C10.6962 3.83982 10.4951 3.70022 10.3044 3.76674C9.53128 4.03669 7.89045 4.9435 7.49386 7.83261C7.16787 7.23021 6.39474 7.05422 5.89792 7.0047C5.69091 6.98397 5.5237 7.1804 5.57527 7.38726C5.95385 8.90512 5 9.81208 5 11.1164C5 13.9466 7.23092 16.2419 9.98568 16.25C12.7826 16.2582 15.0367 13.8602 14.9995 10.989Z'
                      fill='url(#paint0_linear_985_6256)'
                    />
                    <g filter='url(#filter0_b_985_6256)'>
                      <path
                        d='M16.2495 9.739C16.2176 7.27734 15.2696 5.57558 14.7549 4.83044C14.6225 4.63879 14.3398 4.66265 14.2373 4.8725C14.0796 5.19481 13.8266 5.60228 13.4821 5.81436C13.4821 5.81436 12.022 4.60628 11.9539 2.79639C11.9462 2.58982 11.7451 2.45022 11.5544 2.51674C10.7813 2.78669 9.14045 3.6935 8.74386 6.58261C8.41787 5.98021 7.64474 5.80422 7.14792 5.7547C6.94091 5.73397 6.7737 5.9304 6.82527 6.13726C7.20385 7.65512 6.25 8.56208 6.25 9.86637C6.25 12.6966 8.48092 14.9919 11.2357 15C14.0326 15.0082 16.2867 12.6102 16.2495 9.739Z'
                        fill='white'
                        fill-opacity='0.1'
                      />
                    </g>
                    <defs>
                      <filter
                        id='filter0_b_985_6256'
                        x='3.95'
                        y='0.2'
                        width='14.6'
                        height='17.1'
                        filterUnits='userSpaceOnUse'
                        color-interpolation-filters='sRGB'
                      >
                        <feFlood
                          flood-opacity='0'
                          result='BackgroundImageFix'
                        />
                        <feGaussianBlur
                          in='BackgroundImageFix'
                          stdDeviation='1.15'
                        />
                        <feComposite
                          in2='SourceAlpha'
                          operator='in'
                          result='effect1_backgroundBlur_985_6256'
                        />
                        <feBlend
                          mode='normal'
                          in='SourceGraphic'
                          in2='effect1_backgroundBlur_985_6256'
                          result='shape'
                        />
                      </filter>
                      <linearGradient
                        id='paint0_linear_985_6256'
                        x1='15.8333'
                        y1='3.75'
                        x2='8.82948'
                        y2='18.1169'
                        gradientUnits='userSpaceOnUse'
                      >
                        <stop stop-color='#F6FF8E' />
                        <stop offset='1' stop-color='#F26B41' />
                      </linearGradient>
                    </defs>
                  </svg>
                }
              />
            </Table.Col>
            <Table.Col center>
              <Button sm filledGrey>
                Boost
              </Button>
            </Table.Col>
          </Table.Row>
          <Table.Row>
            {!isTablet && <Table.Col>1</Table.Col>}
            <Table.Col>
              <button className={style.favorite}>
                <svg
                  width='14'
                  height='13'
                  viewBox='0 0 14 13'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M6.26134 0.775945C6.53462 0.11891 7.46538 0.118909 7.73866 0.775943L8.92838 3.63637C9.04358 3.91336 9.30407 4.10261 9.6031 4.12659L12.6912 4.37416C13.4005 4.43102 13.6881 5.31623 13.1477 5.77916L10.7949 7.79457C10.5671 7.98974 10.4676 8.29596 10.5372 8.58776L11.256 11.6012C11.4211 12.2934 10.6681 12.8405 10.0608 12.4695L7.417 10.8547C7.16099 10.6983 6.83901 10.6983 6.583 10.8547L3.93918 12.4695C3.3319 12.8405 2.5789 12.2934 2.74401 11.6012L3.46282 8.58776C3.53243 8.29596 3.43293 7.98974 3.2051 7.79458L0.852314 5.77917C0.311884 5.31623 0.599504 4.43102 1.30883 4.37416L4.3969 4.12659C4.69593 4.10261 4.95642 3.91336 5.07162 3.63637L6.26134 0.775945Z'
                    fill='white'
                    fill-opacity='0.1'
                  />
                </svg>
              </button>
            </Table.Col>
            <Table.Col>
              <TokenInfo icon={img} name={'Memereum'} label={'Meme'} />
            </Table.Col>
            {!isTablet && (
              <>
                <Table.Col>
                  <div className={style.chains}>
                    <TypeDiv title={'Audit'} />
                    <TypeDiv title={'KYC  99/100'} />
                    <TypeDiv title={'Meme'} />
                  </div>
                </Table.Col>
                <Table.Col>
                  <Chain
                    name={'ETH'}
                    icon={
                      <svg
                        width='16'
                        height='16'
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z'
                          fill='white'
                          fill-opacity='0.1'
                        />
                        <path
                          d='M7.99902 3V6.69568L10.9978 8.09145L7.99902 3Z'
                          fill='white'
                          fill-opacity='0.5'
                        />
                        <path
                          d='M7.99919 3L5 8.09145L7.99919 6.69568V3Z'
                          fill='white'
                        />
                        <path
                          d='M7.99902 10.4851V12.9963L10.9998 8.67188L7.99902 10.4851Z'
                          fill='white'
                          fill-opacity='0.5'
                        />
                        <path
                          d='M7.99919 12.9963V10.4847L5 8.67188L7.99919 12.9963Z'
                          fill='white'
                        />
                        <path
                          d='M7.99902 9.90393L10.9978 8.09025L7.99902 6.69531V9.90393Z'
                          fill='white'
                          fill-opacity='0.1'
                        />
                        <path
                          d='M5 8.09025L7.99919 9.90393V6.69531L5 8.09025Z'
                          fill='white'
                          fill-opacity='0.5'
                        />
                      </svg>
                    }
                  />
                </Table.Col>
                <Table.Col center>4234</Table.Col>
                <Table.Col center>
                  <Chain
                    name={'120K'}
                    reverse
                    smallIcon
                    transparentIcon
                    icon={
                      <svg
                        width='12'
                        height='12'
                        viewBox='0 0 12 12'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M8.4138 0.291703C8.30641 0.252347 8.19552 0.211485 8.07999 0.175844C6.24482 -0.400641 5.22397 0.5433 4.95266 1.51726C4.58097 2.84793 5.21459 3.75965 6.85658 5.10106C8.8694 6.74457 9.70256 8.27092 9.11883 10.3648C8.94667 10.9845 8.6586 11.5402 8.26389 12C9.89063 11.2974 11.2144 9.84416 11.7516 7.91792C12.6195 4.81091 11.1423 1.56073 8.4138 0.291703Z'
                          fill='#FF9700'
                        />
                        <path
                          d='M5.56033 6.32975C3.5978 4.81937 2.57808 3.187 3.13756 1.18055C3.22146 0.882968 3.33273 0.606473 3.46938 0.351562C1.96443 1.0936 0.757025 2.49214 0.248304 4.31246C-0.282671 6.21289 0.0643994 8.16573 1.03686 9.68876C1.70789 10.559 2.35753 11.2126 3.67621 11.5983C5.46184 12.1212 6.90155 11.3948 7.30222 9.95693C7.67467 8.62585 7.16983 7.64085 5.56033 6.32975Z'
                          fill='#2B3D52'
                        />
                      </svg>
                    }
                  />
                </Table.Col>
                <Table.Col center>$0.00031</Table.Col>
                <Table.Col>$ 7.37M</Table.Col>
                <Table.Col center>
                  <div className={`${style.diff} ${style.minus}`}>-15%</div>
                </Table.Col>
                <Table.Col center>500K</Table.Col>
                <Table.Col center>In 2 month</Table.Col>
              </>
            )}
            <Table.Col center>
              <Chain
                transparentIcon
                name='2,387'
                icon={
                  <svg
                    width='20'
                    height='20'
                    viewBox='0 0 20 20'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M14.9995 10.989C14.9676 8.52734 14.0196 6.82558 13.5049 6.08044C13.3725 5.88879 13.0898 5.91265 12.9873 6.1225C12.8296 6.44481 12.5766 6.85228 12.2321 7.06436C12.2321 7.06436 10.772 5.85628 10.7039 4.04639C10.6962 3.83982 10.4951 3.70022 10.3044 3.76674C9.53128 4.03669 7.89045 4.9435 7.49386 7.83261C7.16787 7.23021 6.39474 7.05422 5.89792 7.0047C5.69091 6.98397 5.5237 7.1804 5.57527 7.38726C5.95385 8.90512 5 9.81208 5 11.1164C5 13.9466 7.23092 16.2419 9.98568 16.25C12.7826 16.2582 15.0367 13.8602 14.9995 10.989Z'
                      fill='url(#paint0_linear_985_6256)'
                    />
                    <g filter='url(#filter0_b_985_6256)'>
                      <path
                        d='M16.2495 9.739C16.2176 7.27734 15.2696 5.57558 14.7549 4.83044C14.6225 4.63879 14.3398 4.66265 14.2373 4.8725C14.0796 5.19481 13.8266 5.60228 13.4821 5.81436C13.4821 5.81436 12.022 4.60628 11.9539 2.79639C11.9462 2.58982 11.7451 2.45022 11.5544 2.51674C10.7813 2.78669 9.14045 3.6935 8.74386 6.58261C8.41787 5.98021 7.64474 5.80422 7.14792 5.7547C6.94091 5.73397 6.7737 5.9304 6.82527 6.13726C7.20385 7.65512 6.25 8.56208 6.25 9.86637C6.25 12.6966 8.48092 14.9919 11.2357 15C14.0326 15.0082 16.2867 12.6102 16.2495 9.739Z'
                        fill='white'
                        fill-opacity='0.1'
                      />
                    </g>
                    <defs>
                      <filter
                        id='filter0_b_985_6256'
                        x='3.95'
                        y='0.2'
                        width='14.6'
                        height='17.1'
                        filterUnits='userSpaceOnUse'
                        color-interpolation-filters='sRGB'
                      >
                        <feFlood
                          flood-opacity='0'
                          result='BackgroundImageFix'
                        />
                        <feGaussianBlur
                          in='BackgroundImageFix'
                          stdDeviation='1.15'
                        />
                        <feComposite
                          in2='SourceAlpha'
                          operator='in'
                          result='effect1_backgroundBlur_985_6256'
                        />
                        <feBlend
                          mode='normal'
                          in='SourceGraphic'
                          in2='effect1_backgroundBlur_985_6256'
                          result='shape'
                        />
                      </filter>
                      <linearGradient
                        id='paint0_linear_985_6256'
                        x1='15.8333'
                        y1='3.75'
                        x2='8.82948'
                        y2='18.1169'
                        gradientUnits='userSpaceOnUse'
                      >
                        <stop stop-color='#F6FF8E' />
                        <stop offset='1' stop-color='#F26B41' />
                      </linearGradient>
                    </defs>
                  </svg>
                }
              />
            </Table.Col>
            <Table.Col center>
              <Button sm filledGrey>
                Boost
              </Button>
            </Table.Col>
          </Table.Row>
          <Table.Row>
            {!isTablet && <Table.Col>1</Table.Col>}
            <Table.Col>
              <button className={style.favorite}>
                <svg
                  width='14'
                  height='13'
                  viewBox='0 0 14 13'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M6.26134 0.775945C6.53462 0.11891 7.46538 0.118909 7.73866 0.775943L8.92838 3.63637C9.04358 3.91336 9.30407 4.10261 9.6031 4.12659L12.6912 4.37416C13.4005 4.43102 13.6881 5.31623 13.1477 5.77916L10.7949 7.79457C10.5671 7.98974 10.4676 8.29596 10.5372 8.58776L11.256 11.6012C11.4211 12.2934 10.6681 12.8405 10.0608 12.4695L7.417 10.8547C7.16099 10.6983 6.83901 10.6983 6.583 10.8547L3.93918 12.4695C3.3319 12.8405 2.5789 12.2934 2.74401 11.6012L3.46282 8.58776C3.53243 8.29596 3.43293 7.98974 3.2051 7.79458L0.852314 5.77917C0.311884 5.31623 0.599504 4.43102 1.30883 4.37416L4.3969 4.12659C4.69593 4.10261 4.95642 3.91336 5.07162 3.63637L6.26134 0.775945Z'
                    fill='white'
                    fill-opacity='0.1'
                  />
                </svg>
              </button>
            </Table.Col>
            <Table.Col>
              <TokenInfo icon={img} name={'Memereum'} label={'Meme'} />
            </Table.Col>
            {!isTablet && (
              <>
                <Table.Col>
                  <div className={style.chains}>
                    <TypeDiv title={'Audit'} />
                    <TypeDiv title={'KYC  99/100'} />
                    <TypeDiv title={'Meme'} />
                  </div>
                </Table.Col>
                <Table.Col>
                  <Chain
                    name={'ETH'}
                    icon={
                      <svg
                        width='16'
                        height='16'
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z'
                          fill='white'
                          fill-opacity='0.1'
                        />
                        <path
                          d='M7.99902 3V6.69568L10.9978 8.09145L7.99902 3Z'
                          fill='white'
                          fill-opacity='0.5'
                        />
                        <path
                          d='M7.99919 3L5 8.09145L7.99919 6.69568V3Z'
                          fill='white'
                        />
                        <path
                          d='M7.99902 10.4851V12.9963L10.9998 8.67188L7.99902 10.4851Z'
                          fill='white'
                          fill-opacity='0.5'
                        />
                        <path
                          d='M7.99919 12.9963V10.4847L5 8.67188L7.99919 12.9963Z'
                          fill='white'
                        />
                        <path
                          d='M7.99902 9.90393L10.9978 8.09025L7.99902 6.69531V9.90393Z'
                          fill='white'
                          fill-opacity='0.1'
                        />
                        <path
                          d='M5 8.09025L7.99919 9.90393V6.69531L5 8.09025Z'
                          fill='white'
                          fill-opacity='0.5'
                        />
                      </svg>
                    }
                  />
                </Table.Col>
                <Table.Col center>4234</Table.Col>
                <Table.Col center>
                  <Chain
                    name={'120K'}
                    reverse
                    smallIcon
                    transparentIcon
                    icon={
                      <svg
                        width='12'
                        height='12'
                        viewBox='0 0 12 12'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M8.4138 0.291703C8.30641 0.252347 8.19552 0.211485 8.07999 0.175844C6.24482 -0.400641 5.22397 0.5433 4.95266 1.51726C4.58097 2.84793 5.21459 3.75965 6.85658 5.10106C8.8694 6.74457 9.70256 8.27092 9.11883 10.3648C8.94667 10.9845 8.6586 11.5402 8.26389 12C9.89063 11.2974 11.2144 9.84416 11.7516 7.91792C12.6195 4.81091 11.1423 1.56073 8.4138 0.291703Z'
                          fill='#FF9700'
                        />
                        <path
                          d='M5.56033 6.32975C3.5978 4.81937 2.57808 3.187 3.13756 1.18055C3.22146 0.882968 3.33273 0.606473 3.46938 0.351562C1.96443 1.0936 0.757025 2.49214 0.248304 4.31246C-0.282671 6.21289 0.0643994 8.16573 1.03686 9.68876C1.70789 10.559 2.35753 11.2126 3.67621 11.5983C5.46184 12.1212 6.90155 11.3948 7.30222 9.95693C7.67467 8.62585 7.16983 7.64085 5.56033 6.32975Z'
                          fill='#2B3D52'
                        />
                      </svg>
                    }
                  />
                </Table.Col>
                <Table.Col center>$0.00031</Table.Col>
                <Table.Col>$ 7.37M</Table.Col>
                <Table.Col center>
                  <div className={`${style.diff}`}>-</div>
                </Table.Col>
                <Table.Col center>500K</Table.Col>
                <Table.Col center>In 2 month</Table.Col>
              </>
            )}
            <Table.Col center>
              <Chain
                transparentIcon
                name='2,387'
                icon={
                  <svg
                    width='20'
                    height='20'
                    viewBox='0 0 20 20'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M14.9995 10.989C14.9676 8.52734 14.0196 6.82558 13.5049 6.08044C13.3725 5.88879 13.0898 5.91265 12.9873 6.1225C12.8296 6.44481 12.5766 6.85228 12.2321 7.06436C12.2321 7.06436 10.772 5.85628 10.7039 4.04639C10.6962 3.83982 10.4951 3.70022 10.3044 3.76674C9.53128 4.03669 7.89045 4.9435 7.49386 7.83261C7.16787 7.23021 6.39474 7.05422 5.89792 7.0047C5.69091 6.98397 5.5237 7.1804 5.57527 7.38726C5.95385 8.90512 5 9.81208 5 11.1164C5 13.9466 7.23092 16.2419 9.98568 16.25C12.7826 16.2582 15.0367 13.8602 14.9995 10.989Z'
                      fill='url(#paint0_linear_985_6256)'
                    />
                    <g filter='url(#filter0_b_985_6256)'>
                      <path
                        d='M16.2495 9.739C16.2176 7.27734 15.2696 5.57558 14.7549 4.83044C14.6225 4.63879 14.3398 4.66265 14.2373 4.8725C14.0796 5.19481 13.8266 5.60228 13.4821 5.81436C13.4821 5.81436 12.022 4.60628 11.9539 2.79639C11.9462 2.58982 11.7451 2.45022 11.5544 2.51674C10.7813 2.78669 9.14045 3.6935 8.74386 6.58261C8.41787 5.98021 7.64474 5.80422 7.14792 5.7547C6.94091 5.73397 6.7737 5.9304 6.82527 6.13726C7.20385 7.65512 6.25 8.56208 6.25 9.86637C6.25 12.6966 8.48092 14.9919 11.2357 15C14.0326 15.0082 16.2867 12.6102 16.2495 9.739Z'
                        fill='white'
                        fill-opacity='0.1'
                      />
                    </g>
                    <defs>
                      <filter
                        id='filter0_b_985_6256'
                        x='3.95'
                        y='0.2'
                        width='14.6'
                        height='17.1'
                        filterUnits='userSpaceOnUse'
                        color-interpolation-filters='sRGB'
                      >
                        <feFlood
                          flood-opacity='0'
                          result='BackgroundImageFix'
                        />
                        <feGaussianBlur
                          in='BackgroundImageFix'
                          stdDeviation='1.15'
                        />
                        <feComposite
                          in2='SourceAlpha'
                          operator='in'
                          result='effect1_backgroundBlur_985_6256'
                        />
                        <feBlend
                          mode='normal'
                          in='SourceGraphic'
                          in2='effect1_backgroundBlur_985_6256'
                          result='shape'
                        />
                      </filter>
                      <linearGradient
                        id='paint0_linear_985_6256'
                        x1='15.8333'
                        y1='3.75'
                        x2='8.82948'
                        y2='18.1169'
                        gradientUnits='userSpaceOnUse'
                      >
                        <stop stop-color='#F6FF8E' />
                        <stop offset='1' stop-color='#F26B41' />
                      </linearGradient>
                    </defs>
                  </svg>
                }
              />
            </Table.Col>
            <Table.Col center>
              <Button sm filledGrey>
                Boost
              </Button>
            </Table.Col>
          </Table.Row>
          <Table.Row>
            {!isTablet && <Table.Col>1</Table.Col>}
            <Table.Col>
              <button className={style.favorite}>
                <svg
                  width='14'
                  height='13'
                  viewBox='0 0 14 13'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M6.26134 0.775945C6.53462 0.11891 7.46538 0.118909 7.73866 0.775943L8.92838 3.63637C9.04358 3.91336 9.30407 4.10261 9.6031 4.12659L12.6912 4.37416C13.4005 4.43102 13.6881 5.31623 13.1477 5.77916L10.7949 7.79457C10.5671 7.98974 10.4676 8.29596 10.5372 8.58776L11.256 11.6012C11.4211 12.2934 10.6681 12.8405 10.0608 12.4695L7.417 10.8547C7.16099 10.6983 6.83901 10.6983 6.583 10.8547L3.93918 12.4695C3.3319 12.8405 2.5789 12.2934 2.74401 11.6012L3.46282 8.58776C3.53243 8.29596 3.43293 7.98974 3.2051 7.79458L0.852314 5.77917C0.311884 5.31623 0.599504 4.43102 1.30883 4.37416L4.3969 4.12659C4.69593 4.10261 4.95642 3.91336 5.07162 3.63637L6.26134 0.775945Z'
                    fill='white'
                    fill-opacity='0.1'
                  />
                </svg>
              </button>
            </Table.Col>
            <Table.Col>
              <TokenInfo icon={img} name={'Memereum'} label={'Meme'} />
            </Table.Col>
            {!isTablet && (
              <>
                <Table.Col>
                  <div className={style.chains}>
                    <TypeDiv title={'Audit'} />
                    <TypeDiv title={'KYC  99/100'} />
                    <TypeDiv title={'Meme'} />
                  </div>
                </Table.Col>
                <Table.Col>
                  <Chain
                    name={'ETH'}
                    icon={
                      <svg
                        width='16'
                        height='16'
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z'
                          fill='white'
                          fill-opacity='0.1'
                        />
                        <path
                          d='M7.99902 3V6.69568L10.9978 8.09145L7.99902 3Z'
                          fill='white'
                          fill-opacity='0.5'
                        />
                        <path
                          d='M7.99919 3L5 8.09145L7.99919 6.69568V3Z'
                          fill='white'
                        />
                        <path
                          d='M7.99902 10.4851V12.9963L10.9998 8.67188L7.99902 10.4851Z'
                          fill='white'
                          fill-opacity='0.5'
                        />
                        <path
                          d='M7.99919 12.9963V10.4847L5 8.67188L7.99919 12.9963Z'
                          fill='white'
                        />
                        <path
                          d='M7.99902 9.90393L10.9978 8.09025L7.99902 6.69531V9.90393Z'
                          fill='white'
                          fill-opacity='0.1'
                        />
                        <path
                          d='M5 8.09025L7.99919 9.90393V6.69531L5 8.09025Z'
                          fill='white'
                          fill-opacity='0.5'
                        />
                      </svg>
                    }
                  />
                </Table.Col>
                <Table.Col center>4234</Table.Col>
                <Table.Col center>
                  <Chain
                    name={'120K'}
                    reverse
                    smallIcon
                    transparentIcon
                    icon={
                      <svg
                        width='12'
                        height='12'
                        viewBox='0 0 12 12'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M8.4138 0.291703C8.30641 0.252347 8.19552 0.211485 8.07999 0.175844C6.24482 -0.400641 5.22397 0.5433 4.95266 1.51726C4.58097 2.84793 5.21459 3.75965 6.85658 5.10106C8.8694 6.74457 9.70256 8.27092 9.11883 10.3648C8.94667 10.9845 8.6586 11.5402 8.26389 12C9.89063 11.2974 11.2144 9.84416 11.7516 7.91792C12.6195 4.81091 11.1423 1.56073 8.4138 0.291703Z'
                          fill='#FF9700'
                        />
                        <path
                          d='M5.56033 6.32975C3.5978 4.81937 2.57808 3.187 3.13756 1.18055C3.22146 0.882968 3.33273 0.606473 3.46938 0.351562C1.96443 1.0936 0.757025 2.49214 0.248304 4.31246C-0.282671 6.21289 0.0643994 8.16573 1.03686 9.68876C1.70789 10.559 2.35753 11.2126 3.67621 11.5983C5.46184 12.1212 6.90155 11.3948 7.30222 9.95693C7.67467 8.62585 7.16983 7.64085 5.56033 6.32975Z'
                          fill='#2B3D52'
                        />
                      </svg>
                    }
                  />
                </Table.Col>
                <Table.Col center>$0.00031</Table.Col>
                <Table.Col>
                  <Chain
                    name={'Presale <br/> $0.001'}
                    transparentIcon
                    icon={
                      <svg
                        width='16'
                        height='16'
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M10.8259 11.2583C10.9278 11.425 10.9874 11.62 10.9982 11.8222C11.009 12.0244 10.9707 12.226 10.8874 12.4054C10.8041 12.5848 10.6789 12.735 10.5254 12.8398C10.372 12.9446 10.1961 13 10.0167 13H5.98328C5.80395 13 5.62802 12.9446 5.47456 12.8398C5.32109 12.735 5.19592 12.5848 5.11259 12.4054C5.02927 12.226 4.99095 12.0244 5.0018 11.8222C5.01265 11.62 5.07224 11.425 5.17414 11.2583L6.52402 9.03949C6.9125 8.40094 6.9125 7.59906 6.52402 6.96051L5.17414 4.74167C5.07224 4.57498 5.01265 4.38 5.0018 4.17782C4.99095 3.97564 5.02927 3.77395 5.11259 3.59459C5.19592 3.41523 5.32109 3.26502 5.47456 3.16022C5.62802 3.05542 5.80395 3.00001 5.98328 3H10.0167C10.1961 3.00001 10.372 3.05542 10.5254 3.16022C10.6789 3.26502 10.8041 3.41523 10.8874 3.59459C10.9707 3.77395 11.009 3.97564 10.9982 4.17782C10.9874 4.38 10.9278 4.57498 10.8259 4.74167L9.47598 6.96051C9.0875 7.59906 9.0875 8.40094 9.47598 9.03949L10.8259 11.2583Z'
                          fill='url(#paint0_linear_133_63079)'
                        />
                        <g filter='url(#filter0_b_133_63079)'>
                          <path
                            d='M11.8259 10.2583C11.9278 10.425 11.9874 10.62 11.9982 10.8222C12.009 11.0244 11.9707 11.226 11.8874 11.4054C11.8041 11.5848 11.6789 11.735 11.5254 11.8398C11.372 11.9446 11.1961 12 11.0167 12H6.98328C6.80395 12 6.62802 11.9446 6.47456 11.8398C6.32109 11.735 6.19592 11.5848 6.11259 11.4054C6.02927 11.226 5.99095 11.0244 6.0018 10.8222C6.01265 10.62 6.07224 10.425 6.17414 10.2583L7.52402 8.03949C7.9125 7.40094 7.9125 6.59906 7.52402 5.96051L6.17414 3.74167C6.07224 3.57498 6.01265 3.38 6.0018 3.17782C5.99095 2.97564 6.02927 2.77395 6.11259 2.59459C6.19592 2.41523 6.32109 2.26502 6.47456 2.16022C6.62802 2.05542 6.80395 2.00001 6.98328 2H11.0167C11.1961 2.00001 11.372 2.05542 11.5254 2.16022C11.6789 2.26502 11.8041 2.41523 11.8874 2.59459C11.9707 2.77395 12.009 2.97564 11.9982 3.17782C11.9874 3.38 11.9278 3.57498 11.8259 3.74167L10.476 5.96051C10.0875 6.59906 10.0875 7.40094 10.476 8.03949L11.8259 10.2583Z'
                            fill='white'
                            fill-opacity='0.1'
                          />
                        </g>
                        <defs>
                          <filter
                            id='filter0_b_133_63079'
                            x='4.1'
                            y='0.1'
                            width='9.8'
                            height='13.8'
                            filterUnits='userSpaceOnUse'
                            color-interpolation-filters='sRGB'
                          >
                            <feFlood
                              flood-opacity='0'
                              result='BackgroundImageFix'
                            />
                            <feGaussianBlur
                              in='BackgroundImageFix'
                              stdDeviation='0.95'
                            />
                            <feComposite
                              in2='SourceAlpha'
                              operator='in'
                              result='effect1_backgroundBlur_133_63079'
                            />
                            <feBlend
                              mode='normal'
                              in='SourceGraphic'
                              in2='effect1_backgroundBlur_133_63079'
                              result='shape'
                            />
                          </filter>
                          <linearGradient
                            id='paint0_linear_133_63079'
                            x1='11.5'
                            y1='3'
                            x2='5'
                            y2='13'
                            gradientUnits='userSpaceOnUse'
                          >
                            <stop stop-color='#BFD3F0' />
                            <stop offset='1' stop-color='#B7FF96' />
                          </linearGradient>
                        </defs>
                      </svg>
                    }
                  />
                </Table.Col>
                <Table.Col center>
                  <div className={`${style.diff}`}>-</div>
                </Table.Col>
                <Table.Col center>500K</Table.Col>
                <Table.Col center>In 2 month</Table.Col>
              </>
            )}
            <Table.Col center>
              <Chain
                transparentIcon
                name='2,387'
                icon={
                  <svg
                    width='20'
                    height='20'
                    viewBox='0 0 20 20'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M14.9995 10.989C14.9676 8.52734 14.0196 6.82558 13.5049 6.08044C13.3725 5.88879 13.0898 5.91265 12.9873 6.1225C12.8296 6.44481 12.5766 6.85228 12.2321 7.06436C12.2321 7.06436 10.772 5.85628 10.7039 4.04639C10.6962 3.83982 10.4951 3.70022 10.3044 3.76674C9.53128 4.03669 7.89045 4.9435 7.49386 7.83261C7.16787 7.23021 6.39474 7.05422 5.89792 7.0047C5.69091 6.98397 5.5237 7.1804 5.57527 7.38726C5.95385 8.90512 5 9.81208 5 11.1164C5 13.9466 7.23092 16.2419 9.98568 16.25C12.7826 16.2582 15.0367 13.8602 14.9995 10.989Z'
                      fill='url(#paint0_linear_985_6256)'
                    />
                    <g filter='url(#filter0_b_985_6256)'>
                      <path
                        d='M16.2495 9.739C16.2176 7.27734 15.2696 5.57558 14.7549 4.83044C14.6225 4.63879 14.3398 4.66265 14.2373 4.8725C14.0796 5.19481 13.8266 5.60228 13.4821 5.81436C13.4821 5.81436 12.022 4.60628 11.9539 2.79639C11.9462 2.58982 11.7451 2.45022 11.5544 2.51674C10.7813 2.78669 9.14045 3.6935 8.74386 6.58261C8.41787 5.98021 7.64474 5.80422 7.14792 5.7547C6.94091 5.73397 6.7737 5.9304 6.82527 6.13726C7.20385 7.65512 6.25 8.56208 6.25 9.86637C6.25 12.6966 8.48092 14.9919 11.2357 15C14.0326 15.0082 16.2867 12.6102 16.2495 9.739Z'
                        fill='white'
                        fill-opacity='0.1'
                      />
                    </g>
                    <defs>
                      <filter
                        id='filter0_b_985_6256'
                        x='3.95'
                        y='0.2'
                        width='14.6'
                        height='17.1'
                        filterUnits='userSpaceOnUse'
                        color-interpolation-filters='sRGB'
                      >
                        <feFlood
                          flood-opacity='0'
                          result='BackgroundImageFix'
                        />
                        <feGaussianBlur
                          in='BackgroundImageFix'
                          stdDeviation='1.15'
                        />
                        <feComposite
                          in2='SourceAlpha'
                          operator='in'
                          result='effect1_backgroundBlur_985_6256'
                        />
                        <feBlend
                          mode='normal'
                          in='SourceGraphic'
                          in2='effect1_backgroundBlur_985_6256'
                          result='shape'
                        />
                      </filter>
                      <linearGradient
                        id='paint0_linear_985_6256'
                        x1='15.8333'
                        y1='3.75'
                        x2='8.82948'
                        y2='18.1169'
                        gradientUnits='userSpaceOnUse'
                      >
                        <stop stop-color='#F6FF8E' />
                        <stop offset='1' stop-color='#F26B41' />
                      </linearGradient>
                    </defs>
                  </svg>
                }
              />
            </Table.Col>
            <Table.Col center>
              <Button sm filledGrey>
                Boost
              </Button>
            </Table.Col>
          </Table.Row>
          <Table.Row>
            {!isTablet && <Table.Col>1</Table.Col>}
            <Table.Col>
              <button className={style.favorite}>
                <svg
                  width='14'
                  height='13'
                  viewBox='0 0 14 13'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M6.26134 0.775945C6.53462 0.11891 7.46538 0.118909 7.73866 0.775943L8.92838 3.63637C9.04358 3.91336 9.30407 4.10261 9.6031 4.12659L12.6912 4.37416C13.4005 4.43102 13.6881 5.31623 13.1477 5.77916L10.7949 7.79457C10.5671 7.98974 10.4676 8.29596 10.5372 8.58776L11.256 11.6012C11.4211 12.2934 10.6681 12.8405 10.0608 12.4695L7.417 10.8547C7.16099 10.6983 6.83901 10.6983 6.583 10.8547L3.93918 12.4695C3.3319 12.8405 2.5789 12.2934 2.74401 11.6012L3.46282 8.58776C3.53243 8.29596 3.43293 7.98974 3.2051 7.79458L0.852314 5.77917C0.311884 5.31623 0.599504 4.43102 1.30883 4.37416L4.3969 4.12659C4.69593 4.10261 4.95642 3.91336 5.07162 3.63637L6.26134 0.775945Z'
                    fill='white'
                    fill-opacity='0.1'
                  />
                </svg>
              </button>
            </Table.Col>
            <Table.Col>
              <TokenInfo icon={img} name={'Memereum'} label={'Meme'} />
            </Table.Col>
            {!isTablet && (
              <>
                <Table.Col>
                  <div className={style.chains}>
                    <TypeDiv title={'Audit'} />
                    <TypeDiv title={'KYC  99/100'} />
                    <TypeDiv title={'Meme'} />
                  </div>
                </Table.Col>
                <Table.Col>
                  <Chain
                    name={'ETH'}
                    icon={
                      <svg
                        width='16'
                        height='16'
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z'
                          fill='white'
                          fill-opacity='0.1'
                        />
                        <path
                          d='M7.99902 3V6.69568L10.9978 8.09145L7.99902 3Z'
                          fill='white'
                          fill-opacity='0.5'
                        />
                        <path
                          d='M7.99919 3L5 8.09145L7.99919 6.69568V3Z'
                          fill='white'
                        />
                        <path
                          d='M7.99902 10.4851V12.9963L10.9998 8.67188L7.99902 10.4851Z'
                          fill='white'
                          fill-opacity='0.5'
                        />
                        <path
                          d='M7.99919 12.9963V10.4847L5 8.67188L7.99919 12.9963Z'
                          fill='white'
                        />
                        <path
                          d='M7.99902 9.90393L10.9978 8.09025L7.99902 6.69531V9.90393Z'
                          fill='white'
                          fill-opacity='0.1'
                        />
                        <path
                          d='M5 8.09025L7.99919 9.90393V6.69531L5 8.09025Z'
                          fill='white'
                          fill-opacity='0.5'
                        />
                      </svg>
                    }
                  />
                </Table.Col>
                <Table.Col center>4234</Table.Col>
                <Table.Col center>
                  <Chain
                    name={'120K'}
                    reverse
                    smallIcon
                    transparentIcon
                    icon={
                      <svg
                        width='12'
                        height='12'
                        viewBox='0 0 12 12'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M8.4138 0.291703C8.30641 0.252347 8.19552 0.211485 8.07999 0.175844C6.24482 -0.400641 5.22397 0.5433 4.95266 1.51726C4.58097 2.84793 5.21459 3.75965 6.85658 5.10106C8.8694 6.74457 9.70256 8.27092 9.11883 10.3648C8.94667 10.9845 8.6586 11.5402 8.26389 12C9.89063 11.2974 11.2144 9.84416 11.7516 7.91792C12.6195 4.81091 11.1423 1.56073 8.4138 0.291703Z'
                          fill='#FF9700'
                        />
                        <path
                          d='M5.56033 6.32975C3.5978 4.81937 2.57808 3.187 3.13756 1.18055C3.22146 0.882968 3.33273 0.606473 3.46938 0.351562C1.96443 1.0936 0.757025 2.49214 0.248304 4.31246C-0.282671 6.21289 0.0643994 8.16573 1.03686 9.68876C1.70789 10.559 2.35753 11.2126 3.67621 11.5983C5.46184 12.1212 6.90155 11.3948 7.30222 9.95693C7.67467 8.62585 7.16983 7.64085 5.56033 6.32975Z'
                          fill='#2B3D52'
                        />
                      </svg>
                    }
                  />
                </Table.Col>
                <Table.Col center>$0.00031</Table.Col>
                <Table.Col>$ 7.37M</Table.Col>
                <Table.Col center>
                  <div className={`${style.diff}`}>-</div>
                </Table.Col>
                <Table.Col center>500K</Table.Col>
                <Table.Col center>In 2 month</Table.Col>
              </>
            )}
            <Table.Col center>
              <Chain
                transparentIcon
                name='2,387'
                icon={
                  <svg
                    width='20'
                    height='20'
                    viewBox='0 0 20 20'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M14.9995 10.989C14.9676 8.52734 14.0196 6.82558 13.5049 6.08044C13.3725 5.88879 13.0898 5.91265 12.9873 6.1225C12.8296 6.44481 12.5766 6.85228 12.2321 7.06436C12.2321 7.06436 10.772 5.85628 10.7039 4.04639C10.6962 3.83982 10.4951 3.70022 10.3044 3.76674C9.53128 4.03669 7.89045 4.9435 7.49386 7.83261C7.16787 7.23021 6.39474 7.05422 5.89792 7.0047C5.69091 6.98397 5.5237 7.1804 5.57527 7.38726C5.95385 8.90512 5 9.81208 5 11.1164C5 13.9466 7.23092 16.2419 9.98568 16.25C12.7826 16.2582 15.0367 13.8602 14.9995 10.989Z'
                      fill='url(#paint0_linear_985_6256)'
                    />
                    <g filter='url(#filter0_b_985_6256)'>
                      <path
                        d='M16.2495 9.739C16.2176 7.27734 15.2696 5.57558 14.7549 4.83044C14.6225 4.63879 14.3398 4.66265 14.2373 4.8725C14.0796 5.19481 13.8266 5.60228 13.4821 5.81436C13.4821 5.81436 12.022 4.60628 11.9539 2.79639C11.9462 2.58982 11.7451 2.45022 11.5544 2.51674C10.7813 2.78669 9.14045 3.6935 8.74386 6.58261C8.41787 5.98021 7.64474 5.80422 7.14792 5.7547C6.94091 5.73397 6.7737 5.9304 6.82527 6.13726C7.20385 7.65512 6.25 8.56208 6.25 9.86637C6.25 12.6966 8.48092 14.9919 11.2357 15C14.0326 15.0082 16.2867 12.6102 16.2495 9.739Z'
                        fill='white'
                        fill-opacity='0.1'
                      />
                    </g>
                    <defs>
                      <filter
                        id='filter0_b_985_6256'
                        x='3.95'
                        y='0.2'
                        width='14.6'
                        height='17.1'
                        filterUnits='userSpaceOnUse'
                        color-interpolation-filters='sRGB'
                      >
                        <feFlood
                          flood-opacity='0'
                          result='BackgroundImageFix'
                        />
                        <feGaussianBlur
                          in='BackgroundImageFix'
                          stdDeviation='1.15'
                        />
                        <feComposite
                          in2='SourceAlpha'
                          operator='in'
                          result='effect1_backgroundBlur_985_6256'
                        />
                        <feBlend
                          mode='normal'
                          in='SourceGraphic'
                          in2='effect1_backgroundBlur_985_6256'
                          result='shape'
                        />
                      </filter>
                      <linearGradient
                        id='paint0_linear_985_6256'
                        x1='15.8333'
                        y1='3.75'
                        x2='8.82948'
                        y2='18.1169'
                        gradientUnits='userSpaceOnUse'
                      >
                        <stop stop-color='#F6FF8E' />
                        <stop offset='1' stop-color='#F26B41' />
                      </linearGradient>
                    </defs>
                  </svg>
                }
              />
            </Table.Col>
            <Table.Col center>
              <Button sm filledGrey>
                Boost
              </Button>
            </Table.Col>
          </Table.Row>
          <Table.Row>
            {!isTablet && <Table.Col>1</Table.Col>}
            <Table.Col>
              <button className={style.favorite}>
                <svg
                  width='14'
                  height='13'
                  viewBox='0 0 14 13'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M6.26134 0.775945C6.53462 0.11891 7.46538 0.118909 7.73866 0.775943L8.92838 3.63637C9.04358 3.91336 9.30407 4.10261 9.6031 4.12659L12.6912 4.37416C13.4005 4.43102 13.6881 5.31623 13.1477 5.77916L10.7949 7.79457C10.5671 7.98974 10.4676 8.29596 10.5372 8.58776L11.256 11.6012C11.4211 12.2934 10.6681 12.8405 10.0608 12.4695L7.417 10.8547C7.16099 10.6983 6.83901 10.6983 6.583 10.8547L3.93918 12.4695C3.3319 12.8405 2.5789 12.2934 2.74401 11.6012L3.46282 8.58776C3.53243 8.29596 3.43293 7.98974 3.2051 7.79458L0.852314 5.77917C0.311884 5.31623 0.599504 4.43102 1.30883 4.37416L4.3969 4.12659C4.69593 4.10261 4.95642 3.91336 5.07162 3.63637L6.26134 0.775945Z'
                    fill='white'
                    fill-opacity='0.1'
                  />
                </svg>
              </button>
            </Table.Col>
            <Table.Col>
              <TokenInfo icon={img} name={'Memereum'} label={'Meme'} />
            </Table.Col>
            {!isTablet && (
              <>
                <Table.Col>
                  <div className={style.chains}>
                    <TypeDiv title={'Audit'} />
                    <TypeDiv title={'KYC  99/100'} />
                    <TypeDiv title={'Meme'} />
                  </div>
                </Table.Col>
                <Table.Col>
                  <Chain
                    name={'ETH'}
                    icon={
                      <svg
                        width='16'
                        height='16'
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z'
                          fill='white'
                          fill-opacity='0.1'
                        />
                        <path
                          d='M7.99902 3V6.69568L10.9978 8.09145L7.99902 3Z'
                          fill='white'
                          fill-opacity='0.5'
                        />
                        <path
                          d='M7.99919 3L5 8.09145L7.99919 6.69568V3Z'
                          fill='white'
                        />
                        <path
                          d='M7.99902 10.4851V12.9963L10.9998 8.67188L7.99902 10.4851Z'
                          fill='white'
                          fill-opacity='0.5'
                        />
                        <path
                          d='M7.99919 12.9963V10.4847L5 8.67188L7.99919 12.9963Z'
                          fill='white'
                        />
                        <path
                          d='M7.99902 9.90393L10.9978 8.09025L7.99902 6.69531V9.90393Z'
                          fill='white'
                          fill-opacity='0.1'
                        />
                        <path
                          d='M5 8.09025L7.99919 9.90393V6.69531L5 8.09025Z'
                          fill='white'
                          fill-opacity='0.5'
                        />
                      </svg>
                    }
                  />
                </Table.Col>
                <Table.Col center>4234</Table.Col>
                <Table.Col center>
                  <Chain
                    name={'120K'}
                    reverse
                    smallIcon
                    transparentIcon
                    icon={
                      <svg
                        width='12'
                        height='12'
                        viewBox='0 0 12 12'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M8.4138 0.291703C8.30641 0.252347 8.19552 0.211485 8.07999 0.175844C6.24482 -0.400641 5.22397 0.5433 4.95266 1.51726C4.58097 2.84793 5.21459 3.75965 6.85658 5.10106C8.8694 6.74457 9.70256 8.27092 9.11883 10.3648C8.94667 10.9845 8.6586 11.5402 8.26389 12C9.89063 11.2974 11.2144 9.84416 11.7516 7.91792C12.6195 4.81091 11.1423 1.56073 8.4138 0.291703Z'
                          fill='#FF9700'
                        />
                        <path
                          d='M5.56033 6.32975C3.5978 4.81937 2.57808 3.187 3.13756 1.18055C3.22146 0.882968 3.33273 0.606473 3.46938 0.351562C1.96443 1.0936 0.757025 2.49214 0.248304 4.31246C-0.282671 6.21289 0.0643994 8.16573 1.03686 9.68876C1.70789 10.559 2.35753 11.2126 3.67621 11.5983C5.46184 12.1212 6.90155 11.3948 7.30222 9.95693C7.67467 8.62585 7.16983 7.64085 5.56033 6.32975Z'
                          fill='#2B3D52'
                        />
                      </svg>
                    }
                  />
                </Table.Col>
                <Table.Col center>$0.00031</Table.Col>
                <Table.Col>$ 7.37M</Table.Col>
                <Table.Col center>
                  <div className={`${style.diff}`}>-</div>
                </Table.Col>
                <Table.Col center>500K</Table.Col>
                <Table.Col center>In 2 month</Table.Col>
              </>
            )}
            <Table.Col center>
              <Chain
                transparentIcon
                name='2,387'
                icon={
                  <svg
                    width='20'
                    height='20'
                    viewBox='0 0 20 20'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M14.9995 10.989C14.9676 8.52734 14.0196 6.82558 13.5049 6.08044C13.3725 5.88879 13.0898 5.91265 12.9873 6.1225C12.8296 6.44481 12.5766 6.85228 12.2321 7.06436C12.2321 7.06436 10.772 5.85628 10.7039 4.04639C10.6962 3.83982 10.4951 3.70022 10.3044 3.76674C9.53128 4.03669 7.89045 4.9435 7.49386 7.83261C7.16787 7.23021 6.39474 7.05422 5.89792 7.0047C5.69091 6.98397 5.5237 7.1804 5.57527 7.38726C5.95385 8.90512 5 9.81208 5 11.1164C5 13.9466 7.23092 16.2419 9.98568 16.25C12.7826 16.2582 15.0367 13.8602 14.9995 10.989Z'
                      fill='url(#paint0_linear_985_6256)'
                    />
                    <g filter='url(#filter0_b_985_6256)'>
                      <path
                        d='M16.2495 9.739C16.2176 7.27734 15.2696 5.57558 14.7549 4.83044C14.6225 4.63879 14.3398 4.66265 14.2373 4.8725C14.0796 5.19481 13.8266 5.60228 13.4821 5.81436C13.4821 5.81436 12.022 4.60628 11.9539 2.79639C11.9462 2.58982 11.7451 2.45022 11.5544 2.51674C10.7813 2.78669 9.14045 3.6935 8.74386 6.58261C8.41787 5.98021 7.64474 5.80422 7.14792 5.7547C6.94091 5.73397 6.7737 5.9304 6.82527 6.13726C7.20385 7.65512 6.25 8.56208 6.25 9.86637C6.25 12.6966 8.48092 14.9919 11.2357 15C14.0326 15.0082 16.2867 12.6102 16.2495 9.739Z'
                        fill='white'
                        fill-opacity='0.1'
                      />
                    </g>
                    <defs>
                      <filter
                        id='filter0_b_985_6256'
                        x='3.95'
                        y='0.2'
                        width='14.6'
                        height='17.1'
                        filterUnits='userSpaceOnUse'
                        color-interpolation-filters='sRGB'
                      >
                        <feFlood
                          flood-opacity='0'
                          result='BackgroundImageFix'
                        />
                        <feGaussianBlur
                          in='BackgroundImageFix'
                          stdDeviation='1.15'
                        />
                        <feComposite
                          in2='SourceAlpha'
                          operator='in'
                          result='effect1_backgroundBlur_985_6256'
                        />
                        <feBlend
                          mode='normal'
                          in='SourceGraphic'
                          in2='effect1_backgroundBlur_985_6256'
                          result='shape'
                        />
                      </filter>
                      <linearGradient
                        id='paint0_linear_985_6256'
                        x1='15.8333'
                        y1='3.75'
                        x2='8.82948'
                        y2='18.1169'
                        gradientUnits='userSpaceOnUse'
                      >
                        <stop stop-color='#F6FF8E' />
                        <stop offset='1' stop-color='#F26B41' />
                      </linearGradient>
                    </defs>
                  </svg>
                }
              />
            </Table.Col>
            <Table.Col center>
              <Button sm filledGrey>
                Boost
              </Button>
            </Table.Col>
          </Table.Row>
          <Table.Row>
            {!isTablet && <Table.Col>1</Table.Col>}
            <Table.Col>
              <button className={style.favorite}>
                <svg
                  width='14'
                  height='13'
                  viewBox='0 0 14 13'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M6.26134 0.775945C6.53462 0.11891 7.46538 0.118909 7.73866 0.775943L8.92838 3.63637C9.04358 3.91336 9.30407 4.10261 9.6031 4.12659L12.6912 4.37416C13.4005 4.43102 13.6881 5.31623 13.1477 5.77916L10.7949 7.79457C10.5671 7.98974 10.4676 8.29596 10.5372 8.58776L11.256 11.6012C11.4211 12.2934 10.6681 12.8405 10.0608 12.4695L7.417 10.8547C7.16099 10.6983 6.83901 10.6983 6.583 10.8547L3.93918 12.4695C3.3319 12.8405 2.5789 12.2934 2.74401 11.6012L3.46282 8.58776C3.53243 8.29596 3.43293 7.98974 3.2051 7.79458L0.852314 5.77917C0.311884 5.31623 0.599504 4.43102 1.30883 4.37416L4.3969 4.12659C4.69593 4.10261 4.95642 3.91336 5.07162 3.63637L6.26134 0.775945Z'
                    fill='white'
                    fill-opacity='0.1'
                  />
                </svg>
              </button>
            </Table.Col>
            <Table.Col>
              <TokenInfo icon={img} name={'Memereum'} label={'Meme'} />
            </Table.Col>
            {!isTablet && (
              <>
                <Table.Col>
                  <div className={style.chains}>
                    <TypeDiv title={'Audit'} />
                    <TypeDiv title={'KYC  99/100'} />
                    <TypeDiv title={'Meme'} />
                  </div>
                </Table.Col>
                <Table.Col>
                  <Chain
                    name={'ETH'}
                    icon={
                      <svg
                        width='16'
                        height='16'
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z'
                          fill='white'
                          fill-opacity='0.1'
                        />
                        <path
                          d='M7.99902 3V6.69568L10.9978 8.09145L7.99902 3Z'
                          fill='white'
                          fill-opacity='0.5'
                        />
                        <path
                          d='M7.99919 3L5 8.09145L7.99919 6.69568V3Z'
                          fill='white'
                        />
                        <path
                          d='M7.99902 10.4851V12.9963L10.9998 8.67188L7.99902 10.4851Z'
                          fill='white'
                          fill-opacity='0.5'
                        />
                        <path
                          d='M7.99919 12.9963V10.4847L5 8.67188L7.99919 12.9963Z'
                          fill='white'
                        />
                        <path
                          d='M7.99902 9.90393L10.9978 8.09025L7.99902 6.69531V9.90393Z'
                          fill='white'
                          fill-opacity='0.1'
                        />
                        <path
                          d='M5 8.09025L7.99919 9.90393V6.69531L5 8.09025Z'
                          fill='white'
                          fill-opacity='0.5'
                        />
                      </svg>
                    }
                  />
                </Table.Col>
                <Table.Col center>4234</Table.Col>
                <Table.Col center>
                  <Chain
                    name={'120K'}
                    reverse
                    smallIcon
                    transparentIcon
                    icon={
                      <svg
                        width='12'
                        height='12'
                        viewBox='0 0 12 12'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M8.4138 0.291703C8.30641 0.252347 8.19552 0.211485 8.07999 0.175844C6.24482 -0.400641 5.22397 0.5433 4.95266 1.51726C4.58097 2.84793 5.21459 3.75965 6.85658 5.10106C8.8694 6.74457 9.70256 8.27092 9.11883 10.3648C8.94667 10.9845 8.6586 11.5402 8.26389 12C9.89063 11.2974 11.2144 9.84416 11.7516 7.91792C12.6195 4.81091 11.1423 1.56073 8.4138 0.291703Z'
                          fill='#FF9700'
                        />
                        <path
                          d='M5.56033 6.32975C3.5978 4.81937 2.57808 3.187 3.13756 1.18055C3.22146 0.882968 3.33273 0.606473 3.46938 0.351562C1.96443 1.0936 0.757025 2.49214 0.248304 4.31246C-0.282671 6.21289 0.0643994 8.16573 1.03686 9.68876C1.70789 10.559 2.35753 11.2126 3.67621 11.5983C5.46184 12.1212 6.90155 11.3948 7.30222 9.95693C7.67467 8.62585 7.16983 7.64085 5.56033 6.32975Z'
                          fill='#2B3D52'
                        />
                      </svg>
                    }
                  />
                </Table.Col>
                <Table.Col center>$0.00031</Table.Col>
                <Table.Col>$ 7.37M</Table.Col>
                <Table.Col center>
                  <div className={`${style.diff}`}>-</div>
                </Table.Col>
                <Table.Col center>500K</Table.Col>
                <Table.Col center>In 2 month</Table.Col>
              </>
            )}
            <Table.Col center>
              <Chain
                transparentIcon
                name='2,387'
                icon={
                  <svg
                    width='20'
                    height='20'
                    viewBox='0 0 20 20'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M14.9995 10.989C14.9676 8.52734 14.0196 6.82558 13.5049 6.08044C13.3725 5.88879 13.0898 5.91265 12.9873 6.1225C12.8296 6.44481 12.5766 6.85228 12.2321 7.06436C12.2321 7.06436 10.772 5.85628 10.7039 4.04639C10.6962 3.83982 10.4951 3.70022 10.3044 3.76674C9.53128 4.03669 7.89045 4.9435 7.49386 7.83261C7.16787 7.23021 6.39474 7.05422 5.89792 7.0047C5.69091 6.98397 5.5237 7.1804 5.57527 7.38726C5.95385 8.90512 5 9.81208 5 11.1164C5 13.9466 7.23092 16.2419 9.98568 16.25C12.7826 16.2582 15.0367 13.8602 14.9995 10.989Z'
                      fill='url(#paint0_linear_985_6256)'
                    />
                    <g filter='url(#filter0_b_985_6256)'>
                      <path
                        d='M16.2495 9.739C16.2176 7.27734 15.2696 5.57558 14.7549 4.83044C14.6225 4.63879 14.3398 4.66265 14.2373 4.8725C14.0796 5.19481 13.8266 5.60228 13.4821 5.81436C13.4821 5.81436 12.022 4.60628 11.9539 2.79639C11.9462 2.58982 11.7451 2.45022 11.5544 2.51674C10.7813 2.78669 9.14045 3.6935 8.74386 6.58261C8.41787 5.98021 7.64474 5.80422 7.14792 5.7547C6.94091 5.73397 6.7737 5.9304 6.82527 6.13726C7.20385 7.65512 6.25 8.56208 6.25 9.86637C6.25 12.6966 8.48092 14.9919 11.2357 15C14.0326 15.0082 16.2867 12.6102 16.2495 9.739Z'
                        fill='white'
                        fill-opacity='0.1'
                      />
                    </g>
                    <defs>
                      <filter
                        id='filter0_b_985_6256'
                        x='3.95'
                        y='0.2'
                        width='14.6'
                        height='17.1'
                        filterUnits='userSpaceOnUse'
                        color-interpolation-filters='sRGB'
                      >
                        <feFlood
                          flood-opacity='0'
                          result='BackgroundImageFix'
                        />
                        <feGaussianBlur
                          in='BackgroundImageFix'
                          stdDeviation='1.15'
                        />
                        <feComposite
                          in2='SourceAlpha'
                          operator='in'
                          result='effect1_backgroundBlur_985_6256'
                        />
                        <feBlend
                          mode='normal'
                          in='SourceGraphic'
                          in2='effect1_backgroundBlur_985_6256'
                          result='shape'
                        />
                      </filter>
                      <linearGradient
                        id='paint0_linear_985_6256'
                        x1='15.8333'
                        y1='3.75'
                        x2='8.82948'
                        y2='18.1169'
                        gradientUnits='userSpaceOnUse'
                      >
                        <stop stop-color='#F6FF8E' />
                        <stop offset='1' stop-color='#F26B41' />
                      </linearGradient>
                    </defs>
                  </svg>
                }
              />
            </Table.Col>
            <Table.Col center>
              <Button sm filledGrey>
                Boost
              </Button>
            </Table.Col>
          </Table.Row>
          <Table.Row>
            {!isTablet && <Table.Col>1</Table.Col>}
            <Table.Col>
              <button className={style.favorite}>
                <svg
                  width='14'
                  height='13'
                  viewBox='0 0 14 13'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M6.26134 0.775945C6.53462 0.11891 7.46538 0.118909 7.73866 0.775943L8.92838 3.63637C9.04358 3.91336 9.30407 4.10261 9.6031 4.12659L12.6912 4.37416C13.4005 4.43102 13.6881 5.31623 13.1477 5.77916L10.7949 7.79457C10.5671 7.98974 10.4676 8.29596 10.5372 8.58776L11.256 11.6012C11.4211 12.2934 10.6681 12.8405 10.0608 12.4695L7.417 10.8547C7.16099 10.6983 6.83901 10.6983 6.583 10.8547L3.93918 12.4695C3.3319 12.8405 2.5789 12.2934 2.74401 11.6012L3.46282 8.58776C3.53243 8.29596 3.43293 7.98974 3.2051 7.79458L0.852314 5.77917C0.311884 5.31623 0.599504 4.43102 1.30883 4.37416L4.3969 4.12659C4.69593 4.10261 4.95642 3.91336 5.07162 3.63637L6.26134 0.775945Z'
                    fill='white'
                    fill-opacity='0.1'
                  />
                </svg>
              </button>
            </Table.Col>
            <Table.Col>
              <TokenInfo icon={img} name={'Memereum'} label={'Meme'} />
            </Table.Col>
            {!isTablet && (
              <>
                <Table.Col>
                  <div className={style.chains}>
                    <TypeDiv title={'Audit'} />
                    <TypeDiv title={'KYC  99/100'} />
                    <TypeDiv title={'Meme'} />
                  </div>
                </Table.Col>
                <Table.Col>
                  <Chain
                    name={'ETH'}
                    icon={
                      <svg
                        width='16'
                        height='16'
                        viewBox='0 0 16 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z'
                          fill='white'
                          fill-opacity='0.1'
                        />
                        <path
                          d='M7.99902 3V6.69568L10.9978 8.09145L7.99902 3Z'
                          fill='white'
                          fill-opacity='0.5'
                        />
                        <path
                          d='M7.99919 3L5 8.09145L7.99919 6.69568V3Z'
                          fill='white'
                        />
                        <path
                          d='M7.99902 10.4851V12.9963L10.9998 8.67188L7.99902 10.4851Z'
                          fill='white'
                          fill-opacity='0.5'
                        />
                        <path
                          d='M7.99919 12.9963V10.4847L5 8.67188L7.99919 12.9963Z'
                          fill='white'
                        />
                        <path
                          d='M7.99902 9.90393L10.9978 8.09025L7.99902 6.69531V9.90393Z'
                          fill='white'
                          fill-opacity='0.1'
                        />
                        <path
                          d='M5 8.09025L7.99919 9.90393V6.69531L5 8.09025Z'
                          fill='white'
                          fill-opacity='0.5'
                        />
                      </svg>
                    }
                  />
                </Table.Col>
                <Table.Col center>4234</Table.Col>
                <Table.Col center>
                  <Chain
                    name={'120K'}
                    reverse
                    smallIcon
                    transparentIcon
                    icon={
                      <svg
                        width='12'
                        height='12'
                        viewBox='0 0 12 12'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M8.4138 0.291703C8.30641 0.252347 8.19552 0.211485 8.07999 0.175844C6.24482 -0.400641 5.22397 0.5433 4.95266 1.51726C4.58097 2.84793 5.21459 3.75965 6.85658 5.10106C8.8694 6.74457 9.70256 8.27092 9.11883 10.3648C8.94667 10.9845 8.6586 11.5402 8.26389 12C9.89063 11.2974 11.2144 9.84416 11.7516 7.91792C12.6195 4.81091 11.1423 1.56073 8.4138 0.291703Z'
                          fill='#FF9700'
                        />
                        <path
                          d='M5.56033 6.32975C3.5978 4.81937 2.57808 3.187 3.13756 1.18055C3.22146 0.882968 3.33273 0.606473 3.46938 0.351562C1.96443 1.0936 0.757025 2.49214 0.248304 4.31246C-0.282671 6.21289 0.0643994 8.16573 1.03686 9.68876C1.70789 10.559 2.35753 11.2126 3.67621 11.5983C5.46184 12.1212 6.90155 11.3948 7.30222 9.95693C7.67467 8.62585 7.16983 7.64085 5.56033 6.32975Z'
                          fill='#2B3D52'
                        />
                      </svg>
                    }
                  />
                </Table.Col>
                <Table.Col center>$0.00031</Table.Col>
                <Table.Col>$ 7.37M</Table.Col>
                <Table.Col center>
                  <div className={`${style.diff}`}>-</div>
                </Table.Col>
                <Table.Col center>500K</Table.Col>
                <Table.Col center>In 2 month</Table.Col>
              </>
            )}
            <Table.Col center>
              <Chain
                transparentIcon
                name='2,387'
                icon={
                  <svg
                    width='20'
                    height='20'
                    viewBox='0 0 20 20'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M14.9995 10.989C14.9676 8.52734 14.0196 6.82558 13.5049 6.08044C13.3725 5.88879 13.0898 5.91265 12.9873 6.1225C12.8296 6.44481 12.5766 6.85228 12.2321 7.06436C12.2321 7.06436 10.772 5.85628 10.7039 4.04639C10.6962 3.83982 10.4951 3.70022 10.3044 3.76674C9.53128 4.03669 7.89045 4.9435 7.49386 7.83261C7.16787 7.23021 6.39474 7.05422 5.89792 7.0047C5.69091 6.98397 5.5237 7.1804 5.57527 7.38726C5.95385 8.90512 5 9.81208 5 11.1164C5 13.9466 7.23092 16.2419 9.98568 16.25C12.7826 16.2582 15.0367 13.8602 14.9995 10.989Z'
                      fill='url(#paint0_linear_985_6256)'
                    />
                    <g filter='url(#filter0_b_985_6256)'>
                      <path
                        d='M16.2495 9.739C16.2176 7.27734 15.2696 5.57558 14.7549 4.83044C14.6225 4.63879 14.3398 4.66265 14.2373 4.8725C14.0796 5.19481 13.8266 5.60228 13.4821 5.81436C13.4821 5.81436 12.022 4.60628 11.9539 2.79639C11.9462 2.58982 11.7451 2.45022 11.5544 2.51674C10.7813 2.78669 9.14045 3.6935 8.74386 6.58261C8.41787 5.98021 7.64474 5.80422 7.14792 5.7547C6.94091 5.73397 6.7737 5.9304 6.82527 6.13726C7.20385 7.65512 6.25 8.56208 6.25 9.86637C6.25 12.6966 8.48092 14.9919 11.2357 15C14.0326 15.0082 16.2867 12.6102 16.2495 9.739Z'
                        fill='white'
                        fill-opacity='0.1'
                      />
                    </g>
                    <defs>
                      <filter
                        id='filter0_b_985_6256'
                        x='3.95'
                        y='0.2'
                        width='14.6'
                        height='17.1'
                        filterUnits='userSpaceOnUse'
                        color-interpolation-filters='sRGB'
                      >
                        <feFlood
                          flood-opacity='0'
                          result='BackgroundImageFix'
                        />
                        <feGaussianBlur
                          in='BackgroundImageFix'
                          stdDeviation='1.15'
                        />
                        <feComposite
                          in2='SourceAlpha'
                          operator='in'
                          result='effect1_backgroundBlur_985_6256'
                        />
                        <feBlend
                          mode='normal'
                          in='SourceGraphic'
                          in2='effect1_backgroundBlur_985_6256'
                          result='shape'
                        />
                      </filter>
                      <linearGradient
                        id='paint0_linear_985_6256'
                        x1='15.8333'
                        y1='3.75'
                        x2='8.82948'
                        y2='18.1169'
                        gradientUnits='userSpaceOnUse'
                      >
                        <stop stop-color='#F6FF8E' />
                        <stop offset='1' stop-color='#F26B41' />
                      </linearGradient>
                    </defs>
                  </svg>
                }
              />
            </Table.Col>
            <Table.Col center>
              <Button sm filledGrey>
                Boost
              </Button>
            </Table.Col>
          </Table.Row>
        </Table.Body>
      </Table.Container>
      <Pagination className={style.promoted__pagination} />
    </div>
  );
};

const TypeDiv = ({ title }) => {
  const [tagColor, setTagColor] = useState('');

  useEffect(() => {
    switch (title) {
      case 'Airdrop Live':
        setTagColor('green');
        break;
      case 'KYC  99/100':
        setTagColor('yellow');
        break;
      case 'Airdrop Ended':
        setTagColor('red');
        break;
      case 'Audit':
        setTagColor('blue');
        break;
      case 'Meme':
        setTagColor('purple');
        break;

      default:
        break;
    }
  }, []);

  return (
    <div
      className={`${style.type}
    ${tagColor === 'purple' && style.purple}
          ${tagColor === 'yellow' && style.yellow}
          ${tagColor === 'blue' && style.blue}
          ${tagColor === 'red' && style.red}
          ${tagColor === 'green' && style.green}
  `}
    >
      {title}
    </div>
  );
};

const Chain = ({ name, icon, reverse, smallIcon, transparentIcon }) => {
  return (
    <div
      className={`${style.chain} 
    ${reverse && style.reverse}
    ${smallIcon && style.smallIcon}
    ${transparentIcon && style.transparentIcon}
    `}
    >
      <p dangerouslySetInnerHTML={{ __html: name }}></p>
      <span className={style.chain__icon}>{icon}</span>
    </div>
  );
};

export default PromotedTableSection;
