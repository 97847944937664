import { Outlet } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import AskModal from './components/modals/Ask/Ask';
import BannerModal from './components/modals/Banner/Banner';
import PromotedSpotModal from './components/modals/PromotedSpot/PromotedSpot';
import CircleModal from './components/modals/Circle/Circle';
import EmailModal from './components/modals/Email/Email';
import RegistrationModal from './components/modals/Registration/Registration';
import RecoveryModal from './components/modals/Recovery/Recovery';
import VerifyModal from './components/modals/Verify/Verify';
import ShareModal from './components/modals/Share/Share';
import { useEffect, useState } from 'react';

function App() {
  const [pagePadding, setPagePadding] = useState(0);

  useEffect(() => {
    const header = document.querySelector('#header');
    setPagePadding(header.scrollHeight);
  }, [window]);

  return (
    <div className='wrapper'>
      <Header />

      {/* modals */}
      {/* <AskModal /> */}
      {/* <BannerModal /> */}
      {/* <PromotedSpotModal /> */}
      {/* <CircleModal /> */}
      {/* <EmailModal /> */}
      {/* <RegistrationModal /> */}
      {/* <RecoveryModal /> */}
      {/* <VerifyModal /> */}
      {/* <ShareModal /> */}

      <div style={{ padding: `${pagePadding}px 0 0` }} className='page'>
        <Outlet />
      </div>

      <Footer />
    </div>
  );
}

export default App;
