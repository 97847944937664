import Button from '../../Button/Button';
import Input from '../../Input/Input';
import ModalWrapper from '../../ModalWrapper/ModalWrapper';
import Select from '../../Select/Select';
import Text from '../../Text/Text';
import Title from '../../Title/Title';
import UploadImg from '../../UploadImg/UploadImg';
import style from './Email.module.scss';
import icon from './assets/img.svg';
import stars from './assets/stars.svg';
import projectIcon from './assets/project-img.jpg';

const EmailModal = ({ close }) => {
  return (
    <ModalWrapper closeModal={close} withCalendar>
      <div className={style.modal}>
        <div className={style.modal__icon}>
          <img src={icon} alt='' />

          {/* <div className={style.modal__iconStars}>
            <img src={stars} alt='' />
          </div> */}
        </div>
        <Title.H2 className={style.modal__title}>Promoted Circle</Title.H2>
        <Text grey center>
          Your coin will appear in <br /> Promoted section on main page
        </Text>
        <Input big border placeholder={'Add document'} fileUpload />
        <Button className={style.modal__add} filled>
          Add to Cart
        </Button>
      </div>
    </ModalWrapper>
  );
};

export default EmailModal;
