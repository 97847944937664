import style from './List.module.scss';

const List = ({ children, bigSpace }) => {
  return (
    <ul className={`${style.list} ${bigSpace && style.bigSpace}`}>
      {children}
    </ul>
  );
};

export default List;
