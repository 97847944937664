import { useMediaQuery } from 'usehooks-ts';
import Button from '../../../../components/Button/Button';
import Text from '../../../../components/Text/Text';
import Title from '../../../../components/Title/Title';
import style from './Top.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link, useLocation } from 'react-router-dom';

const BuyBoostsTop = () => {
  const isMobile = useMediaQuery('(max-width:991px)');
  const location = useLocation();

  return (
    <div className={style.top}>
      <div className={style.topContent}>
        <Title.H1>Boosts</Title.H1>
        {isMobile && (
          <div className={style.topBody__read}>
            <Text grey>What is Boost?</Text>
            <a href=''>
              <Text>Read now</Text>
            </a>
          </div>
        )}
      </div>
      <div className={style.topBody}>
        <Swiper
          className={style.topBody__btns}
          spaceBetween={8}
          slidesPerView={'auto'}
        >
          <SwiperSlide>
            <Link to={'buy'}>
              <Button
                filled={location.pathname.split('/').reverse()[0] === 'buy'}
              >
                Buy Boost
              </Button>
            </Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link to={'history'}>
              <Button
                filled={location.pathname.split('/').reverse()[0] === 'history'}
              >
                Payments History
              </Button>
            </Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link to={'boosted'}>
              <Button
                filled={location.pathname.split('/').reverse()[0] === 'boosted'}
              >
                Boosted projects
              </Button>
            </Link>
          </SwiperSlide>
        </Swiper>

        {!isMobile && (
          <div className={style.topBody__read}>
            <Text grey>What is Boost?</Text>
            <a href=''>
              <Text>Read now</Text>
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default BuyBoostsTop;
