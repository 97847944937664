import { useRef, useState } from 'react';
import Button from '../../../../components/Button/Button';
import SubmitCoinCard from '../../modules/Card/Card';
import style from './Category.module.scss';

const CategorySection = ({ handleSuccess }) => {
  return (
    <SubmitCoinCard
      className={style.category}
      label={'Choose 1 or more categories'}
      title={'Category'}
    >
      <div className={style.categoryBody}>
        <CategoryItem>DeFi</CategoryItem>
        <CategoryItem>Artificial Intelligence</CategoryItem>
        <CategoryItem>Play To Earn</CategoryItem>
        <CategoryItem>Utility Token</CategoryItem>
        <CategoryItem>Gaming</CategoryItem>
        <CategoryItem>Fan Token</CategoryItem>
        <CategoryItem>Gambing</CategoryItem>
        <CategoryItem>Meme</CategoryItem>
        <CategoryItem>NFT</CategoryItem>
        <CategoryItem>Other</CategoryItem>
      </div>
    </SubmitCoinCard>
  );
};

const CategoryItem = ({ children }) => {
  const inputRef = useRef(null);
  const [checked, setChecked] = useState(false);

  const handleChecked = () => {
    setChecked(inputRef.current.checked);
  };

  return (
    <label className={style.categoryItem__wrapper}>
      <input onChange={handleChecked} ref={inputRef} type='checkbox' />
      <Button isDiv filled={checked} className={style.categoryBody__item} sm>
        {children}
      </Button>
    </label>
  );
};

export default CategorySection;
