import Button from '../Button/Button';
import Title from '../Title/Title';
import Block from './components/Block/Block';
import style from './Menu.module.scss';

// import icons
import profileIcon from '../../assets/btn_icons/profile.svg';
import boostIcon from '../../assets/btn_icons/boost.svg';
import plusIcon from '../../assets/btn_icons/plus.svg';
import starGoldIcon from '../../assets/btn_icons/star-gold.svg';
import presaleRedIcon from '../../assets/btn_icons/presale-red.svg';
import presaleIcon from '../../assets/btn_icons/presale.svg';
import crownIcon from '../../assets/btn_icons/crown.svg';
import crownRedIcon from '../../assets/btn_icons/crown-red.svg';
import shibaInuIcon from '../../assets/btn_icons/shiba-inu.svg';
import cloudIcon from '../../assets/btn_icons/cloud.svg';
import mailIcon from '../../assets/btn_icons/mail.svg';
import blogIcon from '../../assets/btn_icons/blog.svg';
import bookIcon from '../../assets/btn_icons/book.svg';
import bookRedIcon from '../../assets/btn_icons/book-red.svg';
import { useMediaQuery } from 'usehooks-ts';

const Menu = ({ isOpen, closeMenu }) => {
  const isMobile = useMediaQuery('(max-width:991px)');

  return (
    <div className={`${style.menu} ${isOpen && style.open}`}>
      <div className={style.menu__bg} onClick={closeMenu}></div>
      <div className={`__container ${style.con}`}>
        <div className={style.menuBody}>
          {!isMobile && (
            <div className={style.menuTop}>
              <Title.H2 className={style.menuTop__title}>Menu</Title.H2>
              <Button
                onClick={closeMenu}
                onlyIcon
                className={style.menuTop__close}
              >
                <svg
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M12 4L4 12M4 4L12 12'
                    stroke='white'
                    stroke-opacity='0.5'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              </Button>
            </div>
          )}
          {isMobile && (
            <div className={style.menuBtns}>
              <Button filled className={style.menuBtns__item}>
                Submit Coin
                <svg
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M8.00001 3.3335V12.6668M3.33334 8.00016H12.6667'
                    stroke='#010106'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              </Button>
              <Button noBorder className={style.menuBtns__item}>
                Login
              </Button>
              <Button className={style.menuBtns__item}>Create Account</Button>
            </div>
          )}
          <Block title={'Account'}>
            <Button icon={profileIcon}>Profile</Button>
            <Button icon={boostIcon}>Boosts</Button>
            <Button icon={plusIcon}>Add coin</Button>
            <Button icon={starGoldIcon}>Watchlist</Button>
          </Block>
          <Block title={'Cryptocurrencies'}>
            <Button icon={presaleRedIcon}>New Presales</Button>
            <Button icon={crownRedIcon}>New Coins</Button>
            <Button icon={presaleIcon}>Popular Presales</Button>
            <Button icon={crownIcon}>Popular Coins</Button>
            <Button icon={shibaInuIcon}>Top Memes</Button>
            <Button icon={cloudIcon}>Airdrops</Button>
          </Block>
          <Block title={'Other'} isAccordion>
            <Button icon={mailIcon}>Contact Us</Button>
            <Button icon={blogIcon}>Blog</Button>
            <Button icon={bookIcon}>Dictionary</Button>
            <Button icon={bookRedIcon}>Crypto Guides</Button>
          </Block>
        </div>
      </div>
    </div>
  );
};

export default Menu;
