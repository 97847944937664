import { Outlet } from 'react-router-dom';
import style from './index.module.scss';
import AdvertiseTop from './modules/Top/Top';

const AdvertisePage = () => {
  return (
    <div className={style.advertise}>
      <div className='__container'>
        <AdvertiseTop />
        <div className={style.advertiseContent}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AdvertisePage;
