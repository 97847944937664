import Banner from './components/Banner/Banner';
import style from './index.module.scss';
import ProjectInfo from './modules/Info/Info';
import ProjectTop from './modules/Top/Top';
import img from './assets/img.jpg';
import banner from '../Main/assets/img.jpg';
import Banners from '../Main/components/Banners/Banners';
import MarketDetails from './modules/MarketDetails/MarketDetails';
import CommunitySection from './modules/Community/Community';
import Transactions from './modules/Transactions/Transactions';
import PresaleDetails from './modules/PresaleDetails/PresaleDetails';

const ProjectPage = () => {
  return (
    <div>
      <ProjectTop />
      <Banner img={img} />
      <ProjectInfo />
      <div className={`__container ${style.banner}`}>
        <Banners.Container withContacts>
          <Banners.Card img={banner} />
        </Banners.Container>
      </div>
      <PresaleDetails />
      <div className={`__container ${style.banner}`}>
        <Banners.Container withContacts>
          <Banners.Card img={banner} />
        </Banners.Container>
      </div>
      <MarketDetails />
      <Transactions />
      <CommunitySection />
    </div>
  );
};

export default ProjectPage;
