import Text from '../../../../components/Text/Text';
import Title from '../../../../components/Title/Title';
import style from './Personal.module.scss';
import visitorsIcon from '../../../../assets/btn_icons/visitors.svg';
import votesIcon from '../../../../assets/btn_icons/votes.svg';
import boostIcon from '../../../../assets/btn_icons/boost.svg';
import starIcon from '../../../../assets/btn_icons/star-yellow.svg';
import dotPurpleIcon from '../../../../assets/btn_icons/dot-purple.svg';
import { useMediaQuery } from 'usehooks-ts';
import { Swiper, SwiperSlide } from 'swiper/react';
import BarGraph from './modules/BarGraph/BarGraph';
import LineGraph from './modules/LineGraph/LineGraph';

const cardList = [
  {
    color: 'blue',
    icon: visitorsIcon,
    value: '8500',
    label: 'Total visitors',
  },
  {
    color: 'purple',
    icon: votesIcon,
    value: '5000',
    label: 'Total Votes',
  },
  {
    color: 'orange',
    icon: boostIcon,
    value: '350',
    label: 'Total Boosts',
  },
  {
    color: 'yellow',
    icon: starIcon,
    value: '32',
    label: 'Added to watchlists',
  },
  {
    color: 'purple-2',
    icon: dotPurpleIcon,
    value: '4',
    label: 'Your projects',
  },
];

const PersonalSection = () => {
  const isTablet = useMediaQuery('(max-width:991px)');

  return (
    <div className={style.personal}>
      <Title.H1 className={style.personal__title}>Personal Statistic</Title.H1>
      {!isTablet && (
        <div className={style.personalBody}>
          {cardList.map((item, index) => (
            <StatisticCard
              key={index}
              color={item.color}
              icon={item.icon}
              value={item.value}
              label={item.label}
            />
          ))}
        </div>
      )}
      {isTablet && (
        <Swiper
          spaceBetween={16}
          slidesPerView={'auto'}
          className={style.personalSlider}
        >
          {cardList.map((item, index) => (
            <SwiperSlide className={style.personalSlider__item}>
              <StatisticCard
                key={index}
                color={item.color}
                icon={item.icon}
                value={item.value}
                label={item.label}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
      <div className={style.personalGraphs}>
        <BarGraph className={style.personalGraphs__item} />
        <LineGraph className={style.personalGraphs__item} />
      </div>
    </div>
  );
};

const StatisticCard = ({ color, icon, value, label }) => {
  return (
    <div className={`${style.card}`}>
      <div
        className={`${style.card__icon} 
        ${color === 'blue' && style._blue}
    ${color === 'purple' && style._purple}
    ${color === 'orange' && style._orange}
    ${color === 'yellow' && style._yellow}
    ${color === 'purple-2' && style._purple2}`}
      >
        <img src={icon} alt='' />
      </div>
      <Title.H1 className={style.card__value}>{value}</Title.H1>
      <Text grey className={style.card__label}>
        {label}
      </Text>
    </div>
  );
};

export default PersonalSection;
