import BlogCard from '../../../../components/BlogCard/BlogCard';
import Title from '../../../../components/Title/Title';
import style from './Popular.module.scss';
import img from '../Content/assets/img.jpg';
import { Swiper, SwiperSlide } from 'swiper/react';
import Button from '../../../../components/Button/Button';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'usehooks-ts';

import 'swiper/css';

const PopularSection = () => {
  const sliderRef = useRef(null);
  const [disablePrevSliderBtn, setDisablePrevSliderBtn] = useState(true);
  const [disableNextSliderBtn, setDisableNextSliderBtn] = useState(false);
  const isTablet = useMediaQuery('(max-width:991px)');
  const isMobile = useMediaQuery('(max-width:767px)');
  const [visibleSlides, setVisibleSlides] = useState(4);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  useEffect(() => {
    isTablet && setVisibleSlides(3);
    isMobile && setVisibleSlides(1);
  }, [isTablet, isMobile]);

  return (
    <div className={style.popular}>
      <div className={style.popularTop}>
        <Title.H1 className={style.popular__title}>Popular</Title.H1>
        <div className={style.popularTop__nav}>
          <Button disabled={disablePrevSliderBtn} onClick={handlePrev} onlyIcon>
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M10 12L6 8L10 4'
                stroke='white'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
            </svg>
          </Button>
          <Button disabled={disableNextSliderBtn} onClick={handleNext} onlyIcon>
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M6 12L10 8L6 4'
                stroke='white'
                stroke-opacity='0.5'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
            </svg>
          </Button>
        </div>
      </div>
      <Swiper
        ref={sliderRef}
        className={style.popularCards}
        slidesPerView={visibleSlides}
        spaceBetween={16}
        onSlideChange={(swiper) => {
          // console.log(swiper.activeIndex, swiper.slides.length - 3);
          if (swiper.activeIndex === 0) {
            setDisablePrevSliderBtn(true);
          } else {
            setDisablePrevSliderBtn(false);
          }
          if (swiper.activeIndex === swiper.slides.length - visibleSlides) {
            setDisableNextSliderBtn(true);
          } else {
            setDisableNextSliderBtn(false);
          }
        }}
      >
        <SwiperSlide>
          <BlogCard
            className={style.popularCards__item}
            img={img}
            tag={'Business'}
            title={
              'Unleash Your Earnings: Mastering the Strategy of Hamster Kombat!'
            }
            text={
              'Hamster Kombat is not just a game but a platform for passive earnings. The main rule of the game is to invest earned coins in purchas...'
            }
            info={{
              date: '01.06.2024',
              time: '2 min',
            }}
          />
        </SwiperSlide>
        <SwiperSlide>
          <BlogCard
            className={style.popularCards__item}
            img={img}
            tag={'Business'}
            title={
              'Unleash Your Earnings: Mastering the Strategy of Hamster Kombat!'
            }
            text={
              'Hamster Kombat is not just a game but a platform for passive earnings. The main rule of the game is to invest earned coins in purchas...'
            }
            info={{
              date: '01.06.2024',
              time: '2 min',
            }}
          />
        </SwiperSlide>
        <SwiperSlide>
          <BlogCard
            className={style.popularCards__item}
            img={img}
            tag={'Business'}
            title={
              'Unleash Your Earnings: Mastering the Strategy of Hamster Kombat!'
            }
            text={
              'Hamster Kombat is not just a game but a platform for passive earnings. The main rule of the game is to invest earned coins in purchas...'
            }
            info={{
              date: '01.06.2024',
              time: '2 min',
            }}
          />
        </SwiperSlide>
        <SwiperSlide>
          <BlogCard
            className={style.popularCards__item}
            img={img}
            tag={'Business'}
            title={
              'Unleash Your Earnings: Mastering the Strategy of Hamster Kombat!'
            }
            text={
              'Hamster Kombat is not just a game but a platform for passive earnings. The main rule of the game is to invest earned coins in purchas...'
            }
            info={{
              date: '01.06.2024',
              time: '2 min',
            }}
          />
        </SwiperSlide>
        <SwiperSlide>
          <BlogCard
            className={style.popularCards__item}
            img={img}
            tag={'Business'}
            title={
              'Unleash Your Earnings: Mastering the Strategy of Hamster Kombat!'
            }
            text={
              'Hamster Kombat is not just a game but a platform for passive earnings. The main rule of the game is to invest earned coins in purchas...'
            }
            info={{
              date: '01.06.2024',
              time: '2 min',
            }}
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default PopularSection;
