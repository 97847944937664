import { useMediaQuery } from 'usehooks-ts';
import style from './Panels.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';

import 'swiper/css';
import './slider.scss';

const Panels = ({ children }) => {
  const isTablet = useMediaQuery('(max-width:991px)');

  console.log(children);

  return (
    <div className={style.panels}>
      {!isTablet && children}
      {isTablet && (
        <>
          <Swiper
            modules={[Pagination]}
            pagination={{ el: `.${style.pagination}` }}
            className={style.panels__slider}
            spaceBetween={16}
          >
            {children.map((item, index) => (
              <SwiperSlide key={index}>{item}</SwiperSlide>
            ))}
          </Swiper>
          <div className={`${style.pagination} panels-slider-pagination`}></div>
        </>
      )}
    </div>
  );
};

export default Panels;
