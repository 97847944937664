import Text from '../../../../components/Text/Text';
import style from './PromotedCard.module.scss';

const PromotedCard = ({ img, name }) => {
  return (
    <div className={style.card}>
      <div className={style.card__img}>
        <img src={img} alt='' />
      </div>
      <Text grey className={style.card__name}>
        {name}
      </Text>
    </div>
  );
};

export default PromotedCard;
