import Button from '../../components/Button/Button';
import Select from '../../components/Select/Select';
import Table from '../../components/Table/Table';
import Title from '../../components/Title/Title';
import TokenInfo from '../../components/TokenInfo/TokenInfo';
import {
  Chain,
  TypeDiv,
} from '../Main/sections/BestTableSection/BestTableSection';
import style from './index.module.scss';
import { useMediaQuery } from 'usehooks-ts';
import { useState } from 'react';
import Text from '../../components/Text/Text';
import img from '../../components/Search/modules/Slider/assets/logo.jpg';
import ethIcon from '../../assets/icons/chains/eth.svg';
import bscIcon from '../../assets/icons/chains/bsc.svg';

const TopNewTokensPage = () => {
  const isTablet = useMediaQuery('(max-width:991px)');
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const handleFilter = () => {
    if (isFilterOpen) {
      setIsFilterOpen(false);
    } else {
      setIsFilterOpen(true);
    }
  };

  return (
    <div className='__container'>
      <div className={style.promoted}>
        <div className={style.promotedTop}>
          <Title.H1>Top New Tokens</Title.H1>
          {isTablet && (
            <Button
              onClick={handleFilter}
              onlyIcon
              className={style.promotedTop__filter}
            >
              {isFilterOpen && (
                <svg
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M12 4L4 12M4 4L12 12'
                    stroke='white'
                    stroke-opacity='0.5'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              )}
              {!isFilterOpen && (
                <svg
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M4 8H12M2 4H14M6 12H10'
                    stroke='white'
                    stroke-opacity='0.5'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              )}
            </Button>
          )}
          {(isFilterOpen || !isTablet) && (
            <div className={style.promotedTop__btns}>
              <Select
                className={style.promotedTop__btnsItem}
                border
                options={[
                  {
                    name: 'Popular',
                    value: '',
                  },
                  {
                    name: 'Old',
                    value: 'old',
                  },
                  {
                    name: 'Last Week',
                    value: 'week',
                  },
                  {
                    name: 'Last Month',
                    value: 'month',
                  },
                  {
                    name: 'Last Year',
                    value: 'year',
                  },
                ]}
              />
              <div className={style.promotedTop__btnsListings}>
                <Text grey>Listings</Text>
                <div className={style.list}>
                  <label>
                    <input type='radio' name='best-table-listing-radio' />
                    <Button
                      isDiv
                      className={style.list__btn}
                      sm
                      filledGrey
                      onlyIcon
                    >
                      <svg
                        width='12'
                        height='12'
                        viewBox='0 0 12 12'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M10.4374 7.17442C10.2249 7.30233 9.97693 7.32558 9.78802 7.22093C9.55188 7.0814 9.4102 6.76744 9.4102 6.33721V5.02326C9.4102 4.39535 9.16225 3.94186 8.7372 3.82558C8.01697 3.61628 7.47384 4.48837 7.27313 4.81395L5.99797 6.82558V4.34884C5.98616 3.77907 5.79725 3.44186 5.44304 3.33721C5.2069 3.26744 4.85269 3.30233 4.51028 3.81396L1.66479 8.30232C1.28697 7.59302 1.08625 6.80233 1.08625 6C1.08625 3.2907 3.29416 1.09302 5.99797 1.09302C8.70177 1.09302 10.9097 3.2907 10.9097 6V6.01163V6.02326C10.9333 6.54651 10.768 6.96512 10.4374 7.17442ZM11.9959 6V5.98837V5.97674C11.9841 2.68605 9.29213 0 5.99797 0C2.692 0 0 2.68605 0 6C0 9.30233 2.692 12 5.99797 12C7.52107 12 8.96153 11.4302 10.0714 10.3953C10.2957 10.186 10.3075 9.84884 10.095 9.62791C9.89428 9.40698 9.55188 9.39535 9.32755 9.59302C9.32755 9.59302 9.32755 9.59302 9.31574 9.60465C8.41841 10.4419 7.21409 10.9186 5.97435 10.9186C4.52209 10.9186 3.22332 10.2907 2.32598 9.2907L4.88811 5.24419V7.11628C4.88811 8.01163 5.24232 8.30233 5.53749 8.38372C5.83267 8.46512 6.28133 8.40698 6.76542 7.65116L8.17046 5.40698C8.21769 5.33721 8.25311 5.26744 8.30034 5.22093V6.36047C8.30034 7.19768 8.64274 7.87209 9.23309 8.19767C9.76441 8.5 10.4374 8.46511 10.9923 8.12791C11.6771 7.68605 12.0432 6.9186 11.9959 6Z'
                          fill='white'
                        />
                      </svg>
                    </Button>
                  </label>
                  <label>
                    <input type='radio' name='best-table-listing-radio' />
                    <Button
                      isDiv
                      className={style.list__btn}
                      sm
                      filledGrey
                      onlyIcon
                    >
                      <svg
                        width='14'
                        height='14'
                        viewBox='0 0 14 14'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M14 6.9701C14.0088 9.80225 12.3226 12.3609 9.70949 13.4549C7.09637 14.549 4.08597 13.9667 2.07316 11.9727C0.0603442 9.97871 -0.548797 6.9701 0.519407 4.34971C1.58761 1.72931 4.13894 0.0176645 6.96394 1.86993e-05C10.8395 -0.00880419 13.9823 3.10568 14 6.9701Z'
                          fill='#8DC63F'
                        />
                        <path
                          d='M13.4791 6.97103C13.4879 9.59143 11.9253 11.956 9.5064 12.9706C7.08749 13.9852 4.30663 13.4382 2.44389 11.603C0.581157 9.75906 0.0161563 6.97985 1.00491 4.56238C1.99366 2.13609 4.35077 0.556789 6.9639 0.539143C10.5481 0.521497 13.4614 3.39776 13.4791 6.97103Z'
                          fill='#F9E988'
                        />
                        <path
                          d='M7.12305 0.955281C7.53797 0.884698 7.95289 0.884698 8.36781 0.955281C8.78274 1.01704 9.18883 1.15821 9.55961 1.35231C9.93039 1.55524 10.2482 1.82875 10.566 2.08461C10.8838 2.34048 11.2016 2.60516 11.5018 2.89632C11.8108 3.17865 12.0756 3.5051 12.2963 3.85801C12.5259 4.21093 12.7113 4.59031 12.8525 4.97852C13.1262 5.77258 13.2145 6.6284 13.082 7.44011H13.0379C12.9055 6.6284 12.7201 5.86081 12.4288 5.12851C12.2963 4.75795 12.1286 4.39621 11.952 4.05211C11.7578 3.70802 11.5548 3.36393 11.3252 3.04631C11.0957 2.71986 10.8044 2.42871 10.4777 2.19049C10.1511 1.95227 9.77149 1.80228 9.40953 1.66111C9.04758 1.51995 8.6768 1.36996 8.29719 1.25526C7.91758 1.14939 7.52914 1.06116 7.12305 0.98175V0.955281Z'
                          fill='white'
                        />
                        <path
                          d='M10.2834 4.68572C9.81549 4.55337 9.32995 4.35927 8.83557 4.16517C8.80909 4.04165 8.69432 3.89166 8.47362 3.69755C8.15581 3.41522 7.54667 3.42405 7.02581 3.54757C6.45198 3.41522 5.87815 3.36228 5.3308 3.49463C0.85494 4.72983 3.38861 7.72961 1.74658 10.7559C1.97611 11.2499 4.50096 14.1439 8.14698 13.3674C8.14698 13.3674 6.90221 10.3765 9.71839 8.93835C12.0049 7.77373 13.6469 5.6033 10.2834 4.68572Z'
                          fill='#8BC53F'
                        />
                        <path
                          d='M7.02539 3.54762C7.35203 3.57409 8.535 3.95347 8.83516 4.16522C8.57914 3.43292 7.73164 3.33587 7.02539 3.54762Z'
                          fill='#009345'
                        />
                        <path
                          d='M10.8398 6.65023C10.8398 6.88845 10.5485 7.01197 10.3807 6.84434C10.213 6.6767 10.3278 6.38555 10.5661 6.37672C10.7162 6.38555 10.8398 6.50024 10.8398 6.65023ZM7.33503 5.40621C7.33503 5.90911 7.02604 6.3679 6.55815 6.562C6.09026 6.75611 5.55174 6.65023 5.18979 6.28849C4.82784 5.92676 4.7219 5.38856 4.91612 4.92095C5.11034 4.45333 5.5694 4.14453 6.0726 4.14453C6.40807 4.14453 6.72588 4.27688 6.95542 4.50627C7.2026 4.75331 7.33503 5.07094 7.33503 5.40621Z'
                          fill='white'
                        />
                        <path
                          d='M6.96405 5.41455C6.96405 5.76746 6.75218 6.09391 6.41671 6.22625C6.09007 6.3586 5.71046 6.28801 5.45444 6.03215C5.19842 5.77629 5.1278 5.40572 5.26022 5.07045C5.39264 4.74401 5.71928 4.52344 6.07241 4.52344C6.56678 4.53226 6.96405 4.92929 6.96405 5.41455Z'
                          fill='#58595B'
                        />
                        <path
                          d='M11.8551 7.20718C10.8399 7.92183 9.68339 8.46003 8.05018 8.46003C7.28213 8.46003 7.13206 7.64832 6.62002 8.04535C6.35518 8.24828 5.43705 8.70707 4.70432 8.6806C3.97158 8.64531 2.78861 8.21298 2.46197 6.66016C2.32955 8.22181 2.26775 9.36878 1.67627 10.6834C2.84158 12.5538 5.62245 13.992 8.14729 13.3744C7.87362 11.4775 9.53331 9.62465 10.4603 8.6806C10.8222 8.31004 11.502 7.72773 11.8551 7.20718Z'
                          fill='#8BC53F'
                        />
                        <path
                          d='M11.8105 7.25C11.4926 7.54116 11.1219 7.7529 10.7422 7.93818C10.3538 8.12346 9.95655 8.26463 9.54162 8.37933C9.1267 8.4852 8.69412 8.56461 8.26154 8.52932C7.82014 8.49403 7.37873 8.34404 7.0874 8.01759L7.10506 7.99995C7.45818 8.22934 7.87311 8.30875 8.2792 8.32639C8.69412 8.33521 9.10904 8.30875 9.51514 8.22934C9.92123 8.14111 10.3273 8.01759 10.7158 7.85878C11.1042 7.69997 11.4838 7.51469 11.8193 7.25H11.8105Z'
                          fill='#58595B'
                        />
                      </svg>
                    </Button>
                  </label>
                </div>
              </div>
              <Select
                className={style.promotedTop__btnsItem}
                border
                isCheckboxList
                options={[
                  {
                    name: 'Badges',
                    value: '',
                  },
                  {
                    name: 'KYC',
                    value: 'kyc',
                  },
                  {
                    name: 'Audit',
                    value: 'audit',
                  },
                ]}
              />
              <Select
                className={style.promotedTop__btnsItem}
                border
                isRange
                label='Market Cap:'
                options={[
                  {
                    name: 'All',
                    value: 'all',
                  },
                ]}
              />
              <Select
                className={style.promotedTop__btnsItem}
                border
                isCheckboxList
                options={[
                  {
                    name: 'Category',
                    value: '',
                  },
                  {
                    name: 'Gaming',
                    value: 'gaming',
                  },
                  {
                    name: 'Meme',
                    value: 'meme',
                  },
                  {
                    name: 'DeFi',
                    value: 'defi',
                  },
                  {
                    name: 'Fan Token',
                    value: 'fan_token',
                  },
                  {
                    name: 'Gambling',
                    value: 'gambling',
                  },
                  {
                    name: 'NFT',
                    value: 'nft',
                  },
                  {
                    name: 'Play to Earn',
                    value: 'play_to_earn',
                  },
                  {
                    name: 'Utility',
                    value: 'utility',
                  },
                  {
                    name: 'Other',
                    value: 'other',
                  },
                ]}
              />
              <Select
                className={style.promotedTop__btnsItem}
                border
                isCheckboxList
                options={[
                  {
                    name: 'Chains',
                    value: '',
                  },
                  {
                    name: (
                      <div className={style.selectItem__icon}>
                        <img src={ethIcon} alt='' />
                        ETH
                      </div>
                    ),
                    value: 'eth',
                  },
                  {
                    name: (
                      <div className={style.selectItem__icon}>
                        <img src={bscIcon} alt='' />
                        BSC
                      </div>
                    ),
                    value: 'bsc',
                  },
                ]}
              />
              {!isTablet && (
                <Button className={style.promotedTop__btnsBtn} noBorder>
                  Reset Filter
                </Button>
              )}
              {isTablet && (
                <div className={style.promotedTop__btnsRow}>
                  <Button className={style.btn} noBorder>
                    Reset Filter
                  </Button>
                  <Button className={style.btn} filled>
                    Apply
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
        <Table.Container className={style.promoted__table}>
          <Table.Head>
            <Table.Row>
              {!isTablet && <Table.Col>#</Table.Col>}
              <Table.Col></Table.Col>
              <Table.Col>Coin</Table.Col>
              {!isTablet && (
                <>
                  <Table.Col>Badges</Table.Col>
                  <Table.Col center>Chains</Table.Col>
                  <Table.Col center>Transactions</Table.Col>
                  <Table.Col center>Site Visitors</Table.Col>
                  <Table.Col center>
                    <div className={style.head__row}>
                      Current Price
                      <svg
                        width='12'
                        height='12'
                        viewBox='0 0 12 12'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <g clip-path='url(#clip0_133_62278)'>
                          <path
                            d='M6 8V6M6 4H6.005M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z'
                            stroke='white'
                            stroke-opacity='0.5'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                          />
                        </g>
                        <defs>
                          <clipPath id='clip0_133_62278'>
                            <rect width='12' height='12' fill='white' />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </Table.Col>
                  <Table.Col>Market Cap</Table.Col>
                  <Table.Col center>Change 24h</Table.Col>
                  <Table.Col center>Raise amount</Table.Col>
                  <Table.Col center>Launch</Table.Col>
                </>
              )}
              <Table.Col></Table.Col>
              <Table.Col center>Total Boosts</Table.Col>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            <Table.Row>
              {!isTablet && <Table.Col>1</Table.Col>}
              <Table.Col>
                <button className={style.favorite}>
                  <svg
                    width='14'
                    height='13'
                    viewBox='0 0 14 13'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M6.26134 0.775945C6.53462 0.11891 7.46538 0.118909 7.73866 0.775943L8.92838 3.63637C9.04358 3.91336 9.30407 4.10261 9.6031 4.12659L12.6912 4.37416C13.4005 4.43102 13.6881 5.31623 13.1477 5.77916L10.7949 7.79457C10.5671 7.98974 10.4676 8.29596 10.5372 8.58776L11.256 11.6012C11.4211 12.2934 10.6681 12.8405 10.0608 12.4695L7.417 10.8547C7.16099 10.6983 6.83901 10.6983 6.583 10.8547L3.93918 12.4695C3.3319 12.8405 2.5789 12.2934 2.74401 11.6012L3.46282 8.58776C3.53243 8.29596 3.43293 7.98974 3.2051 7.79458L0.852314 5.77917C0.311884 5.31623 0.599504 4.43102 1.30883 4.37416L4.3969 4.12659C4.69593 4.10261 4.95642 3.91336 5.07162 3.63637L6.26134 0.775945Z'
                      fill='white'
                      fill-opacity='0.1'
                    />
                  </svg>
                </button>
              </Table.Col>
              <Table.Col>
                <TokenInfo icon={img} name={'Memereum'} label={'Meme'} />
              </Table.Col>
              {!isTablet && (
                <>
                  <Table.Col>
                    <div className={style.chains}>
                      <TypeDiv title={'Audit'} />
                      <TypeDiv title={'KYC  99/100'} />
                      <TypeDiv title={'Meme'} />
                    </div>
                  </Table.Col>
                  <Table.Col>
                    <Chain
                      name={'ETH'}
                      icon={
                        <svg
                          width='16'
                          height='16'
                          viewBox='0 0 16 16'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z'
                            fill='white'
                            fill-opacity='0.1'
                          />
                          <path
                            d='M7.99902 3V6.69568L10.9978 8.09145L7.99902 3Z'
                            fill='white'
                            fill-opacity='0.5'
                          />
                          <path
                            d='M7.99919 3L5 8.09145L7.99919 6.69568V3Z'
                            fill='white'
                          />
                          <path
                            d='M7.99902 10.4851V12.9963L10.9998 8.67188L7.99902 10.4851Z'
                            fill='white'
                            fill-opacity='0.5'
                          />
                          <path
                            d='M7.99919 12.9963V10.4847L5 8.67188L7.99919 12.9963Z'
                            fill='white'
                          />
                          <path
                            d='M7.99902 9.90393L10.9978 8.09025L7.99902 6.69531V9.90393Z'
                            fill='white'
                            fill-opacity='0.1'
                          />
                          <path
                            d='M5 8.09025L7.99919 9.90393V6.69531L5 8.09025Z'
                            fill='white'
                            fill-opacity='0.5'
                          />
                        </svg>
                      }
                    />
                  </Table.Col>
                  <Table.Col center>4234</Table.Col>
                  <Table.Col center>
                    <Chain
                      name={'120K'}
                      reverse
                      smallIcon
                      transparentIcon
                      icon={
                        <svg
                          width='12'
                          height='12'
                          viewBox='0 0 12 12'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M8.4138 0.291703C8.30641 0.252347 8.19552 0.211485 8.07999 0.175844C6.24482 -0.400641 5.22397 0.5433 4.95266 1.51726C4.58097 2.84793 5.21459 3.75965 6.85658 5.10106C8.8694 6.74457 9.70256 8.27092 9.11883 10.3648C8.94667 10.9845 8.6586 11.5402 8.26389 12C9.89063 11.2974 11.2144 9.84416 11.7516 7.91792C12.6195 4.81091 11.1423 1.56073 8.4138 0.291703Z'
                            fill='#FF9700'
                          />
                          <path
                            d='M5.56033 6.32975C3.5978 4.81937 2.57808 3.187 3.13756 1.18055C3.22146 0.882968 3.33273 0.606473 3.46938 0.351562C1.96443 1.0936 0.757025 2.49214 0.248304 4.31246C-0.282671 6.21289 0.0643994 8.16573 1.03686 9.68876C1.70789 10.559 2.35753 11.2126 3.67621 11.5983C5.46184 12.1212 6.90155 11.3948 7.30222 9.95693C7.67467 8.62585 7.16983 7.64085 5.56033 6.32975Z'
                            fill='#2B3D52'
                          />
                        </svg>
                      }
                    />
                  </Table.Col>
                  <Table.Col center>$0.00031</Table.Col>
                  <Table.Col>$ 7.37M</Table.Col>
                  <Table.Col center>
                    <div className={`${style.diff} ${style.plus}`}>+15%</div>
                  </Table.Col>
                  <Table.Col center>500K</Table.Col>
                  <Table.Col center>In 2 month</Table.Col>
                </>
              )}
              <Table.Col center>
                <Chain
                  transparentIcon
                  name='2,387'
                  icon={
                    <svg
                      width='20'
                      height='20'
                      viewBox='0 0 20 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M14.9995 10.989C14.9676 8.52734 14.0196 6.82558 13.5049 6.08044C13.3725 5.88879 13.0898 5.91265 12.9873 6.1225C12.8296 6.44481 12.5766 6.85228 12.2321 7.06436C12.2321 7.06436 10.772 5.85628 10.7039 4.04639C10.6962 3.83982 10.4951 3.70022 10.3044 3.76674C9.53128 4.03669 7.89045 4.9435 7.49386 7.83261C7.16787 7.23021 6.39474 7.05422 5.89792 7.0047C5.69091 6.98397 5.5237 7.1804 5.57527 7.38726C5.95385 8.90512 5 9.81208 5 11.1164C5 13.9466 7.23092 16.2419 9.98568 16.25C12.7826 16.2582 15.0367 13.8602 14.9995 10.989Z'
                        fill='url(#paint0_linear_985_6256)'
                      />
                      <g filter='url(#filter0_b_985_6256)'>
                        <path
                          d='M16.2495 9.739C16.2176 7.27734 15.2696 5.57558 14.7549 4.83044C14.6225 4.63879 14.3398 4.66265 14.2373 4.8725C14.0796 5.19481 13.8266 5.60228 13.4821 5.81436C13.4821 5.81436 12.022 4.60628 11.9539 2.79639C11.9462 2.58982 11.7451 2.45022 11.5544 2.51674C10.7813 2.78669 9.14045 3.6935 8.74386 6.58261C8.41787 5.98021 7.64474 5.80422 7.14792 5.7547C6.94091 5.73397 6.7737 5.9304 6.82527 6.13726C7.20385 7.65512 6.25 8.56208 6.25 9.86637C6.25 12.6966 8.48092 14.9919 11.2357 15C14.0326 15.0082 16.2867 12.6102 16.2495 9.739Z'
                          fill='white'
                          fill-opacity='0.1'
                        />
                      </g>
                      <defs>
                        <filter
                          id='filter0_b_985_6256'
                          x='3.95'
                          y='0.2'
                          width='14.6'
                          height='17.1'
                          filterUnits='userSpaceOnUse'
                          color-interpolation-filters='sRGB'
                        >
                          <feFlood
                            flood-opacity='0'
                            result='BackgroundImageFix'
                          />
                          <feGaussianBlur
                            in='BackgroundImageFix'
                            stdDeviation='1.15'
                          />
                          <feComposite
                            in2='SourceAlpha'
                            operator='in'
                            result='effect1_backgroundBlur_985_6256'
                          />
                          <feBlend
                            mode='normal'
                            in='SourceGraphic'
                            in2='effect1_backgroundBlur_985_6256'
                            result='shape'
                          />
                        </filter>
                        <linearGradient
                          id='paint0_linear_985_6256'
                          x1='15.8333'
                          y1='3.75'
                          x2='8.82948'
                          y2='18.1169'
                          gradientUnits='userSpaceOnUse'
                        >
                          <stop stop-color='#F6FF8E' />
                          <stop offset='1' stop-color='#F26B41' />
                        </linearGradient>
                      </defs>
                    </svg>
                  }
                />
              </Table.Col>
              <Table.Col center>
                <Button sm filledGrey>
                  Boost
                </Button>
              </Table.Col>
            </Table.Row>
            <Table.Row>
              {!isTablet && <Table.Col>1</Table.Col>}
              <Table.Col>
                <button className={style.favorite}>
                  <svg
                    width='14'
                    height='13'
                    viewBox='0 0 14 13'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M6.26134 0.775945C6.53462 0.11891 7.46538 0.118909 7.73866 0.775943L8.92838 3.63637C9.04358 3.91336 9.30407 4.10261 9.6031 4.12659L12.6912 4.37416C13.4005 4.43102 13.6881 5.31623 13.1477 5.77916L10.7949 7.79457C10.5671 7.98974 10.4676 8.29596 10.5372 8.58776L11.256 11.6012C11.4211 12.2934 10.6681 12.8405 10.0608 12.4695L7.417 10.8547C7.16099 10.6983 6.83901 10.6983 6.583 10.8547L3.93918 12.4695C3.3319 12.8405 2.5789 12.2934 2.74401 11.6012L3.46282 8.58776C3.53243 8.29596 3.43293 7.98974 3.2051 7.79458L0.852314 5.77917C0.311884 5.31623 0.599504 4.43102 1.30883 4.37416L4.3969 4.12659C4.69593 4.10261 4.95642 3.91336 5.07162 3.63637L6.26134 0.775945Z'
                      fill='white'
                      fill-opacity='0.1'
                    />
                  </svg>
                </button>
              </Table.Col>
              <Table.Col>
                <TokenInfo icon={img} name={'Memereum'} label={'Meme'} />
              </Table.Col>
              {!isTablet && (
                <>
                  <Table.Col>
                    <div className={style.chains}>
                      <TypeDiv title={'Audit'} />
                      <TypeDiv title={'KYC  99/100'} />
                      <TypeDiv title={'Meme'} />
                    </div>
                  </Table.Col>
                  <Table.Col>
                    <Chain
                      name={'ETH'}
                      icon={
                        <svg
                          width='16'
                          height='16'
                          viewBox='0 0 16 16'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z'
                            fill='white'
                            fill-opacity='0.1'
                          />
                          <path
                            d='M7.99902 3V6.69568L10.9978 8.09145L7.99902 3Z'
                            fill='white'
                            fill-opacity='0.5'
                          />
                          <path
                            d='M7.99919 3L5 8.09145L7.99919 6.69568V3Z'
                            fill='white'
                          />
                          <path
                            d='M7.99902 10.4851V12.9963L10.9998 8.67188L7.99902 10.4851Z'
                            fill='white'
                            fill-opacity='0.5'
                          />
                          <path
                            d='M7.99919 12.9963V10.4847L5 8.67188L7.99919 12.9963Z'
                            fill='white'
                          />
                          <path
                            d='M7.99902 9.90393L10.9978 8.09025L7.99902 6.69531V9.90393Z'
                            fill='white'
                            fill-opacity='0.1'
                          />
                          <path
                            d='M5 8.09025L7.99919 9.90393V6.69531L5 8.09025Z'
                            fill='white'
                            fill-opacity='0.5'
                          />
                        </svg>
                      }
                    />
                  </Table.Col>
                  <Table.Col center>4234</Table.Col>
                  <Table.Col center>
                    <Chain
                      name={'120K'}
                      reverse
                      smallIcon
                      transparentIcon
                      icon={
                        <svg
                          width='12'
                          height='12'
                          viewBox='0 0 12 12'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M8.4138 0.291703C8.30641 0.252347 8.19552 0.211485 8.07999 0.175844C6.24482 -0.400641 5.22397 0.5433 4.95266 1.51726C4.58097 2.84793 5.21459 3.75965 6.85658 5.10106C8.8694 6.74457 9.70256 8.27092 9.11883 10.3648C8.94667 10.9845 8.6586 11.5402 8.26389 12C9.89063 11.2974 11.2144 9.84416 11.7516 7.91792C12.6195 4.81091 11.1423 1.56073 8.4138 0.291703Z'
                            fill='#FF9700'
                          />
                          <path
                            d='M5.56033 6.32975C3.5978 4.81937 2.57808 3.187 3.13756 1.18055C3.22146 0.882968 3.33273 0.606473 3.46938 0.351562C1.96443 1.0936 0.757025 2.49214 0.248304 4.31246C-0.282671 6.21289 0.0643994 8.16573 1.03686 9.68876C1.70789 10.559 2.35753 11.2126 3.67621 11.5983C5.46184 12.1212 6.90155 11.3948 7.30222 9.95693C7.67467 8.62585 7.16983 7.64085 5.56033 6.32975Z'
                            fill='#2B3D52'
                          />
                        </svg>
                      }
                    />
                  </Table.Col>
                  <Table.Col center>$0.00031</Table.Col>
                  <Table.Col>$ 7.37M</Table.Col>
                  <Table.Col center>
                    <div className={`${style.diff} ${style.minus}`}>-15%</div>
                  </Table.Col>
                  <Table.Col center>500K</Table.Col>
                  <Table.Col center>In 2 month</Table.Col>
                </>
              )}
              <Table.Col center>
                <Chain
                  transparentIcon
                  name='2,387'
                  icon={
                    <svg
                      width='20'
                      height='20'
                      viewBox='0 0 20 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M14.9995 10.989C14.9676 8.52734 14.0196 6.82558 13.5049 6.08044C13.3725 5.88879 13.0898 5.91265 12.9873 6.1225C12.8296 6.44481 12.5766 6.85228 12.2321 7.06436C12.2321 7.06436 10.772 5.85628 10.7039 4.04639C10.6962 3.83982 10.4951 3.70022 10.3044 3.76674C9.53128 4.03669 7.89045 4.9435 7.49386 7.83261C7.16787 7.23021 6.39474 7.05422 5.89792 7.0047C5.69091 6.98397 5.5237 7.1804 5.57527 7.38726C5.95385 8.90512 5 9.81208 5 11.1164C5 13.9466 7.23092 16.2419 9.98568 16.25C12.7826 16.2582 15.0367 13.8602 14.9995 10.989Z'
                        fill='url(#paint0_linear_985_6256)'
                      />
                      <g filter='url(#filter0_b_985_6256)'>
                        <path
                          d='M16.2495 9.739C16.2176 7.27734 15.2696 5.57558 14.7549 4.83044C14.6225 4.63879 14.3398 4.66265 14.2373 4.8725C14.0796 5.19481 13.8266 5.60228 13.4821 5.81436C13.4821 5.81436 12.022 4.60628 11.9539 2.79639C11.9462 2.58982 11.7451 2.45022 11.5544 2.51674C10.7813 2.78669 9.14045 3.6935 8.74386 6.58261C8.41787 5.98021 7.64474 5.80422 7.14792 5.7547C6.94091 5.73397 6.7737 5.9304 6.82527 6.13726C7.20385 7.65512 6.25 8.56208 6.25 9.86637C6.25 12.6966 8.48092 14.9919 11.2357 15C14.0326 15.0082 16.2867 12.6102 16.2495 9.739Z'
                          fill='white'
                          fill-opacity='0.1'
                        />
                      </g>
                      <defs>
                        <filter
                          id='filter0_b_985_6256'
                          x='3.95'
                          y='0.2'
                          width='14.6'
                          height='17.1'
                          filterUnits='userSpaceOnUse'
                          color-interpolation-filters='sRGB'
                        >
                          <feFlood
                            flood-opacity='0'
                            result='BackgroundImageFix'
                          />
                          <feGaussianBlur
                            in='BackgroundImageFix'
                            stdDeviation='1.15'
                          />
                          <feComposite
                            in2='SourceAlpha'
                            operator='in'
                            result='effect1_backgroundBlur_985_6256'
                          />
                          <feBlend
                            mode='normal'
                            in='SourceGraphic'
                            in2='effect1_backgroundBlur_985_6256'
                            result='shape'
                          />
                        </filter>
                        <linearGradient
                          id='paint0_linear_985_6256'
                          x1='15.8333'
                          y1='3.75'
                          x2='8.82948'
                          y2='18.1169'
                          gradientUnits='userSpaceOnUse'
                        >
                          <stop stop-color='#F6FF8E' />
                          <stop offset='1' stop-color='#F26B41' />
                        </linearGradient>
                      </defs>
                    </svg>
                  }
                />
              </Table.Col>
              <Table.Col center>
                <Button sm filledGrey>
                  Boost
                </Button>
              </Table.Col>
            </Table.Row>
            <Table.Row>
              {!isTablet && <Table.Col>1</Table.Col>}
              <Table.Col>
                <button className={style.favorite}>
                  <svg
                    width='14'
                    height='13'
                    viewBox='0 0 14 13'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M6.26134 0.775945C6.53462 0.11891 7.46538 0.118909 7.73866 0.775943L8.92838 3.63637C9.04358 3.91336 9.30407 4.10261 9.6031 4.12659L12.6912 4.37416C13.4005 4.43102 13.6881 5.31623 13.1477 5.77916L10.7949 7.79457C10.5671 7.98974 10.4676 8.29596 10.5372 8.58776L11.256 11.6012C11.4211 12.2934 10.6681 12.8405 10.0608 12.4695L7.417 10.8547C7.16099 10.6983 6.83901 10.6983 6.583 10.8547L3.93918 12.4695C3.3319 12.8405 2.5789 12.2934 2.74401 11.6012L3.46282 8.58776C3.53243 8.29596 3.43293 7.98974 3.2051 7.79458L0.852314 5.77917C0.311884 5.31623 0.599504 4.43102 1.30883 4.37416L4.3969 4.12659C4.69593 4.10261 4.95642 3.91336 5.07162 3.63637L6.26134 0.775945Z'
                      fill='white'
                      fill-opacity='0.1'
                    />
                  </svg>
                </button>
              </Table.Col>
              <Table.Col>
                <TokenInfo icon={img} name={'Memereum'} label={'Meme'} />
              </Table.Col>
              {!isTablet && (
                <>
                  <Table.Col>
                    <div className={style.chains}>
                      <TypeDiv title={'Audit'} />
                      <TypeDiv title={'KYC  99/100'} />
                      <TypeDiv title={'Meme'} />
                    </div>
                  </Table.Col>
                  <Table.Col>
                    <Chain
                      name={'ETH'}
                      icon={
                        <svg
                          width='16'
                          height='16'
                          viewBox='0 0 16 16'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z'
                            fill='white'
                            fill-opacity='0.1'
                          />
                          <path
                            d='M7.99902 3V6.69568L10.9978 8.09145L7.99902 3Z'
                            fill='white'
                            fill-opacity='0.5'
                          />
                          <path
                            d='M7.99919 3L5 8.09145L7.99919 6.69568V3Z'
                            fill='white'
                          />
                          <path
                            d='M7.99902 10.4851V12.9963L10.9998 8.67188L7.99902 10.4851Z'
                            fill='white'
                            fill-opacity='0.5'
                          />
                          <path
                            d='M7.99919 12.9963V10.4847L5 8.67188L7.99919 12.9963Z'
                            fill='white'
                          />
                          <path
                            d='M7.99902 9.90393L10.9978 8.09025L7.99902 6.69531V9.90393Z'
                            fill='white'
                            fill-opacity='0.1'
                          />
                          <path
                            d='M5 8.09025L7.99919 9.90393V6.69531L5 8.09025Z'
                            fill='white'
                            fill-opacity='0.5'
                          />
                        </svg>
                      }
                    />
                  </Table.Col>
                  <Table.Col center>4234</Table.Col>
                  <Table.Col center>
                    <Chain
                      name={'120K'}
                      reverse
                      smallIcon
                      transparentIcon
                      icon={
                        <svg
                          width='12'
                          height='12'
                          viewBox='0 0 12 12'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M8.4138 0.291703C8.30641 0.252347 8.19552 0.211485 8.07999 0.175844C6.24482 -0.400641 5.22397 0.5433 4.95266 1.51726C4.58097 2.84793 5.21459 3.75965 6.85658 5.10106C8.8694 6.74457 9.70256 8.27092 9.11883 10.3648C8.94667 10.9845 8.6586 11.5402 8.26389 12C9.89063 11.2974 11.2144 9.84416 11.7516 7.91792C12.6195 4.81091 11.1423 1.56073 8.4138 0.291703Z'
                            fill='#FF9700'
                          />
                          <path
                            d='M5.56033 6.32975C3.5978 4.81937 2.57808 3.187 3.13756 1.18055C3.22146 0.882968 3.33273 0.606473 3.46938 0.351562C1.96443 1.0936 0.757025 2.49214 0.248304 4.31246C-0.282671 6.21289 0.0643994 8.16573 1.03686 9.68876C1.70789 10.559 2.35753 11.2126 3.67621 11.5983C5.46184 12.1212 6.90155 11.3948 7.30222 9.95693C7.67467 8.62585 7.16983 7.64085 5.56033 6.32975Z'
                            fill='#2B3D52'
                          />
                        </svg>
                      }
                    />
                  </Table.Col>
                  <Table.Col center>$0.00031</Table.Col>
                  <Table.Col>$ 7.37M</Table.Col>
                  <Table.Col center>
                    <div className={`${style.diff}`}>-</div>
                  </Table.Col>
                  <Table.Col center>500K</Table.Col>
                  <Table.Col center>In 2 month</Table.Col>
                </>
              )}
              <Table.Col center>
                <Chain
                  transparentIcon
                  name='2,387'
                  icon={
                    <svg
                      width='20'
                      height='20'
                      viewBox='0 0 20 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M14.9995 10.989C14.9676 8.52734 14.0196 6.82558 13.5049 6.08044C13.3725 5.88879 13.0898 5.91265 12.9873 6.1225C12.8296 6.44481 12.5766 6.85228 12.2321 7.06436C12.2321 7.06436 10.772 5.85628 10.7039 4.04639C10.6962 3.83982 10.4951 3.70022 10.3044 3.76674C9.53128 4.03669 7.89045 4.9435 7.49386 7.83261C7.16787 7.23021 6.39474 7.05422 5.89792 7.0047C5.69091 6.98397 5.5237 7.1804 5.57527 7.38726C5.95385 8.90512 5 9.81208 5 11.1164C5 13.9466 7.23092 16.2419 9.98568 16.25C12.7826 16.2582 15.0367 13.8602 14.9995 10.989Z'
                        fill='url(#paint0_linear_985_6256)'
                      />
                      <g filter='url(#filter0_b_985_6256)'>
                        <path
                          d='M16.2495 9.739C16.2176 7.27734 15.2696 5.57558 14.7549 4.83044C14.6225 4.63879 14.3398 4.66265 14.2373 4.8725C14.0796 5.19481 13.8266 5.60228 13.4821 5.81436C13.4821 5.81436 12.022 4.60628 11.9539 2.79639C11.9462 2.58982 11.7451 2.45022 11.5544 2.51674C10.7813 2.78669 9.14045 3.6935 8.74386 6.58261C8.41787 5.98021 7.64474 5.80422 7.14792 5.7547C6.94091 5.73397 6.7737 5.9304 6.82527 6.13726C7.20385 7.65512 6.25 8.56208 6.25 9.86637C6.25 12.6966 8.48092 14.9919 11.2357 15C14.0326 15.0082 16.2867 12.6102 16.2495 9.739Z'
                          fill='white'
                          fill-opacity='0.1'
                        />
                      </g>
                      <defs>
                        <filter
                          id='filter0_b_985_6256'
                          x='3.95'
                          y='0.2'
                          width='14.6'
                          height='17.1'
                          filterUnits='userSpaceOnUse'
                          color-interpolation-filters='sRGB'
                        >
                          <feFlood
                            flood-opacity='0'
                            result='BackgroundImageFix'
                          />
                          <feGaussianBlur
                            in='BackgroundImageFix'
                            stdDeviation='1.15'
                          />
                          <feComposite
                            in2='SourceAlpha'
                            operator='in'
                            result='effect1_backgroundBlur_985_6256'
                          />
                          <feBlend
                            mode='normal'
                            in='SourceGraphic'
                            in2='effect1_backgroundBlur_985_6256'
                            result='shape'
                          />
                        </filter>
                        <linearGradient
                          id='paint0_linear_985_6256'
                          x1='15.8333'
                          y1='3.75'
                          x2='8.82948'
                          y2='18.1169'
                          gradientUnits='userSpaceOnUse'
                        >
                          <stop stop-color='#F6FF8E' />
                          <stop offset='1' stop-color='#F26B41' />
                        </linearGradient>
                      </defs>
                    </svg>
                  }
                />
              </Table.Col>
              <Table.Col center>
                <Button sm filledGrey>
                  Boost
                </Button>
              </Table.Col>
            </Table.Row>
            <Table.Row>
              {!isTablet && <Table.Col>1</Table.Col>}
              <Table.Col>
                <button className={style.favorite}>
                  <svg
                    width='14'
                    height='13'
                    viewBox='0 0 14 13'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M6.26134 0.775945C6.53462 0.11891 7.46538 0.118909 7.73866 0.775943L8.92838 3.63637C9.04358 3.91336 9.30407 4.10261 9.6031 4.12659L12.6912 4.37416C13.4005 4.43102 13.6881 5.31623 13.1477 5.77916L10.7949 7.79457C10.5671 7.98974 10.4676 8.29596 10.5372 8.58776L11.256 11.6012C11.4211 12.2934 10.6681 12.8405 10.0608 12.4695L7.417 10.8547C7.16099 10.6983 6.83901 10.6983 6.583 10.8547L3.93918 12.4695C3.3319 12.8405 2.5789 12.2934 2.74401 11.6012L3.46282 8.58776C3.53243 8.29596 3.43293 7.98974 3.2051 7.79458L0.852314 5.77917C0.311884 5.31623 0.599504 4.43102 1.30883 4.37416L4.3969 4.12659C4.69593 4.10261 4.95642 3.91336 5.07162 3.63637L6.26134 0.775945Z'
                      fill='white'
                      fill-opacity='0.1'
                    />
                  </svg>
                </button>
              </Table.Col>
              <Table.Col>
                <TokenInfo icon={img} name={'Memereum'} label={'Meme'} />
              </Table.Col>
              {!isTablet && (
                <>
                  <Table.Col>
                    <div className={style.chains}>
                      <TypeDiv title={'Audit'} />
                      <TypeDiv title={'KYC  99/100'} />
                      <TypeDiv title={'Meme'} />
                    </div>
                  </Table.Col>
                  <Table.Col>
                    <Chain
                      name={'ETH'}
                      icon={
                        <svg
                          width='16'
                          height='16'
                          viewBox='0 0 16 16'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z'
                            fill='white'
                            fill-opacity='0.1'
                          />
                          <path
                            d='M7.99902 3V6.69568L10.9978 8.09145L7.99902 3Z'
                            fill='white'
                            fill-opacity='0.5'
                          />
                          <path
                            d='M7.99919 3L5 8.09145L7.99919 6.69568V3Z'
                            fill='white'
                          />
                          <path
                            d='M7.99902 10.4851V12.9963L10.9998 8.67188L7.99902 10.4851Z'
                            fill='white'
                            fill-opacity='0.5'
                          />
                          <path
                            d='M7.99919 12.9963V10.4847L5 8.67188L7.99919 12.9963Z'
                            fill='white'
                          />
                          <path
                            d='M7.99902 9.90393L10.9978 8.09025L7.99902 6.69531V9.90393Z'
                            fill='white'
                            fill-opacity='0.1'
                          />
                          <path
                            d='M5 8.09025L7.99919 9.90393V6.69531L5 8.09025Z'
                            fill='white'
                            fill-opacity='0.5'
                          />
                        </svg>
                      }
                    />
                  </Table.Col>
                  <Table.Col center>4234</Table.Col>
                  <Table.Col center>
                    <Chain
                      name={'120K'}
                      reverse
                      smallIcon
                      transparentIcon
                      icon={
                        <svg
                          width='12'
                          height='12'
                          viewBox='0 0 12 12'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M8.4138 0.291703C8.30641 0.252347 8.19552 0.211485 8.07999 0.175844C6.24482 -0.400641 5.22397 0.5433 4.95266 1.51726C4.58097 2.84793 5.21459 3.75965 6.85658 5.10106C8.8694 6.74457 9.70256 8.27092 9.11883 10.3648C8.94667 10.9845 8.6586 11.5402 8.26389 12C9.89063 11.2974 11.2144 9.84416 11.7516 7.91792C12.6195 4.81091 11.1423 1.56073 8.4138 0.291703Z'
                            fill='#FF9700'
                          />
                          <path
                            d='M5.56033 6.32975C3.5978 4.81937 2.57808 3.187 3.13756 1.18055C3.22146 0.882968 3.33273 0.606473 3.46938 0.351562C1.96443 1.0936 0.757025 2.49214 0.248304 4.31246C-0.282671 6.21289 0.0643994 8.16573 1.03686 9.68876C1.70789 10.559 2.35753 11.2126 3.67621 11.5983C5.46184 12.1212 6.90155 11.3948 7.30222 9.95693C7.67467 8.62585 7.16983 7.64085 5.56033 6.32975Z'
                            fill='#2B3D52'
                          />
                        </svg>
                      }
                    />
                  </Table.Col>
                  <Table.Col center>$0.00031</Table.Col>
                  <Table.Col>
                    <Chain
                      name={'Presale <br/> $0.001'}
                      transparentIcon
                      icon={
                        <svg
                          width='16'
                          height='16'
                          viewBox='0 0 16 16'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M10.8259 11.2583C10.9278 11.425 10.9874 11.62 10.9982 11.8222C11.009 12.0244 10.9707 12.226 10.8874 12.4054C10.8041 12.5848 10.6789 12.735 10.5254 12.8398C10.372 12.9446 10.1961 13 10.0167 13H5.98328C5.80395 13 5.62802 12.9446 5.47456 12.8398C5.32109 12.735 5.19592 12.5848 5.11259 12.4054C5.02927 12.226 4.99095 12.0244 5.0018 11.8222C5.01265 11.62 5.07224 11.425 5.17414 11.2583L6.52402 9.03949C6.9125 8.40094 6.9125 7.59906 6.52402 6.96051L5.17414 4.74167C5.07224 4.57498 5.01265 4.38 5.0018 4.17782C4.99095 3.97564 5.02927 3.77395 5.11259 3.59459C5.19592 3.41523 5.32109 3.26502 5.47456 3.16022C5.62802 3.05542 5.80395 3.00001 5.98328 3H10.0167C10.1961 3.00001 10.372 3.05542 10.5254 3.16022C10.6789 3.26502 10.8041 3.41523 10.8874 3.59459C10.9707 3.77395 11.009 3.97564 10.9982 4.17782C10.9874 4.38 10.9278 4.57498 10.8259 4.74167L9.47598 6.96051C9.0875 7.59906 9.0875 8.40094 9.47598 9.03949L10.8259 11.2583Z'
                            fill='url(#paint0_linear_133_63079)'
                          />
                          <g filter='url(#filter0_b_133_63079)'>
                            <path
                              d='M11.8259 10.2583C11.9278 10.425 11.9874 10.62 11.9982 10.8222C12.009 11.0244 11.9707 11.226 11.8874 11.4054C11.8041 11.5848 11.6789 11.735 11.5254 11.8398C11.372 11.9446 11.1961 12 11.0167 12H6.98328C6.80395 12 6.62802 11.9446 6.47456 11.8398C6.32109 11.735 6.19592 11.5848 6.11259 11.4054C6.02927 11.226 5.99095 11.0244 6.0018 10.8222C6.01265 10.62 6.07224 10.425 6.17414 10.2583L7.52402 8.03949C7.9125 7.40094 7.9125 6.59906 7.52402 5.96051L6.17414 3.74167C6.07224 3.57498 6.01265 3.38 6.0018 3.17782C5.99095 2.97564 6.02927 2.77395 6.11259 2.59459C6.19592 2.41523 6.32109 2.26502 6.47456 2.16022C6.62802 2.05542 6.80395 2.00001 6.98328 2H11.0167C11.1961 2.00001 11.372 2.05542 11.5254 2.16022C11.6789 2.26502 11.8041 2.41523 11.8874 2.59459C11.9707 2.77395 12.009 2.97564 11.9982 3.17782C11.9874 3.38 11.9278 3.57498 11.8259 3.74167L10.476 5.96051C10.0875 6.59906 10.0875 7.40094 10.476 8.03949L11.8259 10.2583Z'
                              fill='white'
                              fill-opacity='0.1'
                            />
                          </g>
                          <defs>
                            <filter
                              id='filter0_b_133_63079'
                              x='4.1'
                              y='0.1'
                              width='9.8'
                              height='13.8'
                              filterUnits='userSpaceOnUse'
                              color-interpolation-filters='sRGB'
                            >
                              <feFlood
                                flood-opacity='0'
                                result='BackgroundImageFix'
                              />
                              <feGaussianBlur
                                in='BackgroundImageFix'
                                stdDeviation='0.95'
                              />
                              <feComposite
                                in2='SourceAlpha'
                                operator='in'
                                result='effect1_backgroundBlur_133_63079'
                              />
                              <feBlend
                                mode='normal'
                                in='SourceGraphic'
                                in2='effect1_backgroundBlur_133_63079'
                                result='shape'
                              />
                            </filter>
                            <linearGradient
                              id='paint0_linear_133_63079'
                              x1='11.5'
                              y1='3'
                              x2='5'
                              y2='13'
                              gradientUnits='userSpaceOnUse'
                            >
                              <stop stop-color='#BFD3F0' />
                              <stop offset='1' stop-color='#B7FF96' />
                            </linearGradient>
                          </defs>
                        </svg>
                      }
                    />
                  </Table.Col>
                  <Table.Col center>
                    <div className={`${style.diff}`}>-</div>
                  </Table.Col>
                  <Table.Col center>500K</Table.Col>
                  <Table.Col center>In 2 month</Table.Col>
                </>
              )}
              <Table.Col center>
                <Chain
                  transparentIcon
                  name='2,387'
                  icon={
                    <svg
                      width='20'
                      height='20'
                      viewBox='0 0 20 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M14.9995 10.989C14.9676 8.52734 14.0196 6.82558 13.5049 6.08044C13.3725 5.88879 13.0898 5.91265 12.9873 6.1225C12.8296 6.44481 12.5766 6.85228 12.2321 7.06436C12.2321 7.06436 10.772 5.85628 10.7039 4.04639C10.6962 3.83982 10.4951 3.70022 10.3044 3.76674C9.53128 4.03669 7.89045 4.9435 7.49386 7.83261C7.16787 7.23021 6.39474 7.05422 5.89792 7.0047C5.69091 6.98397 5.5237 7.1804 5.57527 7.38726C5.95385 8.90512 5 9.81208 5 11.1164C5 13.9466 7.23092 16.2419 9.98568 16.25C12.7826 16.2582 15.0367 13.8602 14.9995 10.989Z'
                        fill='url(#paint0_linear_985_6256)'
                      />
                      <g filter='url(#filter0_b_985_6256)'>
                        <path
                          d='M16.2495 9.739C16.2176 7.27734 15.2696 5.57558 14.7549 4.83044C14.6225 4.63879 14.3398 4.66265 14.2373 4.8725C14.0796 5.19481 13.8266 5.60228 13.4821 5.81436C13.4821 5.81436 12.022 4.60628 11.9539 2.79639C11.9462 2.58982 11.7451 2.45022 11.5544 2.51674C10.7813 2.78669 9.14045 3.6935 8.74386 6.58261C8.41787 5.98021 7.64474 5.80422 7.14792 5.7547C6.94091 5.73397 6.7737 5.9304 6.82527 6.13726C7.20385 7.65512 6.25 8.56208 6.25 9.86637C6.25 12.6966 8.48092 14.9919 11.2357 15C14.0326 15.0082 16.2867 12.6102 16.2495 9.739Z'
                          fill='white'
                          fill-opacity='0.1'
                        />
                      </g>
                      <defs>
                        <filter
                          id='filter0_b_985_6256'
                          x='3.95'
                          y='0.2'
                          width='14.6'
                          height='17.1'
                          filterUnits='userSpaceOnUse'
                          color-interpolation-filters='sRGB'
                        >
                          <feFlood
                            flood-opacity='0'
                            result='BackgroundImageFix'
                          />
                          <feGaussianBlur
                            in='BackgroundImageFix'
                            stdDeviation='1.15'
                          />
                          <feComposite
                            in2='SourceAlpha'
                            operator='in'
                            result='effect1_backgroundBlur_985_6256'
                          />
                          <feBlend
                            mode='normal'
                            in='SourceGraphic'
                            in2='effect1_backgroundBlur_985_6256'
                            result='shape'
                          />
                        </filter>
                        <linearGradient
                          id='paint0_linear_985_6256'
                          x1='15.8333'
                          y1='3.75'
                          x2='8.82948'
                          y2='18.1169'
                          gradientUnits='userSpaceOnUse'
                        >
                          <stop stop-color='#F6FF8E' />
                          <stop offset='1' stop-color='#F26B41' />
                        </linearGradient>
                      </defs>
                    </svg>
                  }
                />
              </Table.Col>
              <Table.Col center>
                <Button sm filledGrey>
                  Boost
                </Button>
              </Table.Col>
            </Table.Row>
            <Table.Row>
              {!isTablet && <Table.Col>1</Table.Col>}
              <Table.Col>
                <button className={style.favorite}>
                  <svg
                    width='14'
                    height='13'
                    viewBox='0 0 14 13'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M6.26134 0.775945C6.53462 0.11891 7.46538 0.118909 7.73866 0.775943L8.92838 3.63637C9.04358 3.91336 9.30407 4.10261 9.6031 4.12659L12.6912 4.37416C13.4005 4.43102 13.6881 5.31623 13.1477 5.77916L10.7949 7.79457C10.5671 7.98974 10.4676 8.29596 10.5372 8.58776L11.256 11.6012C11.4211 12.2934 10.6681 12.8405 10.0608 12.4695L7.417 10.8547C7.16099 10.6983 6.83901 10.6983 6.583 10.8547L3.93918 12.4695C3.3319 12.8405 2.5789 12.2934 2.74401 11.6012L3.46282 8.58776C3.53243 8.29596 3.43293 7.98974 3.2051 7.79458L0.852314 5.77917C0.311884 5.31623 0.599504 4.43102 1.30883 4.37416L4.3969 4.12659C4.69593 4.10261 4.95642 3.91336 5.07162 3.63637L6.26134 0.775945Z'
                      fill='white'
                      fill-opacity='0.1'
                    />
                  </svg>
                </button>
              </Table.Col>
              <Table.Col>
                <TokenInfo icon={img} name={'Memereum'} label={'Meme'} />
              </Table.Col>
              {!isTablet && (
                <>
                  <Table.Col>
                    <div className={style.chains}>
                      <TypeDiv title={'Audit'} />
                      <TypeDiv title={'KYC  99/100'} />
                      <TypeDiv title={'Meme'} />
                    </div>
                  </Table.Col>
                  <Table.Col>
                    <Chain
                      name={'ETH'}
                      icon={
                        <svg
                          width='16'
                          height='16'
                          viewBox='0 0 16 16'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z'
                            fill='white'
                            fill-opacity='0.1'
                          />
                          <path
                            d='M7.99902 3V6.69568L10.9978 8.09145L7.99902 3Z'
                            fill='white'
                            fill-opacity='0.5'
                          />
                          <path
                            d='M7.99919 3L5 8.09145L7.99919 6.69568V3Z'
                            fill='white'
                          />
                          <path
                            d='M7.99902 10.4851V12.9963L10.9998 8.67188L7.99902 10.4851Z'
                            fill='white'
                            fill-opacity='0.5'
                          />
                          <path
                            d='M7.99919 12.9963V10.4847L5 8.67188L7.99919 12.9963Z'
                            fill='white'
                          />
                          <path
                            d='M7.99902 9.90393L10.9978 8.09025L7.99902 6.69531V9.90393Z'
                            fill='white'
                            fill-opacity='0.1'
                          />
                          <path
                            d='M5 8.09025L7.99919 9.90393V6.69531L5 8.09025Z'
                            fill='white'
                            fill-opacity='0.5'
                          />
                        </svg>
                      }
                    />
                  </Table.Col>
                  <Table.Col center>4234</Table.Col>
                  <Table.Col center>
                    <Chain
                      name={'120K'}
                      reverse
                      smallIcon
                      transparentIcon
                      icon={
                        <svg
                          width='12'
                          height='12'
                          viewBox='0 0 12 12'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M8.4138 0.291703C8.30641 0.252347 8.19552 0.211485 8.07999 0.175844C6.24482 -0.400641 5.22397 0.5433 4.95266 1.51726C4.58097 2.84793 5.21459 3.75965 6.85658 5.10106C8.8694 6.74457 9.70256 8.27092 9.11883 10.3648C8.94667 10.9845 8.6586 11.5402 8.26389 12C9.89063 11.2974 11.2144 9.84416 11.7516 7.91792C12.6195 4.81091 11.1423 1.56073 8.4138 0.291703Z'
                            fill='#FF9700'
                          />
                          <path
                            d='M5.56033 6.32975C3.5978 4.81937 2.57808 3.187 3.13756 1.18055C3.22146 0.882968 3.33273 0.606473 3.46938 0.351562C1.96443 1.0936 0.757025 2.49214 0.248304 4.31246C-0.282671 6.21289 0.0643994 8.16573 1.03686 9.68876C1.70789 10.559 2.35753 11.2126 3.67621 11.5983C5.46184 12.1212 6.90155 11.3948 7.30222 9.95693C7.67467 8.62585 7.16983 7.64085 5.56033 6.32975Z'
                            fill='#2B3D52'
                          />
                        </svg>
                      }
                    />
                  </Table.Col>
                  <Table.Col center>$0.00031</Table.Col>
                  <Table.Col>$ 7.37M</Table.Col>
                  <Table.Col center>
                    <div className={`${style.diff}`}>-</div>
                  </Table.Col>
                  <Table.Col center>500K</Table.Col>
                  <Table.Col center>In 2 month</Table.Col>
                </>
              )}
              <Table.Col center>
                <Chain
                  transparentIcon
                  name='2,387'
                  icon={
                    <svg
                      width='20'
                      height='20'
                      viewBox='0 0 20 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M14.9995 10.989C14.9676 8.52734 14.0196 6.82558 13.5049 6.08044C13.3725 5.88879 13.0898 5.91265 12.9873 6.1225C12.8296 6.44481 12.5766 6.85228 12.2321 7.06436C12.2321 7.06436 10.772 5.85628 10.7039 4.04639C10.6962 3.83982 10.4951 3.70022 10.3044 3.76674C9.53128 4.03669 7.89045 4.9435 7.49386 7.83261C7.16787 7.23021 6.39474 7.05422 5.89792 7.0047C5.69091 6.98397 5.5237 7.1804 5.57527 7.38726C5.95385 8.90512 5 9.81208 5 11.1164C5 13.9466 7.23092 16.2419 9.98568 16.25C12.7826 16.2582 15.0367 13.8602 14.9995 10.989Z'
                        fill='url(#paint0_linear_985_6256)'
                      />
                      <g filter='url(#filter0_b_985_6256)'>
                        <path
                          d='M16.2495 9.739C16.2176 7.27734 15.2696 5.57558 14.7549 4.83044C14.6225 4.63879 14.3398 4.66265 14.2373 4.8725C14.0796 5.19481 13.8266 5.60228 13.4821 5.81436C13.4821 5.81436 12.022 4.60628 11.9539 2.79639C11.9462 2.58982 11.7451 2.45022 11.5544 2.51674C10.7813 2.78669 9.14045 3.6935 8.74386 6.58261C8.41787 5.98021 7.64474 5.80422 7.14792 5.7547C6.94091 5.73397 6.7737 5.9304 6.82527 6.13726C7.20385 7.65512 6.25 8.56208 6.25 9.86637C6.25 12.6966 8.48092 14.9919 11.2357 15C14.0326 15.0082 16.2867 12.6102 16.2495 9.739Z'
                          fill='white'
                          fill-opacity='0.1'
                        />
                      </g>
                      <defs>
                        <filter
                          id='filter0_b_985_6256'
                          x='3.95'
                          y='0.2'
                          width='14.6'
                          height='17.1'
                          filterUnits='userSpaceOnUse'
                          color-interpolation-filters='sRGB'
                        >
                          <feFlood
                            flood-opacity='0'
                            result='BackgroundImageFix'
                          />
                          <feGaussianBlur
                            in='BackgroundImageFix'
                            stdDeviation='1.15'
                          />
                          <feComposite
                            in2='SourceAlpha'
                            operator='in'
                            result='effect1_backgroundBlur_985_6256'
                          />
                          <feBlend
                            mode='normal'
                            in='SourceGraphic'
                            in2='effect1_backgroundBlur_985_6256'
                            result='shape'
                          />
                        </filter>
                        <linearGradient
                          id='paint0_linear_985_6256'
                          x1='15.8333'
                          y1='3.75'
                          x2='8.82948'
                          y2='18.1169'
                          gradientUnits='userSpaceOnUse'
                        >
                          <stop stop-color='#F6FF8E' />
                          <stop offset='1' stop-color='#F26B41' />
                        </linearGradient>
                      </defs>
                    </svg>
                  }
                />
              </Table.Col>
              <Table.Col center>
                <Button sm filledGrey>
                  Boost
                </Button>
              </Table.Col>
            </Table.Row>
            <Table.Row>
              {!isTablet && <Table.Col>1</Table.Col>}
              <Table.Col>
                <button className={style.favorite}>
                  <svg
                    width='14'
                    height='13'
                    viewBox='0 0 14 13'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M6.26134 0.775945C6.53462 0.11891 7.46538 0.118909 7.73866 0.775943L8.92838 3.63637C9.04358 3.91336 9.30407 4.10261 9.6031 4.12659L12.6912 4.37416C13.4005 4.43102 13.6881 5.31623 13.1477 5.77916L10.7949 7.79457C10.5671 7.98974 10.4676 8.29596 10.5372 8.58776L11.256 11.6012C11.4211 12.2934 10.6681 12.8405 10.0608 12.4695L7.417 10.8547C7.16099 10.6983 6.83901 10.6983 6.583 10.8547L3.93918 12.4695C3.3319 12.8405 2.5789 12.2934 2.74401 11.6012L3.46282 8.58776C3.53243 8.29596 3.43293 7.98974 3.2051 7.79458L0.852314 5.77917C0.311884 5.31623 0.599504 4.43102 1.30883 4.37416L4.3969 4.12659C4.69593 4.10261 4.95642 3.91336 5.07162 3.63637L6.26134 0.775945Z'
                      fill='white'
                      fill-opacity='0.1'
                    />
                  </svg>
                </button>
              </Table.Col>
              <Table.Col>
                <TokenInfo icon={img} name={'Memereum'} label={'Meme'} />
              </Table.Col>
              {!isTablet && (
                <>
                  <Table.Col>
                    <div className={style.chains}>
                      <TypeDiv title={'Audit'} />
                      <TypeDiv title={'KYC  99/100'} />
                      <TypeDiv title={'Meme'} />
                    </div>
                  </Table.Col>
                  <Table.Col>
                    <Chain
                      name={'ETH'}
                      icon={
                        <svg
                          width='16'
                          height='16'
                          viewBox='0 0 16 16'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z'
                            fill='white'
                            fill-opacity='0.1'
                          />
                          <path
                            d='M7.99902 3V6.69568L10.9978 8.09145L7.99902 3Z'
                            fill='white'
                            fill-opacity='0.5'
                          />
                          <path
                            d='M7.99919 3L5 8.09145L7.99919 6.69568V3Z'
                            fill='white'
                          />
                          <path
                            d='M7.99902 10.4851V12.9963L10.9998 8.67188L7.99902 10.4851Z'
                            fill='white'
                            fill-opacity='0.5'
                          />
                          <path
                            d='M7.99919 12.9963V10.4847L5 8.67188L7.99919 12.9963Z'
                            fill='white'
                          />
                          <path
                            d='M7.99902 9.90393L10.9978 8.09025L7.99902 6.69531V9.90393Z'
                            fill='white'
                            fill-opacity='0.1'
                          />
                          <path
                            d='M5 8.09025L7.99919 9.90393V6.69531L5 8.09025Z'
                            fill='white'
                            fill-opacity='0.5'
                          />
                        </svg>
                      }
                    />
                  </Table.Col>
                  <Table.Col center>4234</Table.Col>
                  <Table.Col center>
                    <Chain
                      name={'120K'}
                      reverse
                      smallIcon
                      transparentIcon
                      icon={
                        <svg
                          width='12'
                          height='12'
                          viewBox='0 0 12 12'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M8.4138 0.291703C8.30641 0.252347 8.19552 0.211485 8.07999 0.175844C6.24482 -0.400641 5.22397 0.5433 4.95266 1.51726C4.58097 2.84793 5.21459 3.75965 6.85658 5.10106C8.8694 6.74457 9.70256 8.27092 9.11883 10.3648C8.94667 10.9845 8.6586 11.5402 8.26389 12C9.89063 11.2974 11.2144 9.84416 11.7516 7.91792C12.6195 4.81091 11.1423 1.56073 8.4138 0.291703Z'
                            fill='#FF9700'
                          />
                          <path
                            d='M5.56033 6.32975C3.5978 4.81937 2.57808 3.187 3.13756 1.18055C3.22146 0.882968 3.33273 0.606473 3.46938 0.351562C1.96443 1.0936 0.757025 2.49214 0.248304 4.31246C-0.282671 6.21289 0.0643994 8.16573 1.03686 9.68876C1.70789 10.559 2.35753 11.2126 3.67621 11.5983C5.46184 12.1212 6.90155 11.3948 7.30222 9.95693C7.67467 8.62585 7.16983 7.64085 5.56033 6.32975Z'
                            fill='#2B3D52'
                          />
                        </svg>
                      }
                    />
                  </Table.Col>
                  <Table.Col center>$0.00031</Table.Col>
                  <Table.Col>$ 7.37M</Table.Col>
                  <Table.Col center>
                    <div className={`${style.diff}`}>-</div>
                  </Table.Col>
                  <Table.Col center>500K</Table.Col>
                  <Table.Col center>In 2 month</Table.Col>
                </>
              )}
              <Table.Col center>
                <Chain
                  transparentIcon
                  name='2,387'
                  icon={
                    <svg
                      width='20'
                      height='20'
                      viewBox='0 0 20 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M14.9995 10.989C14.9676 8.52734 14.0196 6.82558 13.5049 6.08044C13.3725 5.88879 13.0898 5.91265 12.9873 6.1225C12.8296 6.44481 12.5766 6.85228 12.2321 7.06436C12.2321 7.06436 10.772 5.85628 10.7039 4.04639C10.6962 3.83982 10.4951 3.70022 10.3044 3.76674C9.53128 4.03669 7.89045 4.9435 7.49386 7.83261C7.16787 7.23021 6.39474 7.05422 5.89792 7.0047C5.69091 6.98397 5.5237 7.1804 5.57527 7.38726C5.95385 8.90512 5 9.81208 5 11.1164C5 13.9466 7.23092 16.2419 9.98568 16.25C12.7826 16.2582 15.0367 13.8602 14.9995 10.989Z'
                        fill='url(#paint0_linear_985_6256)'
                      />
                      <g filter='url(#filter0_b_985_6256)'>
                        <path
                          d='M16.2495 9.739C16.2176 7.27734 15.2696 5.57558 14.7549 4.83044C14.6225 4.63879 14.3398 4.66265 14.2373 4.8725C14.0796 5.19481 13.8266 5.60228 13.4821 5.81436C13.4821 5.81436 12.022 4.60628 11.9539 2.79639C11.9462 2.58982 11.7451 2.45022 11.5544 2.51674C10.7813 2.78669 9.14045 3.6935 8.74386 6.58261C8.41787 5.98021 7.64474 5.80422 7.14792 5.7547C6.94091 5.73397 6.7737 5.9304 6.82527 6.13726C7.20385 7.65512 6.25 8.56208 6.25 9.86637C6.25 12.6966 8.48092 14.9919 11.2357 15C14.0326 15.0082 16.2867 12.6102 16.2495 9.739Z'
                          fill='white'
                          fill-opacity='0.1'
                        />
                      </g>
                      <defs>
                        <filter
                          id='filter0_b_985_6256'
                          x='3.95'
                          y='0.2'
                          width='14.6'
                          height='17.1'
                          filterUnits='userSpaceOnUse'
                          color-interpolation-filters='sRGB'
                        >
                          <feFlood
                            flood-opacity='0'
                            result='BackgroundImageFix'
                          />
                          <feGaussianBlur
                            in='BackgroundImageFix'
                            stdDeviation='1.15'
                          />
                          <feComposite
                            in2='SourceAlpha'
                            operator='in'
                            result='effect1_backgroundBlur_985_6256'
                          />
                          <feBlend
                            mode='normal'
                            in='SourceGraphic'
                            in2='effect1_backgroundBlur_985_6256'
                            result='shape'
                          />
                        </filter>
                        <linearGradient
                          id='paint0_linear_985_6256'
                          x1='15.8333'
                          y1='3.75'
                          x2='8.82948'
                          y2='18.1169'
                          gradientUnits='userSpaceOnUse'
                        >
                          <stop stop-color='#F6FF8E' />
                          <stop offset='1' stop-color='#F26B41' />
                        </linearGradient>
                      </defs>
                    </svg>
                  }
                />
              </Table.Col>
              <Table.Col center>
                <Button sm filledGrey>
                  Boost
                </Button>
              </Table.Col>
            </Table.Row>
            <Table.Row>
              {!isTablet && <Table.Col>1</Table.Col>}
              <Table.Col>
                <button className={style.favorite}>
                  <svg
                    width='14'
                    height='13'
                    viewBox='0 0 14 13'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M6.26134 0.775945C6.53462 0.11891 7.46538 0.118909 7.73866 0.775943L8.92838 3.63637C9.04358 3.91336 9.30407 4.10261 9.6031 4.12659L12.6912 4.37416C13.4005 4.43102 13.6881 5.31623 13.1477 5.77916L10.7949 7.79457C10.5671 7.98974 10.4676 8.29596 10.5372 8.58776L11.256 11.6012C11.4211 12.2934 10.6681 12.8405 10.0608 12.4695L7.417 10.8547C7.16099 10.6983 6.83901 10.6983 6.583 10.8547L3.93918 12.4695C3.3319 12.8405 2.5789 12.2934 2.74401 11.6012L3.46282 8.58776C3.53243 8.29596 3.43293 7.98974 3.2051 7.79458L0.852314 5.77917C0.311884 5.31623 0.599504 4.43102 1.30883 4.37416L4.3969 4.12659C4.69593 4.10261 4.95642 3.91336 5.07162 3.63637L6.26134 0.775945Z'
                      fill='white'
                      fill-opacity='0.1'
                    />
                  </svg>
                </button>
              </Table.Col>
              <Table.Col>
                <TokenInfo icon={img} name={'Memereum'} label={'Meme'} />
              </Table.Col>
              {!isTablet && (
                <>
                  <Table.Col>
                    <div className={style.chains}>
                      <TypeDiv title={'Audit'} />
                      <TypeDiv title={'KYC  99/100'} />
                      <TypeDiv title={'Meme'} />
                    </div>
                  </Table.Col>
                  <Table.Col>
                    <Chain
                      name={'ETH'}
                      icon={
                        <svg
                          width='16'
                          height='16'
                          viewBox='0 0 16 16'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z'
                            fill='white'
                            fill-opacity='0.1'
                          />
                          <path
                            d='M7.99902 3V6.69568L10.9978 8.09145L7.99902 3Z'
                            fill='white'
                            fill-opacity='0.5'
                          />
                          <path
                            d='M7.99919 3L5 8.09145L7.99919 6.69568V3Z'
                            fill='white'
                          />
                          <path
                            d='M7.99902 10.4851V12.9963L10.9998 8.67188L7.99902 10.4851Z'
                            fill='white'
                            fill-opacity='0.5'
                          />
                          <path
                            d='M7.99919 12.9963V10.4847L5 8.67188L7.99919 12.9963Z'
                            fill='white'
                          />
                          <path
                            d='M7.99902 9.90393L10.9978 8.09025L7.99902 6.69531V9.90393Z'
                            fill='white'
                            fill-opacity='0.1'
                          />
                          <path
                            d='M5 8.09025L7.99919 9.90393V6.69531L5 8.09025Z'
                            fill='white'
                            fill-opacity='0.5'
                          />
                        </svg>
                      }
                    />
                  </Table.Col>
                  <Table.Col center>4234</Table.Col>
                  <Table.Col center>
                    <Chain
                      name={'120K'}
                      reverse
                      smallIcon
                      transparentIcon
                      icon={
                        <svg
                          width='12'
                          height='12'
                          viewBox='0 0 12 12'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M8.4138 0.291703C8.30641 0.252347 8.19552 0.211485 8.07999 0.175844C6.24482 -0.400641 5.22397 0.5433 4.95266 1.51726C4.58097 2.84793 5.21459 3.75965 6.85658 5.10106C8.8694 6.74457 9.70256 8.27092 9.11883 10.3648C8.94667 10.9845 8.6586 11.5402 8.26389 12C9.89063 11.2974 11.2144 9.84416 11.7516 7.91792C12.6195 4.81091 11.1423 1.56073 8.4138 0.291703Z'
                            fill='#FF9700'
                          />
                          <path
                            d='M5.56033 6.32975C3.5978 4.81937 2.57808 3.187 3.13756 1.18055C3.22146 0.882968 3.33273 0.606473 3.46938 0.351562C1.96443 1.0936 0.757025 2.49214 0.248304 4.31246C-0.282671 6.21289 0.0643994 8.16573 1.03686 9.68876C1.70789 10.559 2.35753 11.2126 3.67621 11.5983C5.46184 12.1212 6.90155 11.3948 7.30222 9.95693C7.67467 8.62585 7.16983 7.64085 5.56033 6.32975Z'
                            fill='#2B3D52'
                          />
                        </svg>
                      }
                    />
                  </Table.Col>
                  <Table.Col center>$0.00031</Table.Col>
                  <Table.Col>$ 7.37M</Table.Col>
                  <Table.Col center>
                    <div className={`${style.diff}`}>-</div>
                  </Table.Col>
                  <Table.Col center>500K</Table.Col>
                  <Table.Col center>In 2 month</Table.Col>
                </>
              )}
              <Table.Col center>
                <Chain
                  transparentIcon
                  name='2,387'
                  icon={
                    <svg
                      width='20'
                      height='20'
                      viewBox='0 0 20 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M14.9995 10.989C14.9676 8.52734 14.0196 6.82558 13.5049 6.08044C13.3725 5.88879 13.0898 5.91265 12.9873 6.1225C12.8296 6.44481 12.5766 6.85228 12.2321 7.06436C12.2321 7.06436 10.772 5.85628 10.7039 4.04639C10.6962 3.83982 10.4951 3.70022 10.3044 3.76674C9.53128 4.03669 7.89045 4.9435 7.49386 7.83261C7.16787 7.23021 6.39474 7.05422 5.89792 7.0047C5.69091 6.98397 5.5237 7.1804 5.57527 7.38726C5.95385 8.90512 5 9.81208 5 11.1164C5 13.9466 7.23092 16.2419 9.98568 16.25C12.7826 16.2582 15.0367 13.8602 14.9995 10.989Z'
                        fill='url(#paint0_linear_985_6256)'
                      />
                      <g filter='url(#filter0_b_985_6256)'>
                        <path
                          d='M16.2495 9.739C16.2176 7.27734 15.2696 5.57558 14.7549 4.83044C14.6225 4.63879 14.3398 4.66265 14.2373 4.8725C14.0796 5.19481 13.8266 5.60228 13.4821 5.81436C13.4821 5.81436 12.022 4.60628 11.9539 2.79639C11.9462 2.58982 11.7451 2.45022 11.5544 2.51674C10.7813 2.78669 9.14045 3.6935 8.74386 6.58261C8.41787 5.98021 7.64474 5.80422 7.14792 5.7547C6.94091 5.73397 6.7737 5.9304 6.82527 6.13726C7.20385 7.65512 6.25 8.56208 6.25 9.86637C6.25 12.6966 8.48092 14.9919 11.2357 15C14.0326 15.0082 16.2867 12.6102 16.2495 9.739Z'
                          fill='white'
                          fill-opacity='0.1'
                        />
                      </g>
                      <defs>
                        <filter
                          id='filter0_b_985_6256'
                          x='3.95'
                          y='0.2'
                          width='14.6'
                          height='17.1'
                          filterUnits='userSpaceOnUse'
                          color-interpolation-filters='sRGB'
                        >
                          <feFlood
                            flood-opacity='0'
                            result='BackgroundImageFix'
                          />
                          <feGaussianBlur
                            in='BackgroundImageFix'
                            stdDeviation='1.15'
                          />
                          <feComposite
                            in2='SourceAlpha'
                            operator='in'
                            result='effect1_backgroundBlur_985_6256'
                          />
                          <feBlend
                            mode='normal'
                            in='SourceGraphic'
                            in2='effect1_backgroundBlur_985_6256'
                            result='shape'
                          />
                        </filter>
                        <linearGradient
                          id='paint0_linear_985_6256'
                          x1='15.8333'
                          y1='3.75'
                          x2='8.82948'
                          y2='18.1169'
                          gradientUnits='userSpaceOnUse'
                        >
                          <stop stop-color='#F6FF8E' />
                          <stop offset='1' stop-color='#F26B41' />
                        </linearGradient>
                      </defs>
                    </svg>
                  }
                />
              </Table.Col>
              <Table.Col center>
                <Button sm filledGrey>
                  Boost
                </Button>
              </Table.Col>
            </Table.Row>
            <Table.Row>
              {!isTablet && <Table.Col>1</Table.Col>}
              <Table.Col>
                <button className={style.favorite}>
                  <svg
                    width='14'
                    height='13'
                    viewBox='0 0 14 13'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M6.26134 0.775945C6.53462 0.11891 7.46538 0.118909 7.73866 0.775943L8.92838 3.63637C9.04358 3.91336 9.30407 4.10261 9.6031 4.12659L12.6912 4.37416C13.4005 4.43102 13.6881 5.31623 13.1477 5.77916L10.7949 7.79457C10.5671 7.98974 10.4676 8.29596 10.5372 8.58776L11.256 11.6012C11.4211 12.2934 10.6681 12.8405 10.0608 12.4695L7.417 10.8547C7.16099 10.6983 6.83901 10.6983 6.583 10.8547L3.93918 12.4695C3.3319 12.8405 2.5789 12.2934 2.74401 11.6012L3.46282 8.58776C3.53243 8.29596 3.43293 7.98974 3.2051 7.79458L0.852314 5.77917C0.311884 5.31623 0.599504 4.43102 1.30883 4.37416L4.3969 4.12659C4.69593 4.10261 4.95642 3.91336 5.07162 3.63637L6.26134 0.775945Z'
                      fill='white'
                      fill-opacity='0.1'
                    />
                  </svg>
                </button>
              </Table.Col>
              <Table.Col>
                <TokenInfo icon={img} name={'Memereum'} label={'Meme'} />
              </Table.Col>
              {!isTablet && (
                <>
                  <Table.Col>
                    <div className={style.chains}>
                      <TypeDiv title={'Audit'} />
                      <TypeDiv title={'KYC  99/100'} />
                      <TypeDiv title={'Meme'} />
                    </div>
                  </Table.Col>
                  <Table.Col>
                    <Chain
                      name={'ETH'}
                      icon={
                        <svg
                          width='16'
                          height='16'
                          viewBox='0 0 16 16'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z'
                            fill='white'
                            fill-opacity='0.1'
                          />
                          <path
                            d='M7.99902 3V6.69568L10.9978 8.09145L7.99902 3Z'
                            fill='white'
                            fill-opacity='0.5'
                          />
                          <path
                            d='M7.99919 3L5 8.09145L7.99919 6.69568V3Z'
                            fill='white'
                          />
                          <path
                            d='M7.99902 10.4851V12.9963L10.9998 8.67188L7.99902 10.4851Z'
                            fill='white'
                            fill-opacity='0.5'
                          />
                          <path
                            d='M7.99919 12.9963V10.4847L5 8.67188L7.99919 12.9963Z'
                            fill='white'
                          />
                          <path
                            d='M7.99902 9.90393L10.9978 8.09025L7.99902 6.69531V9.90393Z'
                            fill='white'
                            fill-opacity='0.1'
                          />
                          <path
                            d='M5 8.09025L7.99919 9.90393V6.69531L5 8.09025Z'
                            fill='white'
                            fill-opacity='0.5'
                          />
                        </svg>
                      }
                    />
                  </Table.Col>
                  <Table.Col center>4234</Table.Col>
                  <Table.Col center>
                    <Chain
                      name={'120K'}
                      reverse
                      smallIcon
                      transparentIcon
                      icon={
                        <svg
                          width='12'
                          height='12'
                          viewBox='0 0 12 12'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M8.4138 0.291703C8.30641 0.252347 8.19552 0.211485 8.07999 0.175844C6.24482 -0.400641 5.22397 0.5433 4.95266 1.51726C4.58097 2.84793 5.21459 3.75965 6.85658 5.10106C8.8694 6.74457 9.70256 8.27092 9.11883 10.3648C8.94667 10.9845 8.6586 11.5402 8.26389 12C9.89063 11.2974 11.2144 9.84416 11.7516 7.91792C12.6195 4.81091 11.1423 1.56073 8.4138 0.291703Z'
                            fill='#FF9700'
                          />
                          <path
                            d='M5.56033 6.32975C3.5978 4.81937 2.57808 3.187 3.13756 1.18055C3.22146 0.882968 3.33273 0.606473 3.46938 0.351562C1.96443 1.0936 0.757025 2.49214 0.248304 4.31246C-0.282671 6.21289 0.0643994 8.16573 1.03686 9.68876C1.70789 10.559 2.35753 11.2126 3.67621 11.5983C5.46184 12.1212 6.90155 11.3948 7.30222 9.95693C7.67467 8.62585 7.16983 7.64085 5.56033 6.32975Z'
                            fill='#2B3D52'
                          />
                        </svg>
                      }
                    />
                  </Table.Col>
                  <Table.Col center>$0.00031</Table.Col>
                  <Table.Col>$ 7.37M</Table.Col>
                  <Table.Col center>
                    <div className={`${style.diff}`}>-</div>
                  </Table.Col>
                  <Table.Col center>500K</Table.Col>
                  <Table.Col center>In 2 month</Table.Col>
                </>
              )}
              <Table.Col center>
                <Chain
                  transparentIcon
                  name='2,387'
                  icon={
                    <svg
                      width='20'
                      height='20'
                      viewBox='0 0 20 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M14.9995 10.989C14.9676 8.52734 14.0196 6.82558 13.5049 6.08044C13.3725 5.88879 13.0898 5.91265 12.9873 6.1225C12.8296 6.44481 12.5766 6.85228 12.2321 7.06436C12.2321 7.06436 10.772 5.85628 10.7039 4.04639C10.6962 3.83982 10.4951 3.70022 10.3044 3.76674C9.53128 4.03669 7.89045 4.9435 7.49386 7.83261C7.16787 7.23021 6.39474 7.05422 5.89792 7.0047C5.69091 6.98397 5.5237 7.1804 5.57527 7.38726C5.95385 8.90512 5 9.81208 5 11.1164C5 13.9466 7.23092 16.2419 9.98568 16.25C12.7826 16.2582 15.0367 13.8602 14.9995 10.989Z'
                        fill='url(#paint0_linear_985_6256)'
                      />
                      <g filter='url(#filter0_b_985_6256)'>
                        <path
                          d='M16.2495 9.739C16.2176 7.27734 15.2696 5.57558 14.7549 4.83044C14.6225 4.63879 14.3398 4.66265 14.2373 4.8725C14.0796 5.19481 13.8266 5.60228 13.4821 5.81436C13.4821 5.81436 12.022 4.60628 11.9539 2.79639C11.9462 2.58982 11.7451 2.45022 11.5544 2.51674C10.7813 2.78669 9.14045 3.6935 8.74386 6.58261C8.41787 5.98021 7.64474 5.80422 7.14792 5.7547C6.94091 5.73397 6.7737 5.9304 6.82527 6.13726C7.20385 7.65512 6.25 8.56208 6.25 9.86637C6.25 12.6966 8.48092 14.9919 11.2357 15C14.0326 15.0082 16.2867 12.6102 16.2495 9.739Z'
                          fill='white'
                          fill-opacity='0.1'
                        />
                      </g>
                      <defs>
                        <filter
                          id='filter0_b_985_6256'
                          x='3.95'
                          y='0.2'
                          width='14.6'
                          height='17.1'
                          filterUnits='userSpaceOnUse'
                          color-interpolation-filters='sRGB'
                        >
                          <feFlood
                            flood-opacity='0'
                            result='BackgroundImageFix'
                          />
                          <feGaussianBlur
                            in='BackgroundImageFix'
                            stdDeviation='1.15'
                          />
                          <feComposite
                            in2='SourceAlpha'
                            operator='in'
                            result='effect1_backgroundBlur_985_6256'
                          />
                          <feBlend
                            mode='normal'
                            in='SourceGraphic'
                            in2='effect1_backgroundBlur_985_6256'
                            result='shape'
                          />
                        </filter>
                        <linearGradient
                          id='paint0_linear_985_6256'
                          x1='15.8333'
                          y1='3.75'
                          x2='8.82948'
                          y2='18.1169'
                          gradientUnits='userSpaceOnUse'
                        >
                          <stop stop-color='#F6FF8E' />
                          <stop offset='1' stop-color='#F26B41' />
                        </linearGradient>
                      </defs>
                    </svg>
                  }
                />
              </Table.Col>
              <Table.Col center>
                <Button sm filledGrey>
                  Boost
                </Button>
              </Table.Col>
            </Table.Row>
          </Table.Body>
        </Table.Container>
      </div>
    </div>
  );
};

export default TopNewTokensPage;
