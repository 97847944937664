import { useMediaQuery } from 'usehooks-ts';
import Table from '../../../../components/Table/Table';
import style from './History.module.scss';
import { useState } from 'react';
import Button from '../../../../components/Button/Button';
import Empty from '../../../../components/Empty/Empty';
import firedIcon from '../../../../assets/icons/fired.svg';

const HistorySection = () => {
  const isMobile = useMediaQuery('(max-width:767px)');
  const [isEmpty, setIsEmpty] = useState(false);

  return (
    <div className={style.history}>
      {isEmpty ? (
        <div className={style.empty}>
          <Empty
            icon={firedIcon}
            title={
              <>
                So far, you have <br /> not purchased Boost
              </>
            }
            text={
              <>
                The more boosts, the higher <br /> you are among other projects
              </>
            }
            button={<Button>Buy Boost</Button>}
          />
        </div>
      ) : (
        <Table.Container
          side={
            isMobile && (
              <Table.Side>
                <Table.Row>
                  <Table.Col>#</Table.Col>
                </Table.Row>
                <Table.Row>
                  <Table.Col>#0582FFF9</Table.Col>
                </Table.Row>
                <Table.Row>
                  <Table.Col>#0582FFF9</Table.Col>
                </Table.Row>
              </Table.Side>
            )
          }
        >
          <Table.Head>
            <Table.Row>
              {!isMobile && <Table.Col>#</Table.Col>}
              <Table.Col center>Boosts</Table.Col>
              <Table.Col center>Bonus</Table.Col>
              <Table.Col center>Price</Table.Col>
              <Table.Col>Date Created</Table.Col>
              <Table.Col>Status</Table.Col>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            <Table.Row>
              {!isMobile && <Table.Col>#0582FFF9</Table.Col>}
              <Table.Col center>12X</Table.Col>
              <Table.Col center>
                <div className={`${style.card} ${style.yellow}`}>10X</div>
              </Table.Col>
              <Table.Col center>$100</Table.Col>
              <Table.Col>02 Jun 2024 - 7 PM</Table.Col>
              <Table.Col>
                <div className={`${style.card} ${style.green} ${style.big}`}>
                  Completed
                </div>
              </Table.Col>
            </Table.Row>
            <Table.Row>
              {!isMobile && <Table.Col>#0582FFF9</Table.Col>}
              <Table.Col center>12X</Table.Col>
              <Table.Col center>
                <div className={`${style.card} ${style.yellow}`}>10X</div>
              </Table.Col>
              <Table.Col center>$100</Table.Col>
              <Table.Col>02 Jun 2024 - 7 PM</Table.Col>
              <Table.Col>
                <div className={`${style.card} ${style.red} ${style.big}`}>
                  Completed
                </div>
              </Table.Col>
            </Table.Row>
          </Table.Body>
        </Table.Container>
      )}
    </div>
  );
};

export default HistorySection;
