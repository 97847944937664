import Banners from './components/Banners/Banners';
import style from './index.module.scss';
import img from './assets/img.jpg';
import { useMediaQuery } from 'usehooks-ts';
import PromotedSection from './sections/PromotedSections/PromotedSection';
import PanelsSection from './sections/PanelsSection/PanelsSection';
import PromotedTableSection from './sections/PromotedTableSection/PromotedTableSection';
import BottomSection from './sections/BottomSection/BottomSection';
import BestTableSection from './sections/BestTableSection/BestTableSection';

const MainPage = () => {
  const isTablet = useMediaQuery('(max-width:767px)');

  return (
    <div className={style.main}>
      <div className='__container'>
        <Banners.Container withContacts>
          <Banners.Card img={img} half={!isTablet} />
          <Banners.Card img={img} half={!isTablet} />
          {!isTablet && <Banners.Card img={img} />}
        </Banners.Container>
        <PromotedSection />
        <PanelsSection />
        <Banners.Container>
          <Banners.Card img={img} />
        </Banners.Container>
        <PromotedTableSection />
        <Banners.Container withContacts>
          <Banners.Card img={img} />
        </Banners.Container>
        <BestTableSection />
        <Banners.Container withContacts>
          <Banners.Card img={img} />
        </Banners.Container>
        <BottomSection />
      </div>
    </div>
  );
};

export default MainPage;
