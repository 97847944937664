import style from './index.module.scss';
import stars from './assets/stars.svg';
import starsMob from './assets/stars-mob.svg';
import img from './assets/img.svg';
import Title from '../../components/Title/Title';
import Text from '../../components/Text/Text';
import Button from '../../components/Button/Button';
import tgIcon from '../../assets/icons/social/tg.svg';
import xIcon from '../../assets/icons/social/x.svg';
import dsIcon from '../../assets/icons/social/ds.svg';
import gmailIcon from '../../assets/icons/social/gmail.svg';
import Accordion from '../../components/Accordion/Accordion';
import { useMediaQuery } from 'usehooks-ts';

const ContactUsPage = () => {
  const isMobile = useMediaQuery('(max-width:767px)');

  return (
    <div className={style.contact}>
      <div className='__container'>
        <div className={style.contactBody}>
          <div className={style.contactBody__bg}>
            <div className={style.contactBody__bgImg}>
              <img src={img} alt='' />
            </div>
            <div className={style.contactBody__bgStars}>
              <img src={isMobile ? starsMob : stars} alt='' />
            </div>
          </div>

          <div className={style.contactBody__content}>
            <Title.H1 fz42 className={style.contactBody__contentTitle}>
              Do you have questions or found an error on the site?
            </Title.H1>
            <Text className={style.contactBody__contentText} fz14 grey>
              Contact us and we'll get back to you as soon as possible!
            </Text>
            <div className={style.contactBody__contentSocial}>
              <Button onlyIcon>
                <img src={tgIcon} alt='' />
              </Button>
              <Button onlyIcon>
                <img src={xIcon} alt='' />
              </Button>
              <Button onlyIcon>
                <img src={dsIcon} alt='' />
              </Button>
              <Button onlyIcon>
                <img src={gmailIcon} alt='' />
              </Button>
            </div>
          </div>
          <div className={style.contactBody__faq}>
            <div className={style.contactBody__faqTop}>
              <Title.H3 className={style.contactBody__faqTitle} fz16>
                FAQ
              </Title.H3>
              <div className={style.contactBody__faqText}>
                <Text grey>What is Boost?</Text>
                <a href=''>
                  <Text>Read now</Text>
                </a>
              </div>
            </div>
            <div className={style.contactBody__faqContent}>
              <Accordion
                title={'Who leads this platform? '}
                text={
                  'LOPE is a meme coin that was created to suppress the dominance of existing cheat coins, LOPE has a special and different feature that can distinguish it from other meme coins, as you know about other meme coins.'
                }
              />
              <Accordion
                title={'Who leads this platform? '}
                text={
                  'LOPE is a meme coin that was created to suppress the dominance of existing cheat coins, LOPE has a special and different feature that can distinguish it from other meme coins, as you know about other meme coins.'
                }
              />
              <Accordion
                title={'Who leads this platform? '}
                text={
                  'LOPE is a meme coin that was created to suppress the dominance of existing cheat coins, LOPE has a special and different feature that can distinguish it from other meme coins, as you know about other meme coins.'
                }
              />
              <Accordion
                title={'Who leads this platform? '}
                text={
                  'LOPE is a meme coin that was created to suppress the dominance of existing cheat coins, LOPE has a special and different feature that can distinguish it from other meme coins, as you know about other meme coins.'
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsPage;
