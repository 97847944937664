import Button from '../../../../components/Button/Button';
import style from './Banner.module.scss';

const Banner = ({ img }) => {
  return (
    <div className={`${style.con} __container`}>
      <div className={style.banner}>
        <div className={style.banner__img}>
          <img src={img} alt='' />
        </div>
        <Button filledGrey className={style.banner__add}>
          Add Custom Banner
          <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M7.99992 3.33203V12.6654M3.33325 7.9987H12.6666'
              stroke='white'
              stroke-linecap='round'
              stroke-linejoin='round'
            />
          </svg>
        </Button>
      </div>
    </div>
  );
};

export default Banner;
