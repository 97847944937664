import { useState } from 'react';
import style from './New.module.scss';
import Title from '../../../../components/Title/Title';
import Text from '../../../../components/Text/Text';
import Purchase from '../../modules/Purchase/Purchase';
import NewCard from '../../components/NewCard/NewCard';
import iconCrown from '../../../../assets/btn_icons/crown.svg';

const cardList = [
  {
    id: 0,
    checked: false,
    icons: ['link', 'copy'],
    icon: iconCrown,
    name: 'Small Banner',
    text: 'A small-sized banner shown on all pages.',
    price: '$50',
    label: 'day',
    showPos: true,
    dates: 'From 12-15 Jun',
  },
  {
    id: 1,
    checked: false,
    icons: ['link', 'copy'],
    icon: iconCrown,
    name: 'Small Banner',
    text: 'A small-sized banner shown on all pages.',
    price: '$50',
    label: 'day',
    showPos: true,
    dates: 'From 12-15 Jun',
  },
  {
    id: 2,
    checked: false,
    icons: ['link', 'copy'],
    icon: iconCrown,
    name: 'Small Banner',
    text: 'A small-sized banner shown on all pages.',
    price: '$50',
    label: 'day',
    showPos: true,
    dates: 'From 12-15 Jun',
  },
  {
    id: 3,
    checked: false,
    icons: ['link', 'copy'],
    icon: iconCrown,
    name: 'Small Banner',
    text: 'A small-sized banner shown on all pages.',
    price: '$50',
    label: 'day',
    showPos: true,
    dates: 'From 12-15 Jun',
  },
  {
    id: 4,
    checked: false,
    icons: ['link', 'copy'],
    icon: iconCrown,
    name: 'Small Banner',
    text: 'A small-sized banner shown on all pages.',
    price: '$50',
    label: 'day',
    showPos: true,
    dates: 'From 12-15 Jun',
  },
  {
    id: 5,
    checked: false,
    icons: ['link', 'copy'],
    icon: iconCrown,
    name: 'Small Banner',
    text: 'A small-sized banner shown on all pages.',
    price: '$50',
    label: 'day',
    showPos: true,
    dates: 'From 12-15 Jun',
  },
];

const NewSection = () => {
  const [selectedCards, setSelectedCards] = useState([]);

  const chooseCard = (item, type) => {
    if (type === 'add') {
      if (selectedCards.filter((card) => card.id === item.id).length < 1) {
        setSelectedCards([...selectedCards, item]);
      }
    } else {
      setSelectedCards([
        ...selectedCards.filter((card) => card.id !== item.id),
      ]);
    }
  };

  return (
    <div className={style.new}>
      <div className={style.newCards}>
        {cardList.map((item) => (
          <NewCard
            key={item.id}
            id={item.id}
            icons={item.icons}
            icon={item.icon}
            name={item.name}
            text={item.text}
            price={item.price}
            label={item.label}
            showPos={item.showPos}
            className={style.newCards__item}
            title={item.title}
            rate={item.rate}
            dates={item.dates}
            bonus={item.bonus}
            choose={chooseCard}
            selectedCards={selectedCards}
          />
        ))}
      </div>
      <Purchase
        choose={chooseCard}
        className={style.newPurchase}
        cardList={selectedCards}
      />
    </div>
  );
};

export default NewSection;
