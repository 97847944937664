import style from './Footer.module.scss';
import logoSvg from '../../assets/logo.svg';
import Button from '../Button/Button';
import Text from '../Text/Text';

// import btn icons
import crown from '../../assets/btn_icons/crown.svg';
import ranking from '../../assets/btn_icons/ranking.svg';
import star from '../../assets/btn_icons/star.svg';
import submit_coin from '../../assets/btn_icons/submit_coin.svg';
import update from '../../assets/btn_icons/update.svg';
import contact_us from '../../assets/btn_icons/contact_us.svg';
import services from '../../assets/btn_icons/services.svg';
import advertise from '../../assets/btn_icons/advertising.svg';
import blog from '../../assets/btn_icons/blog.svg';

const Footer = () => {
  return (
    <footer className={style.footer}>
      <div className={'__container'}>
        <div className={style.footerBody}>
          <div className={style.footerBody__logo}>
            <img src={logoSvg} alt='' />
          </div>
          <div className={style.footerBody__links}>
            <div className={style.col}>
              <Button icon={crown}>All Time Ranking</Button>
              <Button icon={ranking}>Daily Ranking</Button>
            </div>
            <div className={style.col}>
              <Button icon={star}>New Listings</Button>
              <Button icon={submit_coin}>Submit Coin</Button>
            </div>
            <div className={style.col}>
              <Button icon={update}>Update Request</Button>
              <Button icon={contact_us}>Contact Us</Button>
            </div>
            <div className={style.col}>
              <Button icon={services}>Services</Button>
              <Button icon={advertise}>Advertise</Button>
            </div>
            <div className={style.col}>
              <Button icon={blog}>Blog</Button>
            </div>
          </div>
        </div>
        <div className={style.footerBottom}>
          <Text grey className={style.footerBottom__cr}>
            2024 bestpresales
          </Text>
          <div className={style.footerBottom__links}>
            <a href=''>
              <Text grey>Cookie Statement</Text>
            </a>
            <a href=''>
              <Text grey>Terms & Conditions</Text>
            </a>
            <a href=''>
              <Text grey>Privacy Policy</Text>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
