import TokenInfo from '../../../../components/TokenInfo/TokenInfo';
import PanelCard, {
  PanelCardNews,
  PanelCardRow,
  PanelCardTags,
  PanelCardValue,
} from '../../components/PanelCard/PanelCard';
import Panels from '../../modules/Panels/Panels';
import style from './PanelsSection.module.scss';
import img from '../PromotedSections/assets/card-icon.jpg';
import img2 from '../PromotedSections/assets/card-icon-2.jpg';

const PanelsSection = () => {
  return (
    <div className={style.panels}>
      <Panels>
        <PanelCard className={style.panels__item} yellow title='Trending Coins'>
          <PanelCardRow>
            <TokenInfo
              icon={img}
              name={'DOGEVERSE'}
              label={'$0.004 , 400 transactions'}
            />
            <PanelCardValue value={'Presale'} />
          </PanelCardRow>
          <PanelCardRow>
            <TokenInfo
              icon={img}
              name={'DOGEVERSE'}
              label={'$0.004 , 400 transactions'}
            />
            <PanelCardValue value={'Presale'} />
          </PanelCardRow>
          <PanelCardRow>
            <TokenInfo
              icon={img}
              name={'DOGEVERSE'}
              label={'$0.004 , 400 transactions'}
            />
            <PanelCardValue value={'Presale'} />
          </PanelCardRow>
        </PanelCard>
        <PanelCard className={style.panels__item} title='Trending Coins'>
          <PanelCardRow>
            <TokenInfo
              icon={img}
              name={'DOGEVERSE'}
              label={'$0.004 , 400 transactions'}
            />
            <PanelCardValue value={'Presale'} />
          </PanelCardRow>
          <PanelCardRow>
            <TokenInfo
              icon={img}
              name={'DOGEVERSE'}
              label={'$0.004 , 400 transactions'}
            />
            <PanelCardValue value={'Presale'} />
          </PanelCardRow>
          <PanelCardRow>
            <TokenInfo
              icon={img}
              name={'DOGEVERSE'}
              label={'$0.004 , 400 transactions'}
            />
            <PanelCardValue value={'Presale'} />
          </PanelCardRow>
        </PanelCard>
        <PanelCard className={style.panels__item} blue title='Trending Coins'>
          <PanelCardRow>
            <TokenInfo
              icon={img}
              name={'DOGEVERSE'}
              label={'$0.004 , 400 transactions'}
            />
            <PanelCardValue value={'8,322'} />
          </PanelCardRow>
          <PanelCardRow>
            <TokenInfo
              icon={img}
              name={'DOGEVERSE'}
              label={'$0.004 , 400 transactions'}
            />
            <PanelCardValue value={'8,322'} />
          </PanelCardRow>
          <PanelCardRow>
            <TokenInfo
              icon={img}
              name={'DOGEVERSE'}
              label={'$0.004 , 400 transactions'}
            />
            <PanelCardValue value={'8,322'} />
          </PanelCardRow>
        </PanelCard>
        <PanelCard className={style.panels__item} red title='Trending Coins'>
          <PanelCardRow>
            <TokenInfo
              icon={img}
              name={'DOGEVERSE'}
              label={'$0.004 , 400 transactions'}
            />
            <PanelCardValue value={'8,322'} />
          </PanelCardRow>
          <PanelCardRow>
            <TokenInfo
              icon={img}
              name={'DOGEVERSE'}
              label={'$0.004 , 400 transactions'}
            />
            <PanelCardValue value={'8,322'} />
          </PanelCardRow>
          <PanelCardRow>
            <TokenInfo
              icon={img}
              name={'DOGEVERSE'}
              label={'$0.004 , 400 transactions'}
            />
            <PanelCardValue value={'8,322'} />
          </PanelCardRow>
        </PanelCard>
        <PanelCard className={style.panels__item} grey title='Trending Coins'>
          <PanelCardRow>
            <TokenInfo
              icon={img}
              name={'DOGEVERSE'}
              label={'$0.004 , 400 transactions'}
            />
            <PanelCardValue value={'Presale'} />
          </PanelCardRow>
          <PanelCardRow>
            <TokenInfo
              icon={img}
              name={'DOGEVERSE'}
              label={'$0.004 , 400 transactions'}
            />
            <PanelCardValue value={'$0,5369'} />
          </PanelCardRow>
          <PanelCardRow>
            <TokenInfo
              icon={img}
              name={'DOGEVERSE'}
              label={'$0.004 , 400 transactions'}
            />
            <PanelCardValue value={'Presale'} />
          </PanelCardRow>
        </PanelCard>
        <PanelCard className={style.panels__item} green title='Trending Coins'>
          <PanelCardRow>
            <TokenInfo icon={img} name={'DOGEVERSE'} label={'DRIFT tokens'} />
            <PanelCardValue timestamp={1725181834} />
          </PanelCardRow>
          <PanelCardRow>
            <TokenInfo icon={img} name={'DOGEVERSE'} label={'DRIFT tokens'} />
            <PanelCardValue timestamp={1725181834} />
          </PanelCardRow>
          <PanelCardRow>
            <TokenInfo icon={img} name={'DOGEVERSE'} label={'DRIFT tokens'} />
            <PanelCardValue timestamp={1725181834} />
          </PanelCardRow>
        </PanelCard>
        <PanelCard className={style.panels__item} pink title='Trending Coins'>
          <PanelCardNews
            img={img2}
            title={'Discover Hamster Kombat'}
            text={
              'Are your ready to drive into a world where fun meets profitablity?'
            }
          />
          <PanelCardNews
            img={img2}
            title={'Discover Hamster Kombat'}
            text={
              'Are your ready to drive into a world where fun meets profitablity?'
            }
          />
        </PanelCard>
        <PanelCard className={style.panels__item} blue2 title='Trending Coins'>
          <PanelCardTags
            tags={[
              'Basics of Cryptocurrency',
              'Blockchain',
              'Altcoins',
              'Crypto Regulations',
              'P2P',
              'Wallets and Security',
              'Exchanges',
              'DeFi',
              'NFTs',
              'Investments',
              'ICO',
              'Crypto Basics',
              'Tax',
              'Security',
            ]}
          />
        </PanelCard>
      </Panels>
    </div>
  );
};

export default PanelsSection;
