import style from './ListItem.module.scss';

const ListItem = ({ icon }) => {
  return (
    <div className={style.item}>
      <div className={style.item__img}>
        <img src={icon} alt='' />
      </div>
    </div>
  );
};

export default ListItem;
