import { useMediaQuery } from 'usehooks-ts';
import Button from '../Button/Button';
import style from './Pagination.module.scss';

const Pagination = ({ className }) => {
  const isTablet = useMediaQuery('(max-width:991px)');

  return (
    <div className={`${style.pagination} ${className}`}>
      <button className={style.pagination__nav}>
        {isTablet ? (
          <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M10 4L6 8L10 12'
              stroke='white'
              stroke-opacity='0.5'
              stroke-linecap='round'
              stroke-linejoin='round'
            />
          </svg>
        ) : (
          'Previous Page'
        )}
      </button>
      <Button sm onlyIcon filled>
        1
      </Button>
      <Button sm onlyIcon filledGrey>
        2
      </Button>
      <Button sm onlyIcon filledGrey>
        3
      </Button>
      <Button sm onlyIcon filledGrey>
        4
      </Button>
      <Button sm onlyIcon filledGrey>
        5
      </Button>
      {!isTablet && (
        <>
          <Button sm onlyIcon filledGrey>
            6
          </Button>
          <Button sm onlyIcon filledGrey>
            7
          </Button>
          <Button sm onlyIcon filledGrey>
            8
          </Button>
          <Button sm onlyIcon filledGrey>
            9
          </Button>
          <Button sm onlyIcon filledGrey>
            10
          </Button>
          <Button sm onlyIcon filledGrey>
            11
          </Button>
          <Button sm onlyIcon filledGrey>
            12
          </Button>
          <Button sm onlyIcon filledGrey>
            13
          </Button>
          <Button sm onlyIcon filledGrey>
            14
          </Button>
          <Button sm onlyIcon filledGrey>
            15
          </Button>
          <Button sm onlyIcon filledGrey>
            16
          </Button>
          <Button sm onlyIcon filledGrey>
            17
          </Button>
          <Button sm onlyIcon filledGrey>
            18
          </Button>
          <Button sm onlyIcon filledGrey>
            19
          </Button>
          <Button sm onlyIcon filledGrey>
            20
          </Button>
        </>
      )}
      <Button sm onlyIcon filledGrey>
        ...
      </Button>
      <Button sm onlyIcon filledGrey>
        99
      </Button>
      <button className={style.pagination__nav}>
        {isTablet ? (
          <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M6 12L10 8L6 4'
              stroke='white'
              stroke-opacity='0.5'
              stroke-linecap='round'
              stroke-linejoin='round'
            />
          </svg>
        ) : (
          'Next Page'
        )}
      </button>
    </div>
  );
};

export default Pagination;
