import Text from '../../components/Text/Text';
import Title from '../../components/Title/Title';
import Img from './components/Img/Img';
import Tag from './components/Tag/Tag';
import style from './index.module.scss';
import Tags from './modules/Tags/Tags';
import List from '../../components/List/List';

import bigImg from './assets/img-big.jpg';
import mdImg from './assets/img-md.jpg';
import mdImg2 from './assets/img-md-2.jpg';
import smImg from './assets/img-sm.jpg';
import smImg2 from './assets/img-sm-2.jpg';
import smImg3 from './assets/img-sm-3.jpg';
import PopularSection from '../Blog/modules/Popular/Popular';

const ArticlePage = () => {
  return (
    <div className={style.article}>
      <div className={`__container`}>
        <div className={style.articleBody}>
          <Title.H1 fz48 center className={style.articleBody__title}>
            Unleash Your Earnings: Mastering the <br /> Strategy of Hamster
            Kombat!
          </Title.H1>
          <Tags className={style.article__tags}>
            <Tag text={'Author:'} value={'John Smith'} />
            <Tag text={'Date:'} value={'05.06.2024'} />
            <Tag text={'Read:'} value={'8 min'} />
            <Tag text={'Category:'} value={'Blockchain, Gaming'} />
          </Tags>
          <div className={style.article__content}>
            <Title.H1>Introduction</Title.H1>
            <Text grey fz14 className={style.text}>
              <p>
                Hamster Kombat is not just a game but a platform for passive
                earnings. The main rule of the game is to invest earned coins in
                purchasing cards that increase passive income. Smart investments
                and strategic improvements significantly enhance the
                profitability of your virtual exchange (if you haven’t joined
                yet, <a href=''> now’s the time</a>).
              </p>
              <p>
                I have a <a href=''>full review</a> of this new app on my
                channel as well as <a href=''>answers to popular questions</a>,
                don’t miss it! But now it’s a little bit about something else.
              </p>
            </Text>
            <Img.Row>
              <Img.Big img={bigImg} />
            </Img.Row>
            <Title.H1>Card Investments</Title.H1>
            <Text grey fz14 className={style.text}>
              <p>
                The higher the level of the card, the more income it generates.
              </p>
              <p>For example:</p>
              <List>
                <li>
                  A card costing 10,000 coins brings in 950 coins per hour,
                  paying off in approximately 10 hours.
                </li>
                <li>
                  A card costing 5,000 coins brings in 50 coins per hour, paying
                  off in 100 hours.
                </li>
              </List>
              <p>
                It is obvious that it is more profitable to invest in
                higher-level cards as they pay off faster and generate more
                income.
              </p>
            </Text>
            <div className={style.space}></div>
            <Title.H1>
              Main Areas of Improvement <br /> in the Mine Section
            </Title.H1>
            <Img.Row>
              <Img.Medium img={mdImg} text={'Location '} />
              <Img.Small img={smImg} text={'Special Cards'} />
            </Img.Row>
            <Text grey fz14 className={style.text}>
              <p>
                <span>Markets:</span> Cards that increase income from various
                market operations.
              </p>
              <p>It is worth improving:</p>
              <p>
                Fan tokens, Staking, BTC Pairs, ETH pairs, Meme coins, Defi2.0
                tokens, Margin trading x10 (x20, x30, x50, x75, x100) Prediction
                markets, Derivatives, Web3 integration, DAO, P2P trading,
                Trading bots.
              </p>
              <p>
                <span>PR and Team:</span> Improvements related to PR and the
                team. The greatest income is brought by:
              </p>
              <p>
                Support team, HamsterBook, X, HamsterTube, HamsterGram, TikTok,
                CoinDesk, Influencers, CEO, IT team, Marketing, Security Team,
                BisDev team, UX and UI team, QA team, VC labs, Risk management
                team, Anonymous transaction ban, Tokenomics expert etc
              </p>
              <p>
                <span>Legal:</span> Licenses for operational activities in
                various regions. The greatest income comes from cards with
                regions:
              </p>
              <p>
                KYC, KYB, Legal opiniom, License UAE, License Europe, License
                Asia, License South America, License Australia, License North
                America, License Nigeria etc.
              </p>
              <p>
                <span>Specials:</span> Special cards with unique bonuses.
                Usually, these cards are the most profitable, although there are
                those that do not generate income but are expensive. Their
                purpose may become clear during a drop or when access to other,
                more profitable cards is opened.
              </p>
            </Text>
            <div className={style.space}></div>
            <Title.H1>
              Life Hacks <br />
              in Hamster Kombat
            </Title.H1>
            <Text fz14 grey className={style.text}>
              <List bigSpace>
                <li>
                  Constantly invest earned coins in improving your exchange.
                  This will speed up the earning process.
                </li>
                <li>
                  Use boosters to increase productivity and raise the energy
                  limit.
                </li>
                <li>
                  Subscribe to official channels and participate in airdrops.
                </li>
                <li>Complete daily tasks to receive coins.</li>
                <li>
                  Purchase cards from the “Specials” section for maximum income.
                </li>
                <li>
                  Attract referrals for quick progress and development of your
                  hamster to the highest level.
                </li>
              </List>
            </Text>
            <div className={style.space}></div>
            <Title.H1>
              Do you know Morse code? <br /> And you have to learn!
            </Title.H1>
            <Img.Row>
              <Img.Medium img={mdImg2} text={'Morse code in Hamster Kombat '} />
            </Img.Row>
            <Text fz14 grey className={style.text}>
              <p>
                A new way to get 1 million coins has now appeared in Monster
                Kombat. <br />
                <span>
                  Every day you need to open words related to crypto currency!
                </span>
              </p>
            </Text>
            <Title.H2>I’ll give you a hint on how to use it.</Title.H2>
            <Img.Row>
              <Img.Small img={smImg2} />
            </Img.Row>
            <Text fz14 grey className={style.text}>
              <p>
                We go into the group, 
                <span>
                  stamp 3 times on the sign, and “EARN per tap” is displayed
                </span>
                . We are transferred to the new menu (the hamster will be on a
                red background). There you need to write the word using Morse
                code.
              </p>
            </Text>
            <Img.Row>
              <Img.Small img={smImg3} />
            </Img.Row>
            <Text fz14 grey className={style.text}>
              <p>
                <span>
                  Tap — dot <br />
                  Long tap — line
                </span>
              </p>
              <p>
                <span>IMPORTANT:</span> <br />
                -… wait for the letter B to appear <br /> - check the letter T{' '}
                <br /> -.-. Check for the letter C I will be in the city <br />{' '}
                P.S. Everything works, I checked it 😈The first couple of days
                there was a word — BTC, Ethereum…
              </p>
              <p>Don’t forget to repost to your friends✅</p>
              <p>
                I can also recommend you other equally promising applications:{' '}
                <a href=''>PixelVerse</a>, <a href=''>TimeTon</a> ,{' '}
                <a href=''> Blum</a>
                (check in every 8 hours for 10 seconds) <a href=''>
                  TapSwap
                </a>, <a href=''>Memefi</a>, <a href=''>DotCoin</a>,{' '}
                <a href=''> Pocket Rocket</a> and <a href=''>Wormfare Slap</a>
              </p>
            </Text>
          </div>
        </div>
        <PopularSection />
      </div>
    </div>
  );
};

export default ArticlePage;
