import Text from '../Text/Text';
import style from './Checkbox.module.scss';

const Checkbox = ({ customRef, onChange, className, title, name }) => {
  return (
    <label ref={customRef} className={`${style.checkbox} ${className}`}>
      <input type='checkbox' onChange={onChange} name={name} />
      <div className={style.checkboxBody}>
        <div className={style.checkboxBody__item}>
          <svg
            width='8'
            height='8'
            viewBox='0 0 8 8'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M6.66634 2L2.99967 5.66667L1.33301 4'
              stroke='white'
              stroke-linecap='round'
              stroke-linejoin='round'
            />
          </svg>
        </div>
        <Text black>{title}</Text>
      </div>
    </label>
  );
};

export default Checkbox;
