import Title from '../../../../components/Title/Title';
import Faq from './modules/Faq/Faq';
import Rate from './modules/Rate/Rate';
import Roadmap from './modules/Roadmap/Roadmap';
import Tokenomics from './modules/Tokenomics/Tokenomics';
import style from './PresaleDetails.module.scss';

const PresaleDetails = () => {
  return (
    <section className={style.presale}>
      <div className='__container'>
        <Title.H1 className={style.presale__title}>Presale Details</Title.H1>
        <div className={style.presaleBody}>
          <div className={style.presaleBody__col}>
            <Tokenomics />
          </div>
          <div className={style.presaleBody__col}>
            <Roadmap />
            <Faq />
            <Rate />
          </div>
        </div>
      </div>
    </section>
  );
};

export default PresaleDetails;
