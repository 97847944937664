import { useEffect, useRef, useState } from 'react';
import Text from '../../../../components/Text/Text';
import Title from '../../../../components/Title/Title';
import style from './BoostCard.module.scss';

const BoostCard = ({
  id,
  title,
  rate,
  bonus,
  price,
  className,
  choose,
  selectedCards,
}) => {
  const inputRef = useRef(null);
  const [isChecked, setIsChecked] = useState(false);

  const chooseHandle = () => {
    if (inputRef.current.checked) {
      choose({ id, title, rate, bonus, price }, 'add');
    } else {
      choose({ id, title, rate, bonus, price }, 'remove');
    }
  };

  useEffect(() => {
    if (selectedCards.filter((card) => card.id === id).length >= 1) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [isChecked, selectedCards, id]);

  return (
    <label className={`${style.card} ${className}`} onClick={chooseHandle}>
      <input type='checkbox' checked={isChecked} ref={inputRef} />
      <div className={style.cardWrapper}>
        <div className={style.cardTop}>
          <div className={style.cardTop__boost}>
            <Title.H2>{title}</Title.H2>
            <div className={style.cardTop__boostRate}>
              <span className={rate >= 1 && style.active}></span>
              <span className={rate >= 2 && style.active}></span>
              <span className={rate >= 3 && style.active}></span>
              <span className={rate >= 4 && style.active}></span>
              <span className={rate >= 5 && style.active}></span>
              <span className={rate >= 6 && style.active}></span>
              <span className={rate >= 7 && style.active}></span>
              <span className={rate >= 8 && style.active}></span>
            </div>
            <Text className={style.cardTop__boostText} grey>
              Boost
            </Text>
          </div>
          <div className={style.cardTop__bonus}>
            <div className={style.cardTop__bonusX}>{bonus}</div>
            <Text grey className={style.cardTop__bonusText}>
              Bonus
            </Text>
          </div>
        </div>
        <div className={style.cardBottom}>
          <Title.H2 className={style.cardBottom__price}>{price}</Title.H2>
          <Title.H2 className={style.cardBottom__check}>
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M13.3334 4L6.00008 11.3333L2.66675 8'
                stroke='#010106'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
            </svg>
          </Title.H2>
        </div>
      </div>
    </label>
  );
};

export default BoostCard;
