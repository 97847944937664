import { useRef, useState } from 'react';
import Text from '../../../Text/Text';
import style from './Block.module.scss';

const Block = ({ title, isAccordion, children }) => {
  const contentRef = useRef(null);
  const [isOpen, setIsOpen] = useState(!isAccordion);

  const handleOpen = () => {
    if (isOpen) {
      setIsOpen(false);
      contentRef.current.style.height = '0px';
    } else {
      setIsOpen(true);
      contentRef.current.style.height = `${contentRef.current.scrollHeight}px`;
    }
  };

  return (
    <div className={`${style.block} ${isOpen && style.open}`}>
      <div
        onClick={isAccordion && handleOpen}
        className={`${style.blockTop} ${isAccordion && style.accordion}`}
      >
        <Text grey className={style.blockTop__title}>
          {title}
        </Text>
        {isAccordion && (
          <div className={style.blockTop__arrow}>
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M12 10L8 6L4 10'
                stroke='white'
                stroke-opacity='0.5'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
            </svg>
          </div>
        )}
      </div>
      <div ref={contentRef} className={style.blockContent}>
        {children}
      </div>
    </div>
  );
};

export default Block;
