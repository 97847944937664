import BlogCard from '../../../../components/BlogCard/BlogCard';
import Pagination from '../../../../components/Pagination/Pagination';
import style from './Content.module.scss';
import img from './assets/img.jpg';

const BlogContent = () => {
  return (
    <div className={style.content}>
      <BlogCard
        className={style.content__card}
        img={img}
        tag={'DeFi'}
        title={
          'Unleash Your Earnings: Mastering the Strategy of Hamster Kombat!'
        }
        text={
          'Hamster Kombat is not just a game but a platform for passive earnings. The main rule of the game is to invest earned coins in purchas...'
        }
        info={{
          date: '01.06.2024',
          time: '2 min',
        }}
      />
      <BlogCard
        className={style.content__card}
        img={img}
        tag={'Gaming'}
        title={
          'Unleash Your Earnings: Mastering the Strategy of Hamster Kombat!'
        }
        text={
          'Hamster Kombat is not just a game but a platform for passive earnings. The main rule of the game is to invest earned coins in purchas...'
        }
        info={{
          date: '01.06.2024',
          time: '2 min',
        }}
      />
      <BlogCard
        className={style.content__card}
        img={img}
        tag={'Blockchain'}
        title={
          'Unleash Your Earnings: Mastering the Strategy of Hamster Kombat!'
        }
        text={
          'Hamster Kombat is not just a game but a platform for passive earnings. The main rule of the game is to invest earned coins in purchas...'
        }
        info={{
          date: '01.06.2024',
          time: '2 min',
        }}
      />
      <BlogCard
        className={style.content__card}
        img={img}
        tag={'Business'}
        title={
          'Unleash Your Earnings: Mastering the Strategy of Hamster Kombat!'
        }
        text={
          'Hamster Kombat is not just a game but a platform for passive earnings. The main rule of the game is to invest earned coins in purchas...'
        }
        info={{
          date: '01.06.2024',
          time: '2 min',
        }}
      />
      <BlogCard
        className={style.content__card}
        img={img}
        tag={'Scam'}
        title={
          'Unleash Your Earnings: Mastering the Strategy of Hamster Kombat!'
        }
        text={
          'Hamster Kombat is not just a game but a platform for passive earnings. The main rule of the game is to invest earned coins in purchas...'
        }
        info={{
          date: '01.06.2024',
          time: '2 min',
        }}
      />
      <BlogCard
        className={style.content__card}
        img={img}
        tag={'DeFi'}
        title={
          'Unleash Your Earnings: Mastering the Strategy of Hamster Kombat!'
        }
        text={
          'Hamster Kombat is not just a game but a platform for passive earnings. The main rule of the game is to invest earned coins in purchas...'
        }
        info={{
          date: '01.06.2024',
          time: '2 min',
        }}
      />
      <BlogCard
        className={style.content__card}
        img={img}
        tag={'DeFi'}
        title={
          'Unleash Your Earnings: Mastering the Strategy of Hamster Kombat!'
        }
        text={
          'Hamster Kombat is not just a game but a platform for passive earnings. The main rule of the game is to invest earned coins in purchas...'
        }
        info={{
          date: '01.06.2024',
          time: '2 min',
        }}
      />
      <BlogCard
        className={style.content__card}
        img={img}
        tag={'DeFi'}
        title={
          'Unleash Your Earnings: Mastering the Strategy of Hamster Kombat!'
        }
        text={
          'Hamster Kombat is not just a game but a platform for passive earnings. The main rule of the game is to invest earned coins in purchas...'
        }
        info={{
          date: '01.06.2024',
          time: '2 min',
        }}
      />

      <Pagination className={style.content__pagination} />
    </div>
  );
};

export default BlogContent;
