import { useRef, useState } from 'react';
import Text from '../Text/Text';
import Title from '../Title/Title';
import style from './Accordion.module.scss';

const Accordion = ({ title, text }) => {
  const accordionContentRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    if (isOpen) {
      setIsOpen(false);
      accordionContentRef.current.style.height = '0';
    } else {
      setIsOpen(true);
      accordionContentRef.current.style.height = `${accordionContentRef.current.scrollHeight}px`;
    }
  };

  return (
    <div className={`${style.accordion} ${isOpen && style.open}`}>
      <div onClick={toggleAccordion} className={style.accordionTop}>
        <Title.H3 className={style.accordionTop__title}>{title}</Title.H3>
        <div className={style.accordionTop__cross}>
          <span></span>
          <span></span>
        </div>
      </div>
      <div ref={accordionContentRef} className={style.accordionContent}>
        <Text grey className={style.accordionContent__text}>
          {text}
        </Text>
      </div>
    </div>
  );
};

export default Accordion;
