import Text from '../../../../components/Text/Text';
import Title from '../../../../components/Title/Title';
import style from './Top.module.scss';

const SubmitCoinTop = () => {
  return (
    <div className={style.top}>
      <div className='__container'>
        <div className={style.topBody}>
          <Title.H1 center className={style.topBody__title}>
            Submit coin
          </Title.H1>
          <Text grey center className={style.topBody__text}>
            Please enter the minimum <br /> information to publish the coin.
          </Text>
        </div>
      </div>
    </div>
  );
};

export default SubmitCoinTop;
