import { useState } from 'react';
import Input from '../../../../components/Input/Input';
import AddCard from '../../components/AddCard/AddCard';
import SubmitCoinCard from '../../modules/Card/Card';
import style from './Roadmap.module.scss';
import { randomInteger } from '../../../../helpers/randomInteger';

const RoadmapSection = () => {
  const [isShown, setIsShown] = useState(false);
  const [list, setList] = useState([
    {
      id: 1,
      value: '',
      placeholder: 'Step #1: Presale',
    },
    {
      id: 2,
      value: '',
      placeholder: 'Step #2: Development',
    },
    {
      id: 3,
      value: '',
      placeholder: 'Step #3: Launch',
    },
  ]);

  const addMore = () => {
    setList([
      ...list,
      {
        id: randomInteger(list.length, 10000),
        value: '',
      },
    ]);
  };

  const removeItem = (id) => {
    if (list.length === 1) {
      setIsShown(false);
    } else {
      setList(
        list.filter((item) => {
          return item.id !== id;
        })
      );
    }
  };

  return (
    <>
      {isShown && (
        <SubmitCoinCard
          onRemove={() => setIsShown(false)}
          title={'Roadmap'}
          className={style.roadmap}
        >
          <div className={style.roadmapBody}>
            {list.map((item, index) => (
              <Input
                key={index}
                border
                big
                // placeholder={`Step #${index + 1}: Presale`}
                placeholder={
                  item.placeholder ? item.placeholder : `Step #${index + 1}`
                }
                onRemove={() => removeItem(item.id)}
                value={item.value}
                icon={
                  <svg
                    width='16'
                    height='16'
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M10.6667 8.00065C10.6667 9.47341 9.47279 10.6673 8.00003 10.6673C6.52727 10.6673 5.33337 9.47341 5.33337 8.00065M10.6667 8.00065C10.6667 6.52789 9.47279 5.33398 8.00003 5.33398C6.52727 5.33398 5.33337 6.52789 5.33337 8.00065M10.6667 8.00065H14.6667M5.33337 8.00065H1.3335'
                      stroke='white'
                      stroke-opacity='0.5'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                  </svg>
                }
              />
            ))}
            <AddCard
              className={style.roadmapBody__add}
              onClick={addMore}
              icon={
                <svg
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M10.6667 8.00065C10.6667 9.47341 9.47279 10.6673 8.00003 10.6673C6.52727 10.6673 5.33337 9.47341 5.33337 8.00065M10.6667 8.00065C10.6667 6.52789 9.47279 5.33398 8.00003 5.33398C6.52727 5.33398 5.33337 6.52789 5.33337 8.00065M10.6667 8.00065H14.6667M5.33337 8.00065H1.3335'
                    stroke='white'
                    stroke-opacity='0.5'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              }
              title={'Add a Step'}
            />
          </div>
        </SubmitCoinCard>
      )}
      {!isShown && (
        <AddCard
          onClick={() => setIsShown(true)}
          icon={
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M10.6667 8.00065C10.6667 9.47341 9.47279 10.6673 8.00003 10.6673C6.52727 10.6673 5.33337 9.47341 5.33337 8.00065M10.6667 8.00065C10.6667 6.52789 9.47279 5.33398 8.00003 5.33398C6.52727 5.33398 5.33337 6.52789 5.33337 8.00065M10.6667 8.00065H14.6667M5.33337 8.00065H1.3335'
                stroke='white'
                stroke-opacity='0.5'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
            </svg>
          }
          title={'Roadmap'}
        />
      )}
    </>
  );
};

export default RoadmapSection;
