import Text from '../../../../../Text/Text';
import style from './Card.module.scss';
import boostIcon from './assets/boost.svg';

const Card = ({ icon, name, label, score }) => {
  return (
    <div className={style.card}>
      <div className={style.card__icon}>
        <img src={icon} alt='' />
      </div>
      <Text className={style.card__name}>{name}</Text>
      <Text className={style.card__label} grey>
        {label}
      </Text>
      <Text className={style.card__score}>
        <img src={boostIcon} alt='' />
        {score}
      </Text>
    </div>
  );
};

export default Card;
