import style from './List.module.scss';
import ListItem from '../../components/ListIItem/ListItem';
import Marquee from 'react-fast-marquee';

const List = ({ iconList, classList }) => {
  return (
    <>
      <div className={`${style.list} ${classList}`}>
        <Marquee speed={30}>
          <div className={style.listRow}>
            {iconList.map((item, index) => (
              <ListItem icon={item} key={index} />
            ))}
          </div>
        </Marquee>
      </div>
    </>
  );
};

export default List;
