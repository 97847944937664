import Text from '../Text/Text';
import style from './TokenInfo.module.scss';

const TokenInfo = ({ icon, name, label }) => {
  return (
    <div className={style.info}>
      <div className={style.info__img}>
        <img src={icon} alt='' />
      </div>
      <div className={style.infoContent}>
        <Text>{name}</Text>
        <Text className={style.infoContent__label} grey>
          {label}
        </Text>
      </div>
    </div>
  );
};

export default TokenInfo;
