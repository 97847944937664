import Title from '../../../../../../components/Title/Title';
import Accordion from '../../../../../../components/Accordion/Accordion';
import style from './Faq.module.scss';

const Faq = () => {
  return (
    <div className={style.faq}>
      <Title.H3 className={style.faq__title}>FAQ</Title.H3>
      <div className={style.faqBody}>
        <Accordion
          title={'What are the important links for LopeCoin'}
          text={
            'What are the important links for LopeCoin What are the important links for LopeCoin What are the important links for LopeCoin What are the important links for LopeCoin'
          }
        />
        <Accordion
          title={'What are the important links for LopeCoin'}
          text={
            'What are the important links for LopeCoin What are the important links for LopeCoin What are the important links for LopeCoin What are the important links for LopeCoin'
          }
        />
        <Accordion
          title={'What are the important links for LopeCoin'}
          text={
            'What are the important links for LopeCoin What are the important links for LopeCoin What are the important links for LopeCoin What are the important links for LopeCoin'
          }
        />
        <Accordion
          title={'What are the important links for LopeCoin'}
          text={
            'What are the important links for LopeCoin What are the important links for LopeCoin What are the important links for LopeCoin What are the important links for LopeCoin'
          }
        />
      </div>
    </div>
  );
};

export default Faq;
