import { useState } from 'react';
import Input from '../../../../components/Input/Input';
import Textarea from '../../../../components/Textarea/Textarea';
import AddCard from '../../components/AddCard/AddCard';
import SubmitCoinCard from '../../modules/Card/Card';
import style from './Kyc.module.scss';

const KycSection = () => {
  const [isShown, setIsShown] = useState(false);

  return (
    <>
      {isShown && (
        <SubmitCoinCard
          onRemove={() => setIsShown(false)}
          className={style.kyc}
          title={'Submit KYC Certificate'}
        >
          <div className={style.kycBody}>
            <Input
              big
              border
              icon={
                <svg
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M5.99992 11.3327H4.66659C2.82564 11.3327 1.33325 9.8403 1.33325 7.99935C1.33325 6.1584 2.82564 4.66602 4.66659 4.66602H5.99992M9.99992 11.3327H11.3333C13.1742 11.3327 14.6666 9.8403 14.6666 7.99935C14.6666 6.1584 13.1742 4.66602 11.3333 4.66602H9.99992M4.66659 7.99935L11.3333 7.99935'
                    stroke='white'
                    stroke-opacity='0.5'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              }
              placeholder={'Certificate Link: https://'}
            />
            <Textarea big border placeholder={'Comment (For moderation)'} />
          </div>
        </SubmitCoinCard>
      )}
      {!isShown && (
        <AddCard
          icon={
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M7.53475 14.4096C7.68235 14.4957 7.75615 14.5388 7.86029 14.5611C7.94112 14.5785 8.05904 14.5785 8.13987 14.5611C8.24402 14.5388 8.31781 14.4957 8.46541 14.4096C9.76411 13.652 13.3334 11.272 13.3334 7.99972V5.46639C13.3334 4.75046 13.3334 4.39249 13.2231 4.13839C13.1109 3.88012 12.9991 3.74273 12.7691 3.58032C12.5428 3.42054 12.0992 3.32827 11.2119 3.14373C10.2339 2.94034 9.48293 2.57308 8.79633 2.04193C8.46709 1.78723 8.30246 1.65988 8.17364 1.62515C8.03771 1.58849 7.96246 1.58849 7.82652 1.62515C7.6977 1.65988 7.53308 1.78723 7.20383 2.04193C6.51723 2.57308 5.76621 2.94034 4.78828 3.14373C3.901 3.32827 3.45736 3.42054 3.23105 3.58032C3.00103 3.74273 2.88927 3.88012 2.77711 4.13839C2.66675 4.39249 2.66675 4.75046 2.66675 5.46639V7.99972C2.66675 11.272 6.23606 13.652 7.53475 14.4096Z'
                stroke='white'
                stroke-opacity='0.5'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
            </svg>
          }
          title={'KYC'}
          onClick={() => setIsShown(true)}
        />
      )}
    </>
  );
};

export default KycSection;
